import { useIntl } from 'react-intl';
import { Feature } from '../schema';
import { DESCRIPTOR as DateTime } from '../../../messages/DateTime';
import { useFormatLabel } from '../../../utils';

export enum Column {
  Date = 'date',
  LeftValue = 'leftValue',
  RightValue = 'rightValue'
}

interface ColumnData {
  field: Column;
  // eslint-disable-next-line
  headerName: any;
  width: number;
}

const useColumnData = (leftFeature: Feature, rightFeature: Feature): ColumnData[] => {
  const { formatMessage } = useIntl();
  const formatLabel = useFormatLabel();
  return [
    { field: Column.Date, headerName: formatMessage(DateTime), width: 200 },
    { field: Column.LeftValue, headerName: formatLabel(leftFeature.title), width: 180 },
    { field: Column.RightValue, headerName: formatLabel(rightFeature.title), width: 180 }
  ];
};

export default useColumnData;
