import React from 'react';
import { Legend } from 'recharts';
import { LocalisedLabel } from '../../lib';
import { useEzerTheme } from '../../EzerThemeProvider';
import { Label as LabelType } from '../../types';
import { useToggles } from '../../utils';

type Props = {
  leftAxisLabel: LabelType;
  rightAxisLabel?: LabelType;
  rightAxisColor: string;
};

const ChartLegend = ({ leftAxisLabel, rightAxisLabel, rightAxisColor }: Props) => {
  const { palette } = useEzerTheme();
  const toggles = useToggles();

  const payload = [];

  if (toggles.includes('timeWithinBounds')) {
    payload.push({
      value: (
        <>
          <LocalisedLabel>{leftAxisLabel}</LocalisedLabel> (%)
        </>
      ),
      type: 'square',
      color: palette.leaf
    });
  }

  if (rightAxisLabel) {
    payload.push({
      value: (
        <>
          <LocalisedLabel>{rightAxisLabel}</LocalisedLabel> (%)
        </>
      ),
      type: 'line',
      color: rightAxisColor
    });
  }

  // @ts-ignore
  return <Legend verticalAlign="top" height={36} payload={payload} />;
};

export default ChartLegend;
