import React from 'react';
import LegendKey from './Chart/ToggleLegend/LegendKey';
import {
  AverageDurationHigh,
  AverageDurationLow,
  NumberHigh,
  NumberLow,
  TimeInHigh,
  TimeInLow,
  TimeInOk,
  TimeWithNoData
} from './messages';

type Props = {
  legendKey: LegendKey;
  featureTitle: string;
  showUnit?: boolean;
};

const FeatureDescription = ({ legendKey, featureTitle, showUnit = false }: Props) => {
  const percentageDescription = showUnit ? ' (%)' : '';
  const minDescription = showUnit ? ' (min)' : '';

  if (legendKey === LegendKey.LowPercentageTime) {
    return (
      <>
        <TimeInLow featureTitle={featureTitle} />
        {percentageDescription}
      </>
    );
  }
  if (legendKey === LegendKey.HighPercentageTime) {
    return (
      <>
        <TimeInHigh featureTitle={featureTitle} />
        {percentageDescription}
      </>
    );
  }
  if (legendKey === LegendKey.OkPercentageTime) {
    return (
      <>
        <TimeInOk featureTitle={featureTitle} />
        {percentageDescription}
      </>
    );
  }
  if (legendKey === LegendKey.NoDataTime) {
    return (
      <>
        <TimeWithNoData />
        {percentageDescription}
      </>
    );
  }
  if (legendKey === LegendKey.LowPeriodCount) {
    return (
      <>
        <NumberLow featureTitle={featureTitle} />
      </>
    );
  }
  if (legendKey === LegendKey.HighPeriodCount) {
    return (
      <>
        <NumberHigh featureTitle={featureTitle} />
      </>
    );
  }
  if (legendKey === LegendKey.AverageHighPeriodDuration) {
    return (
      <>
        <AverageDurationHigh featureTitle={featureTitle} />
        {minDescription}
      </>
    );
  }
  if (legendKey === LegendKey.AverageLowPeriodDuration) {
    return (
      <>
        <AverageDurationLow featureTitle={featureTitle} />
        {minDescription}
      </>
    );
  }
  return <>{featureTitle}</>;
};

export default FeatureDescription;
