import { FeatureName, Label } from '../../../../../types';
import { round, useFulfillmentRecorder } from '../../../../../utils';
import useRecommendationDataFeatureConfigs from '../../../../../utils/useRecommendationDataFeatureConfigs';
import { useActiveRecommendation } from '../../../utils';
import useTargetData from './useTargetData';

export enum Direction {
  undefined,
  increase,
  decrease,
  nochange
}

export type PlantParameter = {
  name: FeatureName;
  label: Label;
  recommendedValue: number;
  currentValue?: number;
  direction: Direction;
  unit: string;
  isExperimental: boolean;
};

const getDirection = (currentValue: number, recommendedValue: number): Direction => {
  if (currentValue > recommendedValue) {
    return Direction.decrease;
  }
  if (currentValue < recommendedValue) {
    return Direction.increase;
  }
  return Direction.nochange;
};

const usePlantParameters = (): PlantParameter[] => {
  const { data: recommendation } = useActiveRecommendation();
  const { data: plantData, isLoading: isLoadingPlantData } = useTargetData(recommendation);
  const recommendationDataFeatureConfigs = useRecommendationDataFeatureConfigs();
  const { setRecommendationTimestamp } = useFulfillmentRecorder();
  if (recommendation) {
    setRecommendationTimestamp(recommendation.timestamp);
  }

  const result = recommendationDataFeatureConfigs.reduce((metrics: PlantParameter[], featureConfig) => {
    if (recommendation?.value !== undefined) {
      const currentValue = round(plantData?.value ?? 0, 2);
      const recommendedValue = round(recommendation.value, 2);
      metrics.push({
        name: featureConfig.feature.name,
        label: featureConfig.feature.label,
        recommendedValue,
        currentValue: isLoadingPlantData ? undefined : currentValue,
        direction: plantData?.value === undefined ? Direction.undefined : getDirection(currentValue, recommendedValue),
        unit: plantData?.unit ?? '',
        isExperimental: featureConfig.defaultColour === 'charcoal'
      });
    }
    return metrics;
  }, []);

  // only return the first result for now - rd 21/10/24

  return result.length >= 1 ? [result[0]] : [];
};
export default usePlantParameters;
