import { useIntl } from 'react-intl';
import { ChartConfig } from '../useCustomer';
import useLocalisedLabel from './useLocalisedLabel';
import { DESCRIPTOR as DateTime } from '../messages/DateTime';

export enum Column {
  Date = 'date',
  Measured = 'measured',
  Predicted = 'predicted'
}

interface ColumnData {
  field: Column;
  headerName: string;
  width: number;
}

const useHistoricalQualityColumnData = (chartConfigs: ChartConfig[]): ColumnData[] => {
  const { formatMessage } = useIntl();
  const predictedLabel = useLocalisedLabel(chartConfigs[0].title);
  const measuredLabel = useLocalisedLabel(chartConfigs[1].title);
  return [
    { field: Column.Date, headerName: formatMessage(DateTime), width: 200 },
    { field: Column.Predicted, headerName: predictedLabel, width: 180 },
    { field: Column.Measured, headerName: measuredLabel, width: 180 }
  ];
};

export default useHistoricalQualityColumnData;
