[
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-14T20:30:00+00:00",
    "value": 0.8875,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-14T20:45:00+00:00",
    "value": 0.8903,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-14T21:00:00+00:00",
    "value": 0.8973,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-14T21:15:00+00:00",
    "value": 0.9061,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-14T21:30:00+00:00",
    "value": 1.2562,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-14T21:45:00+00:00",
    "value": 1.2717,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-14T22:00:00+00:00",
    "value": 1.2902,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-14T22:15:00+00:00",
    "value": 1.3051,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-14T22:30:00+00:00",
    "value": 1.3207,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-14T22:45:00+00:00",
    "value": 1.3359,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-14T23:00:00+00:00",
    "value": 1.3468,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-14T23:15:00+00:00",
    "value": 1.3516,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-14T23:30:00+00:00",
    "value": 1.3528,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-14T23:45:00+00:00",
    "value": 1.6294,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T00:00:00+00:00",
    "value": 1.628,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T00:15:00+00:00",
    "value": 1.6265,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T00:30:00+00:00",
    "value": 1.6234,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T00:45:00+00:00",
    "value": 1.6203,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T01:00:00+00:00",
    "value": 1.6165,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T01:15:00+00:00",
    "value": 1.612,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T01:30:00+00:00",
    "value": 1.6933,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T01:45:00+00:00",
    "value": 1.6904,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T02:00:00+00:00",
    "value": 1.6901,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T02:15:00+00:00",
    "value": 1.6904,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T02:30:00+00:00",
    "value": 1.692,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T02:45:00+00:00",
    "value": 1.6925,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T03:00:00+00:00",
    "value": 1.687,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T03:15:00+00:00",
    "value": 1.679,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T03:30:00+00:00",
    "value": 1.8297,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T03:45:00+00:00",
    "value": 1.8178,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T04:00:00+00:00",
    "value": 1.8052,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T04:15:00+00:00",
    "value": 1.7923,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T04:30:00+00:00",
    "value": 1.7805,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T04:45:00+00:00",
    "value": 1.7703,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T05:00:00+00:00",
    "value": 1.6198,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T05:15:00+00:00",
    "value": 1.6113,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T05:30:00+00:00",
    "value": 1.6049,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T05:45:00+00:00",
    "value": 1.5987,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T06:00:00+00:00",
    "value": 1.5915,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T06:15:00+00:00",
    "value": 1.5854,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T06:30:00+00:00",
    "value": 1.5796,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T06:45:00+00:00",
    "value": 1.5741,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T07:00:00+00:00",
    "value": 1.5687,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T07:15:00+00:00",
    "value": 1.3909,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T07:30:00+00:00",
    "value": 1.3905,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T07:45:00+00:00",
    "value": 1.3892,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T08:00:00+00:00",
    "value": 1.3903,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T08:15:00+00:00",
    "value": 1.3911,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T08:30:00+00:00",
    "value": 1.3917,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T08:45:00+00:00",
    "value": 1.3945,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T09:00:00+00:00",
    "value": 1.3967,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T09:15:00+00:00",
    "value": 1.4424,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T09:30:00+00:00",
    "value": 1.4416,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T09:45:00+00:00",
    "value": 1.4403,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T10:00:00+00:00",
    "value": 1.4421,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T10:15:00+00:00",
    "value": 1.444,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T10:30:00+00:00",
    "value": 1.4448,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T10:45:00+00:00",
    "value": 1.442,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T11:00:00+00:00",
    "value": 1.4359,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T11:15:00+00:00",
    "value": 1.4285,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T11:30:00+00:00",
    "value": 1.1707,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T11:45:00+00:00",
    "value": 1.1647,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T12:00:00+00:00",
    "value": 1.1595,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T12:15:00+00:00",
    "value": 1.1528,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T12:30:00+00:00",
    "value": 1.1459,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T12:45:00+00:00",
    "value": 1.1409,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T13:00:00+00:00",
    "value": 1.1388,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T13:15:00+00:00",
    "value": 1.2707,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T13:30:00+00:00",
    "value": 1.2704,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T13:45:00+00:00",
    "value": 1.2719,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T14:00:00+00:00",
    "value": 1.2722,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T14:15:00+00:00",
    "value": 1.275,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T14:30:00+00:00",
    "value": 1.2786,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T14:45:00+00:00",
    "value": 1.2832,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T15:00:00+00:00",
    "value": 1.2864,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T15:15:00+00:00",
    "value": 1.2927,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T15:30:00+00:00",
    "value": 1.2501,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T15:45:00+00:00",
    "value": 1.2533,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T16:00:00+00:00",
    "value": 1.2558,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T16:15:00+00:00",
    "value": 1.2623,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T16:30:00+00:00",
    "value": 1.2703,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T16:45:00+00:00",
    "value": 1.2699,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T17:00:00+00:00",
    "value": 1.2754,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T17:15:00+00:00",
    "value": 1.2836,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T17:30:00+00:00",
    "value": 1.7246,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T17:45:00+00:00",
    "value": 1.7353,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T18:00:00+00:00",
    "value": 1.7476,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T18:15:00+00:00",
    "value": 1.7608,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T18:30:00+00:00",
    "value": 1.7713,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T18:45:00+00:00",
    "value": 1.78,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T19:00:00+00:00",
    "value": 1.7855,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T19:15:00+00:00",
    "value": 1.7929,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T19:30:00+00:00",
    "value": 1.798,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T19:45:00+00:00",
    "value": 2.0137,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T20:00:00+00:00",
    "value": 2.0147,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T20:15:00+00:00",
    "value": 2.0189,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T20:30:00+00:00",
    "value": 2.0269,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T20:45:00+00:00",
    "value": 2.0403,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T21:00:00+00:00",
    "value": 2.0511,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T21:15:00+00:00",
    "value": 2.0634,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T21:30:00+00:00",
    "value": 2.072,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T21:45:00+00:00",
    "value": 2.0787,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T22:00:00+00:00",
    "value": 2.0838,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T22:15:00+00:00",
    "value": 2.0966,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T22:30:00+00:00",
    "value": 2.0998,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T22:45:00+00:00",
    "value": 2.0965,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T23:00:00+00:00",
    "value": 2.0883,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T23:15:00+00:00",
    "value": 2.0724,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T23:30:00+00:00",
    "value": 2.0497,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-15T23:45:00+00:00",
    "value": 2.731,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T00:00:00+00:00",
    "value": 2.7023,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T00:15:00+00:00",
    "value": 2.6724,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T00:30:00+00:00",
    "value": 2.6426,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T00:45:00+00:00",
    "value": 2.5392,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T01:00:00+00:00",
    "value": 2.5128,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T01:15:00+00:00",
    "value": 2.4868,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T01:30:00+00:00",
    "value": 2.4581,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T01:45:00+00:00",
    "value": 2.4312,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T02:00:00+00:00",
    "value": 1.8267,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T02:15:00+00:00",
    "value": 1.8007,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T02:30:00+00:00",
    "value": 1.7778,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T02:45:00+00:00",
    "value": 1.7546,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T03:00:00+00:00",
    "value": 1.7357,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T03:15:00+00:00",
    "value": 1.7201,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T03:30:00+00:00",
    "value": 1.7041,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T03:45:00+00:00",
    "value": 1.7989,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T04:00:00+00:00",
    "value": 1.7834,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T04:15:00+00:00",
    "value": 1.7707,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T04:30:00+00:00",
    "value": 1.7596,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T04:45:00+00:00",
    "value": 1.7497,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T05:00:00+00:00",
    "value": 1.7405,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T05:15:00+00:00",
    "value": 1.7328,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T05:30:00+00:00",
    "value": 1.7223,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T05:45:00+00:00",
    "value": 1.7125,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T06:00:00+00:00",
    "value": 1.9466,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T06:15:00+00:00",
    "value": 1.9413,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T06:30:00+00:00",
    "value": 1.9389,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T06:45:00+00:00",
    "value": 1.9379,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T07:00:00+00:00",
    "value": 1.9394,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T07:15:00+00:00",
    "value": 1.9464,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T07:30:00+00:00",
    "value": 1.9606,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T07:45:00+00:00",
    "value": 1.9125,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T08:00:00+00:00",
    "value": 1.9203,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T08:15:00+00:00",
    "value": 1.9213,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T08:30:00+00:00",
    "value": 1.9178,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T08:45:00+00:00",
    "value": 1.917,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T09:00:00+00:00",
    "value": 1.9149,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T09:15:00+00:00",
    "value": 2.0508,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T09:30:00+00:00",
    "value": 2.0449,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T09:45:00+00:00",
    "value": 2.0407,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T10:00:00+00:00",
    "value": 2.0352,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T10:15:00+00:00",
    "value": 2.0306,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T10:30:00+00:00",
    "value": 2.0278,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T10:45:00+00:00",
    "value": 2.0255,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T11:00:00+00:00",
    "value": 2.0245,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T11:15:00+00:00",
    "value": 2.0214,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T11:30:00+00:00",
    "value": 2.0191,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T11:45:00+00:00",
    "value": 2.0272,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T12:00:00+00:00",
    "value": 2.0262,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-10-16T12:15:00+00:00",
    "value": 2.0253,
    "error": null
  }
]
