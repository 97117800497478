import { createUseStyles } from 'react-jss';
import { LoadingMessage } from '../../../../lib';
import MessageContainer from './MessageContainer';
import { EzerTheme } from '../../../../EzerThemeProvider';

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  message: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(18)
  }
}));

const LoadingContent = () => {
  const styles = useStyles();
  return (
    <MessageContainer dataTestId="recommendation-loading-content">
      <LoadingMessage size="small" className={styles.message} />
    </MessageContainer>
  );
};

export default LoadingContent;
