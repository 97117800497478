/* eslint-disable @typescript-eslint/no-explicit-any */
import { differenceInSeconds, subHours } from 'date-fns';
import usePlantStatusQuery from '../../../../serviceQueries/usePlantStatusQuery';
import useNow from '../../../../useNow';
import bufferTime from '../bufferTime';

export type PlantStatusName =
  | 'OK'
  | 'SHUTDOWN'
  | 'UNKNOWN'
  | 'WARMING_UP'
  | 'COOLING_DOWN'
  | 'GAPS_IN_FEED'
  | 'REDUCED_THROUGHPUT'
  | 'LIGHT_UP';

export type PlantStatus = {
  timestamp: Date;
  status: PlantStatusName;
};

type Response = {
  data: PlantStatus[];
  isLoading: boolean;
  isError: boolean;
};

const STATUS_ITEMS_IN_A_DAY = 96;

const usePlantStatus = (): Response => {
  const now = useNow();
  const startDate = subHours(now, 24);
  const { data: plantStatusData = [], isLoading, isError } = usePlantStatusQuery(startDate, now);

  const orderedData = [...plantStatusData].reverse();

  const data = orderedData
    .reduce((acc: any, datum: any) => {
      if (acc.length === 0 || acc[acc.length - 1].timestamp !== datum.timestamp) {
        acc.push(datum);
      }
      return acc;
    }, [])
    .slice(0, STATUS_ITEMS_IN_A_DAY)
    .map((datum: any) => ({
      timestamp: new Date(datum.timestamp),
      status: datum.status as PlantStatusName
    }));

  // set current status to previous status if the new item is within the buffer time.
  // This is to give enough time for the Back End to clear the initial 'UNKNOWN' out
  if (
    data.length >= 2 &&
    data[0].status === 'UNKNOWN' &&
    differenceInSeconds(now, data[0].timestamp) <= bufferTime.seconds + bufferTime.minutes * 60
  ) {
    data[0].status = data[1].status;
  }

  return { data, isLoading, isError };
};
export default usePlantStatus;
