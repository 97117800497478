import { TogglesData } from '../../../lib/Toggles/Toggles';
import { Label } from '../../../types';
import { LocalisedLabel } from '../../../lib';
import { convertStringToKey } from '../../../utils';

const useTogglesData = (values: Label[]): TogglesData =>
  values.map((value) => ({
    value: convertStringToKey(value.default),
    label: <LocalisedLabel>{value}</LocalisedLabel>,
    exclusive: true
  }));

export default useTogglesData;
