import { Static, Type } from '@sinclair/typebox';
import getTemplateSchema from '../getTemplateSchema';
import { selectableEnum } from '../../Config/utils';
import { FeatureName } from '../../types';
import { LabelSchema } from '../../Config/sharedSchemas';

const featureSchema = Type.Object({
  title: LabelSchema({ title: 'Title', description: 'The title of the feature' }),
  featureName: selectableEnum(FeatureName, {
    title: 'Feature Name',
    description: 'The name of the recommended feature'
  })
});

export type Feature = Static<typeof featureSchema>;

const options = Type.Object({
  chartTitle: LabelSchema({ title: 'Chart Title', description: 'The title for the chart' }),
  leftFeature: featureSchema,
  rightFeature: featureSchema,
  performanceFeature: featureSchema,
  leftRangeStart: Type.Optional(
    Type.Number({
      title: 'Left Range Start',
      description: 'Optional range start value for left axis'
    })
  ),
  leftRangeEnd: Type.Optional(
    Type.Number({
      title: 'Left Range End',
      description: 'Optional range end value for left axis'
    })
  ),
  rightRangeStart: Type.Optional(
    Type.Number({
      title: 'Right Range Start',
      description: 'Optional range start value for right axis'
    })
  ),
  rightRangeEnd: Type.Optional(
    Type.Number({
      title: 'Right Range End',
      description: 'Optional range end value for right axis'
    })
  )
});

export type Options = Static<typeof options>;

export const templateSlug = 'HistoricalGasAnalyserPage';

export const templateSchema = Type.Object(getTemplateSchema({ templateSlug, options }), {
  title: 'Historical Gas Analyser Page',
  description: 'Display historical data on left and right axis withe high and low bounds'
});

export type TemplateSchema = Static<typeof templateSchema>;
