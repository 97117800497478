import { useIntl } from 'react-intl';
import { Label } from '../types';

const useFormatLabel = () => {
  const { formatMessage } = useIntl();
  return (label: Label) => {
    if (label.id) {
      return formatMessage({ id: label.id, defaultMessage: label.default });
    }
    return label.default;
  };
};

export default useFormatLabel;
