import { useIntl } from 'react-intl';
import { useEzerTheme } from '../../../EzerThemeProvider';
import { DESCRIPTOR as Week } from '../../../messages/Week';
import { DESCRIPTOR as TimeInOk } from '../messages/TimeInOk';
import { DESCRIPTOR as TimeInHigh } from '../messages/TimeInHigh';
import { DESCRIPTOR as TimeInLow } from '../messages/TimeInLow';
import { DESCRIPTOR as NumberHigh } from '../messages/NumberHigh';
import { DESCRIPTOR as NumberLow } from '../messages/NumberLow';
import { DESCRIPTOR as AverageDurationHigh } from '../messages/AverageDurationHigh';
import { DESCRIPTOR as AverageDurationLow } from '../messages/AverageDurationLow';

type ColumnData = {
  field: string;
  // eslint-disable-next-line
  headerName: any;
  width: number;
};
const useColumns = (featureName: string): ColumnData[] => {
  const { spacing } = useEzerTheme();

  const { formatMessage } = useIntl();

  return [
    {
      field: 'week',
      headerName: formatMessage(Week),
      width: spacing(10)
    },
    {
      field: 'percentage_time_good',
      headerName: `${formatMessage(TimeInOk, { featureTitle: featureName })} (%)`,
      width: spacing(22)
    },
    {
      field: 'percentage_time_high',
      headerName: `${formatMessage(TimeInHigh, { featureTitle: featureName })} (%)`,
      width: spacing(22)
    },
    {
      field: 'percentage_time_low',
      headerName: `${formatMessage(TimeInLow, { featureTitle: featureName })} (%)`,
      width: spacing(22)
    },
    {
      field: 'high_period_count',
      headerName: formatMessage(NumberHigh, { featureTitle: featureName }),
      width: spacing(20)
    },
    {
      field: 'low_period_count',
      headerName: formatMessage(NumberLow, { featureTitle: featureName }),
      width: spacing(20)
    },
    {
      field: 'average_duration_high',
      headerName: `${formatMessage(AverageDurationHigh, { featureTitle: featureName })} (min)`,
      width: spacing(25)
    },
    {
      field: 'average_duration_low',
      headerName: `${formatMessage(AverageDurationLow, { featureTitle: featureName })} (min)`,
      width: spacing(25)
    }
  ];
};
export default useColumns;
