import { TooltipProps } from 'recharts';
import { ChartTooltip } from '../../../lib';
import { getLocalDateAndTime, useTimezone } from '../../../utils';
import { DateTime } from '../../../messages';

type Props = TooltipProps<string, string> & { descriptor: string };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const generateItemListData = (payload: any[], descriptor: string) =>
  payload.map(({ value, color }) => ({
    label: `${descriptor}: ${value}`,
    color
  }));

const TooltipContent = ({ label = '', payload = [], descriptor }: Props) => {
  const timezone = useTimezone();
  const date = label ? getLocalDateAndTime(new Date(label), timezone) : '';
  // payload is sometimes 'undefined'
  const itemListData = payload ? generateItemListData(payload, descriptor) : [];
  const localisedLabel = (
    <>
      <DateTime />: {date}
    </>
  );
  return <ChartTooltip label={localisedLabel} itemListData={itemListData} />;
};

export default TooltipContent;
