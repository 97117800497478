import { FeatureName } from '../types';
import useMetadataQuery from '../serviceQueries/useMetadataQuery';

type MetadataRecord = {
  unit?: string;
};

export type Metadata = Partial<Record<keyof typeof FeatureName, MetadataRecord>>;

type Response = {
  data: Metadata;
  isLoading: boolean;
  isError: boolean;
};

const useMetadata = (featureNames: FeatureName[]): Response => {
  const { data = {}, isLoading, isError } = useMetadataQuery(featureNames);
  return {
    data: data.metadata ?? {},
    isLoading,
    isError
  };
};

export default useMetadata;
