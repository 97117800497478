import React from 'react';
import { createUseStyles } from 'react-jss';
import MessageContainer from './MessageContainer';
import { EzerTheme } from '../../../../EzerThemeProvider';

type Props = {
  children: React.ReactNode;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  message: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(20)
  }
}));
const MessageContent = ({ children }: Props) => {
  const styles = useStyles();
  return (
    <MessageContainer>
      <p className={styles.message}>{children}</p>
    </MessageContainer>
  );
};

export default MessageContent;
