import * as DailyAverageKpiUnrankedPage from './DailyAverageKpiUnrankedPage';
import * as FreeLimeTemplatePage from './FreeLimeTemplatePage';
import * as HistoricalGasAnalyserPage from './HistoricalGasAnalyserPage';
import * as HistoricalPerformanceImprovementPagePage from './HistoricalPerformanceImprovementPage';
import * as HistoricalQualityPage from './HistoricalQualityPage';
import * as HistoricalRecommendationsMultiAxisPage from './HistoricalRecommendationsMultiAxisPage';
import * as HistoricalRecommendationsNewConfigPage from './HistoricalRecommendationsNewConfigPage';
import * as HistoricalWeeklyPerformancePage from './HistoricalWeeklyPerformancePage';
import * as TimelineTemplatePage from './TimelineTemplatePage';
import * as TodayPage from './TodayPage';
import * as HistoricalMokQualityPage from './HistoricalMokQualityPage';

const templates = [
  DailyAverageKpiUnrankedPage,
  FreeLimeTemplatePage,
  HistoricalGasAnalyserPage,
  HistoricalPerformanceImprovementPagePage,
  HistoricalQualityPage,
  HistoricalMokQualityPage,
  HistoricalRecommendationsMultiAxisPage,
  HistoricalRecommendationsNewConfigPage,
  HistoricalWeeklyPerformancePage,
  TimelineTemplatePage,
  TodayPage
] as const;

export default templates;
