import { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import type { EzerTheme } from '../../EzerThemeProvider';
import { EXCLUSIVE_PARAM_KEY, INCLUSIVE_PARAM_KEY } from './contants';

type Props = {
  children: ReactNode;
  value: string;
  isFirst: boolean;
  isLast: boolean;

  disabled?: boolean;
  exclusive?: boolean;
};

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    minWidth: spacing(7),
    paddingRight: spacing(2),
    paddingLeft: spacing(2),
    height: spacing(7),
    border: `1px solid ${palette.white}`,
    fontSize: spacing(2),
    color: palette.white,
    '&:hover': {
      cursor: 'pointer',
      boxShadow: `${palette.blackShadow} 0 0 0 150px inset`
    }
  },
  inclusiveButton: {
    borderRadius: spacing(0.5),
    marginRight: spacing(2)
  },
  lastInclusiveButton: {
    marginRight: 0
  },
  exclusiveButton: {
    borderRight: 'none'
  },
  firstExclusiveButton: {
    borderTopLeftRadius: spacing(0.5),
    borderBottomLeftRadius: spacing(0.5)
  },
  lastExclusiveButton: {
    borderTopRightRadius: spacing(0.5),
    borderBottomRightRadius: spacing(0.5),
    borderRight: `1px solid ${palette.white}`
  },
  active: {
    backgroundColor: palette.leaf,
    borderColor: palette.leaf
  },
  disabled: {
    opacity: 0.5
  }
}));

const ToggleButton = ({ children, value, isFirst, isLast, disabled, exclusive = false }: Props) => {
  const styles = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const inclusiveSearchParams = searchParams.get(INCLUSIVE_PARAM_KEY)?.split(',') || [];
  const exclusiveSearchParam = searchParams.get(EXCLUSIVE_PARAM_KEY);

  const isActive = exclusive
    ? exclusiveSearchParam === value
    : !!inclusiveSearchParams.find((paramValue) => paramValue === value);

  const onClickInclusive = () => {
    if (isActive) {
      const index = inclusiveSearchParams.indexOf(value);
      inclusiveSearchParams.splice(index, 1);
    } else {
      inclusiveSearchParams.push(value);
    }
    if (!!exclusiveSearchParam || inclusiveSearchParams.length !== 0) {
      searchParams.set(INCLUSIVE_PARAM_KEY, inclusiveSearchParams.join(','));
      setSearchParams(searchParams);
    }
  };
  const onClickExclusive = () => {
    if (!isActive) {
      searchParams.set(EXCLUSIVE_PARAM_KEY, value);
      setSearchParams(searchParams);
    }
  };
  return (
    <button
      onClick={exclusive ? onClickExclusive : onClickInclusive}
      className={classNames(
        styles.root,
        { [styles.inclusiveButton]: !exclusive },
        { [styles.lastInclusiveButton]: !exclusive && isLast },
        { [styles.exclusiveButton]: exclusive },
        { [styles.firstExclusiveButton]: exclusive && isFirst },
        { [styles.lastExclusiveButton]: exclusive && isLast },
        { [styles.active]: isActive },
        { [styles.disabled]: disabled }
      )}
      type="button"
      disabled={disabled}>
      {children}
    </button>
  );
};
export default ToggleButton;
