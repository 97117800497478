/* eslint camelcase: 0 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import useKpiByWeekQuery from '../../serviceQueries/useKpiByWeekQuery';
import { FeatureName } from '../../types';
import { convertPeriodToTime } from '../../utils';

type Period = {
  date: number;
  percentWithinBounds: number;
  sensorData: number | null;
  standardDeviation: number;
};

export type KpiData = {
  periods: Period[];
  upperBound: number;
  lowerBound: number;
};

type Response = {
  data: KpiData;
  isLoading: boolean;
  isError: boolean;
};

const generatePeriods = (data: any[]): Period[] =>
  data.map((dataItem) => ({
    date: convertPeriodToTime(dataItem.period),
    percentWithinBounds: dataItem.percent_within_bounds || 0,
    sensorData: dataItem.average_value || null,
    standardDeviation: dataItem.st_dev || 0
  }));

const useKpiData = (featureName: FeatureName, startDate: Date, endDate: Date): Response => {
  const { data, isLoading, isError } = useKpiByWeekQuery(featureName, startDate, endDate);
  return {
    data: {
      periods: isLoading || isError ? [] : generatePeriods(data?.periods || []),
      upperBound: data?.targets[0]?.upper_bound || 0,
      lowerBound: data?.targets[0]?.lower_bound || 0
    },
    isLoading,
    isError
  };
};
export default useKpiData;
