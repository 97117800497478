import React from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import ForDate from './ForDate';
import { EzerTheme } from '../../../../../EzerThemeProvider';
import { round } from '../../../../../utils';
import { Age } from '../../../../../lib';

type Props = {
  title: React.ReactNode;
  date: Date;
  value?: number;
  unit?: string;
};

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    display: 'flex',
    marginLeft: spacing(1),
    marginRight: spacing(1),
    columnGap: spacing(1),
    marginTop: spacing(1)
  },
  cell: {
    fontSize: spacing(2),
    lineHeight: `${spacing(2)}px`,
    height: spacing(5),
    textAlign: 'left',
    paddingTop: spacing(1.5),
    paddingLeft: spacing(1.25),
    paddingRight: spacing(1.25)
  },
  title: {
    width: spacing(22),
    backgroundColor: palette.lightCarbon
  },
  value: {
    width: spacing(18),
    fontSize: spacing(3),
    backgroundColor: palette.lightCarbon
  }
}));

const Row = ({ title, date, value, unit = '' }: Props) => {
  const styles = useStyles();
  return (
    <li className={styles.root}>
      <h3 className={classNames(styles.cell, styles.title)}>{title}</h3>
      {value !== undefined && (
        <p className={classNames(styles.cell, styles.value)}>
          {round(value)} {unit}
        </p>
      )}
      <p className={styles.cell}>
        <ForDate date={date} /> (<Age date={date} />)
      </p>
    </li>
  );
};
export default Row;
