import { UseQueryResult } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import type { ConfigWrapperType } from '../Config/ConfigSchema';
import { Api } from '../settings';
import useAuthorisedQuery from './useAuthorisedQuery';

const DIRECTORY = 'config/';
export const usePath = () => {
  const [search] = useSearchParams();
  const configAliasParam = search.get('configAlias');
  return configAliasParam ? `${DIRECTORY}?alias=${configAliasParam}` : DIRECTORY;
};

const useConfigQuery = (): UseQueryResult<ConfigWrapperType, unknown> => {
  const path = usePath();

  return useAuthorisedQuery(path, path, { refetchOnMount: false }, Api.serve);
};

export default useConfigQuery;
