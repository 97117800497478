import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import ProgressIndicator from './ProgressIndicator';
import { EzerTheme, useEzerTheme } from '../EzerThemeProvider';
import { Loading } from '../messages';

type Props = {
  className?: string;
  size?: 'large' | 'small';
  children?: string | React.ReactNode;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    paddingTop: spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  small: {
    paddingBottom: 3,
    justifyContent: 'flex-start'
  },
  message: {
    fontSize: spacing(3),
    display: 'inline-block'
  },
  smallMessage: {
    fontSize: spacing(2)
  },
  progressIndicator: {
    marginLeft: spacing(2),
    display: 'inline-block'
  },
  smallProgressIndicator: {
    marginLeft: spacing(1),
    position: 'relative',
    top: -spacing(0.25)
  }
}));

const LoadingMessage = ({ className, size = 'large', children = <Loading /> }: Props) => {
  const styles = useStyles();
  const isSmall = size === 'small';
  const { spacing } = useEzerTheme();
  return (
    <p className={classNames(styles.root, { [styles.small]: isSmall }, className)}>
      <span className={classNames(styles.message, { [styles.smallMessage]: isSmall })}>{children}</span>
      <ProgressIndicator
        className={classNames(styles.progressIndicator, { [styles.smallProgressIndicator]: isSmall })}
        isLoading
        size={isSmall ? spacing(3) : spacing(4)}
      />
    </p>
  );
};
export default LoadingMessage;
