import { Type, Static } from '@sinclair/typebox';
import { selectableEnum } from '../../Config/utils';
import { FeatureName } from '../../types';
import getTemplateSchema from '../getTemplateSchema';

const featureSchema = Type.Object({
  title: Type.String({ title: 'Title', description: 'The title of the feature' }),
  featureName: selectableEnum(FeatureName, {
    title: 'Feature Name',
    description: 'The name of the recommended feature'
  }),
  rangeStart: Type.Optional(
    Type.Number({
      title: 'Range Start',
      description: 'Optional range start value'
    })
  ),
  rangeEnd: Type.Optional(
    Type.Number({
      title: 'Range End',
      description: 'Optional range end value'
    })
  )
});

export type Feature = Static<typeof featureSchema>;

const options = Type.Object({
  features: Type.Array(featureSchema, {
    title: 'Historical Features',
    description: 'The list of historical features to be shown on this page'
  })
});

export type Options = Static<typeof options>;
export const templateSlug = 'TimelineTemplatePage';

export const templateSchema = Type.Object(getTemplateSchema({ templateSlug, options }), {
  title: 'Timeline Template Page',
  description: 'Historical data for plant data'
});

export type TemplateSchema = Static<typeof templateSchema>;
