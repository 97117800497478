import { FormattedMessage } from 'react-intl';
import usePlantStatus from './usePlantStatus';
import { Timeline, LoadingContent, SectionContent, SectionTitle, MessageContent } from '../../lib';
import useTimelineData from './useTimelineData';
import { DataCouldNotBeLoaded } from '../../../../messages';

const PlantStatusTimeline = () => {
  const { data, isLoading, isError } = usePlantStatus();
  const timelineData = useTimelineData(data);

  return (
    <div data-testid="plant-status-timeline">
      <SectionTitle>
        <FormattedMessage defaultMessage="Plant Status" id="page.today.plantStatus.header" />
      </SectionTitle>
      <SectionContent>
        {isLoading && <LoadingContent />}
        {isError && (
          <MessageContent>
            <DataCouldNotBeLoaded />
          </MessageContent>
        )}
        {!isLoading && !isError && <Timeline data={timelineData} dataTestId="plant-status-timeline" />}
      </SectionContent>
    </div>
  );
};

export default PlantStatusTimeline;
