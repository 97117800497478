import * as React from 'react';
import { FormEvent, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from './Button';
import { EzerTheme } from '../../../../EzerThemeProvider';
import useAddServeFeedbackMutation from '../../../../serviceQueries/useAddServeFeedbackMutation';
import { Recommendation } from '../../../../types';
import { Feedback } from '../../../../utils/useHistoricalFeedbacks';
import { Save } from '../../../../messages';

type Props = {
  recommendation: Recommendation;
  feedbacks: Feedback[];
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  input: {
    marginRight: spacing(1),
    width: spacing(46.5)
  }
}));

const FeedbackForm = ({ recommendation, feedbacks }: Props) => {
  const styles = useStyles();
  const { timestamp } = recommendation;
  const feedback = feedbacks.find((item) => item.timestamp.getTime() === timestamp.getTime());
  const [text, setText] = useState(feedback?.content ?? '');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const addServeFeedbackMutation = useAddServeFeedbackMutation();

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    addServeFeedbackMutation.mutate(
      {
        timestamp: recommendation.timestamp,
        feedback: text
      },
      {
        onSuccess: () => {
          setButtonDisabled(true);
          setIsLoading(false);
        }
      }
    );
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setButtonDisabled(false);
    setText(event.target.value);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  return (
    <form onSubmit={onSubmit} className={styles.root}>
      <input
        className={styles.input}
        type="text"
        defaultValue={feedback?.content ?? ''}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <Button type="submit" disabled={buttonDisabled} isLoading={isLoading}>
        <Save />
      </Button>
    </form>
  );
};

export default FeedbackForm;
