import { SoftSensor } from '../../../utils/useHistoricalSoftSensors';
import { FeatureName } from '../../../types';

type Payload = {
  date: number;
  value?: number;
};

export type ChartData = Payload[];

const useChartData = (data: SoftSensor[], featureName: FeatureName): ChartData =>
  data
    .filter((datum) => datum.feature === featureName)
    .map((datum) => ({
      date: new Date(datum.timestamp).getTime(),
      value: datum.value
    }));

export default useChartData;
