import { useIntl } from 'react-intl';
import { DataTable } from '../../lib';
import { FeatureName } from '../../types';
import { getLocalDateAndTime, useTimezone } from '../../utils';
import { PlantData } from '../../utils/useHistoricalPlantData';
import { Feature } from './schema';
import { DESCRIPTOR as Time } from '../../messages/Time';

type Props = {
  data: PlantData;
  features: Feature[];
  fileName: string;
};

const WIDE_FIELDS = [
  FeatureName.s_c_sol_xrf_fcao,
  FeatureName.f_k_coal_moisture,
  FeatureName.f_k_coal_fineness,
  FeatureName.g_pc_gol_co,
  FeatureName.g_pc_gol_o2,
  FeatureName.p_pc_ammonia_flowrate,
  FeatureName.g_phstack_gol_nox
];

type ColumnData = {
  field: string;
  // eslint-disable-next-line
  headerName: any;
  width: number;
};

const useColumnData = (features: Feature[]): ColumnData[] => {
  const { formatMessage } = useIntl();
  return [
    { field: 'timestamp', headerName: formatMessage(Time), width: 200 },
    ...features.map(({ featureName, title }) => ({
      field: featureName,
      headerName: title,
      width: WIDE_FIELDS.includes(featureName) ? 240 : 120
    }))
  ];
};

const useTableData = (data: PlantData, featureNames: FeatureName[]) => {
  const timezone = useTimezone();
  return data.items.map((item) => {
    const { data: metricData, id } = item;

    const valuesByFeatureName: { [key in FeatureName]?: number } = {};
    featureNames.forEach((featureName) => {
      valuesByFeatureName[featureName] = metricData?.[featureName]?.value;
    });
    return {
      id,
      timestamp: getLocalDateAndTime(new Date(item.timestamp), timezone),
      ...valuesByFeatureName
    };
  });
};

const Table = ({ data, features, fileName }: Props) => {
  const featureNames = features.map(({ featureName }) => featureName);
  const columnData = useColumnData(features);
  const tableData = useTableData(data, featureNames);
  return <DataTable data={tableData} columns={columnData} fileName={fileName} />;
};

export default Table;
