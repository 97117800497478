import { addHours, subHours } from 'date-fns';
import { DataLoadErrorMessage, LoadingMessage, LocalisedLabel, PageTitle } from '../../lib';
import { useFileName, useHistoricalMokTargets, useHistoricalPlantData, useHistoricalSoftSensors } from '../../utils';
import useNow from '../../useNow';
import FilterControls from '../../FilterControls/FilterControls';
import ChartContainer from './ChartContainer';
import Table from './Table/Table';
import useStartDate from '../../FilterControls/useStartDate';
import useEndDate from '../../FilterControls/useEndDate';
import { TemplateSchema } from './schema';

const HistoricalMokQualityPage = ({ label, options: { chartConfigs } }: TemplateSchema) => {
  const now = useNow();
  const startDate = useStartDate(subHours(now, 24));
  const endDate = useEndDate(addHours(now, 6));

  const modelledFeatureNames = chartConfigs.length > 0 ? [chartConfigs[0].featureName] : [];

  const {
    data: modelledData,
    isLoading: isModelledDataLoading,
    isError: isModelledDataError
  } = useHistoricalSoftSensors(modelledFeatureNames, startDate, endDate);

  const measuredFeatureNames = chartConfigs.length > 1 ? [chartConfigs[1].featureName] : [];

  const {
    data: measuredData,
    isLoading: isMeasuredDataLoading,
    isError: isMeasuredDataError
  } = useHistoricalPlantData(measuredFeatureNames, startDate, endDate);

  const {
    data: targetData,
    isLoading: isTargetDataLoading,
    isError: isTargetDataError
  } = useHistoricalMokTargets(modelledFeatureNames, startDate, endDate);

  const {
    data: oldTargetData,
    isLoading: isOldTargetDataLoading,
    isError: isOldTargetDataError
  } = useHistoricalMokTargets(modelledFeatureNames, subHours(startDate, 24), endDate);

  const isLoading = isModelledDataLoading || isMeasuredDataLoading || isTargetDataLoading || isOldTargetDataLoading;
  const isError = isModelledDataError || isMeasuredDataError || isTargetDataError || isOldTargetDataError;
  const fileName = useFileName(label, startDate, endDate);

  return (
    <>
      <PageTitle>
        <LocalisedLabel>{label}</LocalisedLabel>
      </PageTitle>
      <FilterControls isLoading={isLoading} showFutureWindow />
      {isLoading && <LoadingMessage />}
      {isError && <DataLoadErrorMessage />}
      {!isLoading && !isError && startDate && endDate && chartConfigs.length >= 2 && (
        <>
          <ChartContainer
            modelledData={modelledData}
            measuredData={measuredData}
            targetData={targetData}
            oldTargetData={oldTargetData}
            modelled={chartConfigs[0].featureName}
            measured={chartConfigs[1].featureName}
            startDate={startDate}
            endDate={endDate}
            xAxisLabel={chartConfigs[0].xAxisLabel}
            yAxisLabel={chartConfigs[0].yAxisLabel}
            rangeStart={chartConfigs[0].rangeStart}
            rangeEnd={chartConfigs[0].rangeEnd}
          />
          <Table
            modelledData={modelledData}
            measuredData={measuredData}
            chartConfigs={chartConfigs}
            fileName={fileName}
          />
        </>
      )}
    </>
  );
};

export default HistoricalMokQualityPage;
