{
  "items": [
    {
      "timestamp": "2024-10-16T12:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T12:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T12:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T11:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T11:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T11:15:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 2.04,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-16T11:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T10:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T10:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T10:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T10:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T09:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T09:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T09:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T09:00:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 2.44,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-16T08:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T08:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T08:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T08:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T07:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T07:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T07:15:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 1.87,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-16T07:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T06:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T06:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T06:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T06:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T05:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T05:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T05:15:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 2.28,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-16T05:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T04:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T04:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T04:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T04:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T03:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T03:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T03:15:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 2.2,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-16T03:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T02:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T02:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T02:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T02:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T01:45:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 1.68,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-16T01:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T01:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T01:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T00:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T00:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T00:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-16T00:00:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 2.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-15T23:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T23:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T23:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T23:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T22:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T22:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T22:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T22:00:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 3.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-15T21:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T21:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T21:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T21:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T20:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T20:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T20:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T20:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T19:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T19:30:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 2.37,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-15T19:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T19:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T18:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T18:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T18:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T18:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T17:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T17:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T17:15:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 1.9,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-15T17:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T16:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T16:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T16:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T16:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T15:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T15:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T15:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T15:00:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 1.21,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-15T14:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T14:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T14:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T14:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T13:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T13:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T13:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T13:00:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 1.36,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-15T12:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T12:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T12:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T12:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T11:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T11:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T11:15:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 1.07,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-15T11:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T10:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T10:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T10:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T10:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T09:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T09:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T09:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T09:00:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 1.46,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-15T08:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T08:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T08:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T08:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T07:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T07:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T07:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T07:00:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 1.32,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-15T06:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T06:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T06:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T06:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T05:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T05:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T05:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T05:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T04:45:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 1.56,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-15T04:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T04:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T04:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T03:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T03:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T03:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T03:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T02:45:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 1.96,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-15T02:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T02:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T02:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T01:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T01:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T01:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T01:00:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 1.73,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-15T00:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T00:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T00:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-15T00:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-14T23:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-14T23:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-14T23:15:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 2.08,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-14T23:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-14T22:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-14T22:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-14T22:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-14T22:00:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-14T21:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-14T21:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-14T21:15:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-14T21:00:00+00:00",
      "data": {
        "s_c_sol_fcao": {
          "value": 1.5,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-10-14T20:45:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    },
    {
      "timestamp": "2024-10-14T20:30:00+00:00",
      "data": {
        "s_c_sol_fcao": null
      }
    }
  ]
}
