import { subHours } from 'date-fns';
import { FeatureName, Recommendation } from '../types';
import useConfig from '../Config/useConfig';
import useHistoricalPlantData from './useHistoricalPlantData';
import convertPlantDataToRecs from './convertPlantDataToRecs';

type Response = {
  data: Recommendation[];
  isLoading: boolean;
  isError: boolean;
};

const useHistoricalMokTargets = (
  featureNames: FeatureName[],
  startDate: Date,
  endDate: Date,
  refetch = false
): Response => {
  const { data: config, isLoading: isConfigLoading, isError: isConfigError } = useConfig();

  const featureConfigs = config?.app.kpiFeatureConfigs ?? [];
  const targetFeatureNames = featureConfigs.reduce((acc: FeatureName[], featureConfig) => {
    if (featureNames.includes(featureConfig.name) && featureConfig?.target) {
      acc.push(featureConfig.target);
    }
    return acc;
  }, []);

  const {
    data,
    isLoading: isRecommendationsLoading,
    isError: isRecommendationsError
  } = useHistoricalPlantData(targetFeatureNames, subHours(startDate, 24), endDate, 15, refetch);

  return {
    data: targetFeatureNames.length >= 1 ? convertPlantDataToRecs(data, targetFeatureNames[0]) : [],
    isLoading: isConfigLoading || isRecommendationsLoading,
    isError: isConfigError || isRecommendationsError
  };
};

export default useHistoricalMokTargets;
