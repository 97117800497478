{
  "periods": [
    {
      "period": "2024-W35",
      "low_percentage_time": 3.0514028261314765,
      "ok_percentage_time": 58.5398320704485,
      "high_percentage_time": 35.920540651238994,
      "unknown_percentage_time": 2.4882244521810364,
      "low_periods": 5,
      "ok_periods": 58,
      "high_periods": 52,
      "unknown_periods": 3,
      "average_low_duration": 3576.0,
      "average_ok_duration": 5914.137931034483,
      "average_high_duration": 4047.6923076923076,
      "average_unknown_duration": 4860.0
    },
    {
      "period": "2024-W36",
      "low_percentage_time": 4.246031746031746,
      "ok_percentage_time": 71.7063492063492,
      "high_percentage_time": 22.24206349206349,
      "unknown_percentage_time": 1.8055555555555556,
      "low_periods": 5,
      "ok_periods": 47,
      "high_periods": 41,
      "unknown_periods": 2,
      "average_low_duration": 5136.0,
      "average_ok_duration": 9227.234042553191,
      "average_high_duration": 3280.9756097560976,
      "average_unknown_duration": 5460.0
    },
    {
      "period": "2024-W37",
      "low_percentage_time": 0.36706349206349204,
      "ok_percentage_time": 90.07936507936508,
      "high_percentage_time": 9.553571428571429,
      "unknown_percentage_time": 0.0,
      "low_periods": 1,
      "ok_periods": 13,
      "high_periods": 12,
      "unknown_periods": 0,
      "average_low_duration": 2220.0,
      "average_ok_duration": 41907.692307692305,
      "average_high_duration": 4815.0,
      "average_unknown_duration": 0.0
    },
    {
      "period": "2024-W38",
      "low_percentage_time": 0.0,
      "ok_percentage_time": 0.0,
      "high_percentage_time": 0.0,
      "unknown_percentage_time": 100.0,
      "low_periods": 0,
      "ok_periods": 0,
      "high_periods": 0,
      "unknown_periods": 1,
      "average_low_duration": 0.0,
      "average_ok_duration": 0.0,
      "average_high_duration": 0.0,
      "average_unknown_duration": 206100.0
    },
    {
      "period": "2024-W39",
      "low_percentage_time": 0.0,
      "ok_percentage_time": 94.0291415901172,
      "high_percentage_time": 4.450427621159329,
      "unknown_percentage_time": 1.5204307887234716,
      "low_periods": 0,
      "ok_periods": 12,
      "high_periods": 9,
      "unknown_periods": 2,
      "average_low_duration": 0.0,
      "average_ok_duration": 29685.0,
      "average_high_duration": 1873.3333333333333,
      "average_unknown_duration": 2880.0
    }
  ],
  "total_low_percentage_time": 1.9231253938248267,
  "total_ok_percentage_time": 70.47763074984248,
  "total_high_percentage_time": 17.62822936357908,
  "total_unknown_percentage_time": 9.971014492753623,
  "average_low_duration": 4161.818181818182,
  "average_high_duration": 3681.0526315789475,
  "average_bad_duration": 3723.36,
  "average_unknown_duration": 29670.0
}
