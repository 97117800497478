import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import AxisTitle from './AxisTitle';
import { EzerTheme } from '../EzerThemeProvider';
import { Label } from '../types';
import LocalisedLabel from './LocalisedLabel';

type Props = {
  title: Label;
  className: string;
  unit?: string;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    transform: 'rotate(180deg)',
    top: 0,
    bottom: spacing(2.5),
    position: 'absolute',
    writingMode: 'vertical-lr'
  }
}));

const VerticalLabel = ({ title, className, unit }: Props) => {
  const styles = useStyles();
  return (
    <AxisTitle
      title={<LocalisedLabel>{title}</LocalisedLabel>}
      className={classNames(className, styles.root)}
      unit={unit}
    />
  );
};

export default VerticalLabel;
