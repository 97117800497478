import { Static, Type } from '@sinclair/typebox';
import { featureConfigSchema, kpiFeatureConfigSchema, languageSchema, timezoneSchema } from './sharedSchemas';

export const appSchema = Type.Object(
  {
    plantRef: Type.String({
      title: 'Plant Ref',
      description: 'The plant ref for this config.'
    }),
    plantName: Type.String({
      title: 'Plant Name',
      description: 'The plant display name.'
    }),
    languages: Type.Array(languageSchema, {
      title: 'Supported Languages'
    }),
    timezone: timezoneSchema,
    // Note, if changing this make sure the KPI endpoints under src/serve/ still
    // work as they depend on this structure.
    kpiFeatureConfigs: Type.Array(kpiFeatureConfigSchema, {
      title: 'KPI Feature Configs',
      description: 'The configuration for the KPI features'
    }),
    recommendationDataFeatureConfigs: Type.Array(featureConfigSchema, {
      title: 'Recommendation Data Feature Configs',
      description: 'The configuration for recommendation feature data'
    }),
    recommendationReviewDestination: Type.String({
      title: 'Review button destination',
      description: 'Relative url e.g. /historical-recommendations'
    }),
    shiftLength: Type.Optional(
      Type.Integer({
        title: 'Shift Length',
        description: 'Shift length in hours.'
      })
    ),
    shiftStartTime: Type.Optional(
      Type.String({
        title: 'Shift Start Time',
        description: 'Start time for first shift. Should be in plant local time e.g. 08:30:00.'
      })
    )
  },
  {
    title: 'App Config',
    description: 'The app general config'
  }
);
export type AppConfigType = Static<typeof appSchema>;

export const appUiSchema = {
  timezone: {
    'ui:options': {
      // placeholder to show how to set a field option
      disabled: false
    }
  }
};
