import { createUseStyles } from 'react-jss';
import { useSearchParams } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import { EzerTheme } from '../../EzerThemeProvider';
import FulfillmentRecorderProvider from '../../FulfillmentRecorderProvider';
import PlantSection from './PlantSection/PlantSection';
import RecommendationContent from './RecommendationContent/RecommendationContent';
import { QualitySection } from './lib';
import GasAnalyserSection from './lib/GasAnalyserSection/GasAnalyserSection';
import KpiByHourSection from './lib/KpiByHourSection/KpiByHourSection';
import { TemplateSchema } from './schema';
import { TemplateOptionsContext, useTemplateOptions } from './TemplateOptionsContext';
import MokQualitySection from './lib/MokQualitySection/MokQualitySection';

const useStyles = createUseStyles(({ spacing, mediaQueries, maxContentWidth }: EzerTheme) => ({
  root: {
    [mediaQueries.desktop]: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  leftColumn: {
    [mediaQueries.desktop]: {
      width: maxContentWidth * 0.5 - spacing(1)
    }
  },
  rightColumn: {
    [mediaQueries.desktop]: {
      width: maxContentWidth * 0.5 - spacing(1)
    }
  }
}));

const TodayPageComponent = () => {
  const styles = useStyles();
  const templateOptions = useTemplateOptions();
  const [searchParams] = useSearchParams();
  const customer = searchParams.get('customer');
  return (
    <FulfillmentRecorderProvider>
      <div className={styles.root} data-testid="today-page">
        <div className={styles.leftColumn}>
          <PlantSection />
          <div data-testid="recommendation-content">
            <RecommendationContent />
          </div>
        </div>
        <div className={styles.rightColumn}>
          {templateOptions.qualityChartConfigs.map((chartConfig) => (
            <div key={chartConfig.modelled.name}>
              {customer === 'mok' ? (
                <MokQualitySection config={chartConfig} />
              ) : (
                <QualitySection config={chartConfig} />
              )}
            </div>
          ))}

          {templateOptions.gasAnalyserChartConfigs.map((chartConfig) => (
            <div key={chartConfig.leftFeatureConfig.feature.name}>
              <GasAnalyserSection config={chartConfig} />
            </div>
          ))}
          {templateOptions.kpiChartConfigs.map((chartConfig) => (
            <div key={chartConfig.feature.name}>
              <KpiByHourSection config={chartConfig} />
            </div>
          ))}
        </div>
      </div>
    </FulfillmentRecorderProvider>
  );
};

const TodayPage = ({ options }: PropsWithChildren<TemplateSchema>) => (
  <TemplateOptionsContext.Provider value={options}>
    <TodayPageComponent />
  </TemplateOptionsContext.Provider>
);

export default TodayPage;
