import React from 'react';
import { PlantStatus } from './usePlantStatus';
import TimelineData from '../../lib/Timeline/TimelineData';
import {
  PlantHasGapsInFeed,
  PlantHasReducedThroughput,
  PlantIsCoolingDown,
  PlantIsInShutdown,
  PlantIsLightingUp,
  PlantIsOk,
  PlantIsWarmingUp,
  Unknown
} from './messages';

const useTimelineData = (data: PlantStatus[]): TimelineData[] => {
  const getColour = (status: string) => {
    if (['SHUTDOWN', 'WARMING_UP', 'COOLING_DOWN', 'LIGHT_UP', 'GAPS_IN_FEED'].includes(status)) {
      return 'red';
    }
    if (status === 'UNKNOWN') {
      return 'black';
    }
    if (status === 'REDUCED_THROUGHPUT') {
      return 'amber';
    }
    return 'green';
  };
  const getExplanation = (status: string) => {
    if (status === 'SHUTDOWN') {
      return <PlantIsInShutdown />;
    }
    if (status === 'WARMING_UP') {
      return <PlantIsWarmingUp />;
    }
    if (status === 'COOLING_DOWN') {
      return <PlantIsCoolingDown />;
    }
    if (status === 'LIGHT_UP') {
      return <PlantIsLightingUp />;
    }
    if (status === 'GAPS_IN_FEED') {
      return <PlantHasGapsInFeed />;
    }
    if (status === 'REDUCED_THROUGHPUT') {
      return <PlantHasReducedThroughput />;
    }
    if (status === 'UNKNOWN') {
      return <Unknown />;
    }
    return <PlantIsOk />;
  };
  return data.map(({ timestamp, status }) => ({
    timestamp,
    colour: getColour(status),
    explanation: getExplanation(status)
  }));
};

export default useTimelineData;
