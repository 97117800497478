import React from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../EzerThemeProvider';

type Props = {
  children: React.ReactNode;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    marginLeft: spacing(2),
    marginRight: spacing(2),
    paddingTop: spacing(3),
    paddingBottom: spacing(1),
    fontSize: spacing(2),
    lineHeight: `${spacing(3)}px`
  }
}));

const MessageContent = ({ children }: Props) => {
  const styles = useStyles();
  return <p className={styles.root}>{children}</p>;
};

export default MessageContent;
