import { Span, SpanStatusCode } from '@opentelemetry/api';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { LongTaskInstrumentation } from '@opentelemetry/instrumentation-long-task';
import { Resource } from '@opentelemetry/resources';
import {
  BatchSpanProcessor,
  ConsoleSpanExporter,
  SimpleSpanProcessor,
  WebTracerProvider
} from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { flatten } from 'flat';
import { v4 as uuidV4 } from 'uuid';
import { authPromise, withTimeout } from './authorisation';
import './pageLifecycle';

const refreshId = uuidV4();
// @ts-ignore
window.refreshId = refreshId;

if (!sessionStorage.getItem('sessionStorageId')) sessionStorage.setItem('sessionStorageId', uuidV4());
// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
const sessionStorageId = sessionStorage.getItem('sessionStorageId')!;

if (!localStorage.getItem('localStorageId')) localStorage.setItem('localStorageId', uuidV4());
// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
const localStorageId = localStorage.getItem('localStorageId')!;

export const provider = new WebTracerProvider({
  resource: new Resource(
    flatten({
      [SemanticResourceAttributes.SERVICE_NAME]: 'deltazero-web',
      [SemanticResourceAttributes.SERVICE_NAMESPACE]: 'ezer',
      env: process.env.REACT_APP_ENV,
      app: {
        url: process.env.REACT_APP_URL,
        git: {
          sha: process.env.REACT_APP_GIT_SHA,
          ref: process.env.REACT_APP_GIT_REF
        },
        github: {
          build: process.env.REACT_APP_GITHUB_RUN
        }
      },
      browser: { refreshId, sessionStorageId, localStorageId }
    }),
    (async () => {
      // DANGER!  ACTHUNG! PROMISES IN HERE MUST NOT FAIL!
      // ...OR NONE OF THESE ATTRIBUTES WILL BE PROPAGATED!
      const auth = await withTimeout(10_000, authPromise);
      return flatten({
        browser: {},
        app: { ...auth }
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      }) as any;
    })()
  )
});

const pushToHoneyComb = !!process.env.REACT_APP_HONEYCOMB_API_KEY;
if (pushToHoneyComb) {
  const exporter = new OTLPTraceExporter({
    url: 'https://api.honeycomb.io/v1/traces',
    headers: {
      'x-honeycomb-team': process.env.REACT_APP_HONEYCOMB_API_KEY
    }
  });
  provider.addSpanProcessor(new BatchSpanProcessor(exporter));
}
if (process.env.REACT_APP_LOG_TELEMETRIES_TO_CONSOLE === 'true') {
  provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
}

provider.register({
  contextManager: new ZoneContextManager()
});

function dedupEvent(target: unknown) {
  const cacheKey = JSON.stringify(target);
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const cached = !!(window as any)[cacheKey];
  if (cached) return true;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  (window as any)[cacheKey] = true;
  const deleteCache = () => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    delete (window as any)[cacheKey];
  };
  const cacheExpirationMs = 10;
  setTimeout(deleteCache, cacheExpirationMs);
  return false;
}

const setLocation = (span: Span) => {
  const { href, origin, pathname, search } = window.location;
  span.setAttributes(flatten({ location: { href, origin, pathname, search } }));
};

// Regex to match your backend urls. This should be updated.
const backendUrlMatcher = /.+(carbonre-dns-test.link)|(.execute-api.eu-west-2.amazonaws.com).+/g;
registerInstrumentations({
  instrumentations: [
    getWebAutoInstrumentations({
      '@opentelemetry/instrumentation-xml-http-request': {
        propagateTraceHeaderCorsUrls: [backendUrlMatcher],
        applyCustomAttributesOnSpan: (span, xhr) => {
          setLocation(span);
          if (xhr.status && xhr.status > 299) {
            span.setStatus({ code: SpanStatusCode.ERROR });
          }
        }
      },
      '@opentelemetry/instrumentation-fetch': {
        propagateTraceHeaderCorsUrls: [backendUrlMatcher],
        applyCustomAttributesOnSpan: (span, request, result) => {
          setLocation(span);
          if (result.status && result.status > 299) {
            span.setStatus({ code: SpanStatusCode.ERROR });
          }
        }
      },
      '@opentelemetry/instrumentation-user-interaction': {
        eventNames: ['click', 'submit', 'keypress'],
        shouldPreventSpanCreation: (eventType, elem, span) => {
          setLocation(span);
          const { innerText, id } = elem;
          const value = 'value' in elem ? (elem as HTMLInputElement).value : undefined;
          const dataTestId = elem.getAttribute('data-testid');
          const href = elem.getAttribute('href');

          /* eslint-disable no-param-reassign, @typescript-eslint/no-explicit-any */
          const xpath = (span as any).attributes.target_xpath;
          delete (span as any).attributes.target_xpath;
          const element = (span as any).attributes.target_element;
          delete (span as any).attributes.target_element;
          /* eslint-enable no-param-reassign, @typescript-eslint/no-explicit-any */

          const target = { innerText, id, dataTestId, xpath, element, eventType, href, value };
          span.setAttributes(flatten({ target }));

          return dedupEvent(target);
        }
      }
    }),
    new LongTaskInstrumentation({
      observerCallback: (span) => {
        setLocation(span);
      }
    })
  ]
});

export default provider;
