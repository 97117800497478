{
  "periods": [
    {
      "period": "2024-09-25 16:00:00+00:00",
      "average_value": null,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-25 17:00:00+00:00",
      "average_value": 767.51,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-25 18:00:00+00:00",
      "average_value": 736.84,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-25 19:00:00+00:00",
      "average_value": 723.57,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-25 20:00:00+00:00",
      "average_value": 708.38,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-25 21:00:00+00:00",
      "average_value": 734.78,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-25 22:00:00+00:00",
      "average_value": 741.33,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-25 23:00:00+00:00",
      "average_value": 741.02,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 00:00:00+00:00",
      "average_value": 728.85,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 01:00:00+00:00",
      "average_value": 723.5,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 02:00:00+00:00",
      "average_value": 721.57,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 03:00:00+00:00",
      "average_value": 733.82,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 04:00:00+00:00",
      "average_value": 723.2,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 05:00:00+00:00",
      "average_value": 1366.52,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 06:00:00+00:00",
      "average_value": 803.13,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 07:00:00+00:00",
      "average_value": 744.94,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 08:00:00+00:00",
      "average_value": 774.69,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 09:00:00+00:00",
      "average_value": 746.47,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 10:00:00+00:00",
      "average_value": 734.39,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 11:00:00+00:00",
      "average_value": 728.49,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 12:00:00+00:00",
      "average_value": 730.55,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 13:00:00+00:00",
      "average_value": 744.41,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 14:00:00+00:00",
      "average_value": 741.15,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 15:00:00+00:00",
      "average_value": 732.44,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 16:00:00+00:00",
      "average_value": 728.6,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 17:00:00+00:00",
      "average_value": null,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 18:00:00+00:00",
      "average_value": null,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 19:00:00+00:00",
      "average_value": null,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 20:00:00+00:00",
      "average_value": null,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-09-26 21:00:00+00:00",
      "average_value": null,
      "st_dev": null,
      "percent_within_bounds": null
    }
  ],
  "baselines": [
    {
      "start_period": "2024-09-25T16:00:00",
      "end_period": "2024-09-26T22:00:00",
      "value": 800.0
    }
  ],
  "targets": null
}
