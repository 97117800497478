import { useIntl } from 'react-intl';
import { useEzerTheme } from '../../../EzerThemeProvider';
import type { Feature } from '../schema';
import { DESCRIPTOR as Time } from '../../../messages/Time';
import { DESCRIPTOR as Historical } from '../../../messages/Historical';
import { DESCRIPTOR as Feedback } from '../../../messages/Feedback';
import { DESCRIPTOR as Recommended } from '../../../messages/Recommended';
import { useFormatLabel } from '../../../utils';

type ColumnData = {
  field: string;
  // eslint-disable-next-line
  headerName: any;
  width: number;
};
const useColumns = (features: Feature[]): ColumnData[] => {
  const { spacing } = useEzerTheme();
  const { formatMessage } = useIntl();
  const formatLabel = useFormatLabel();
  const columns = [
    {
      field: 'timestamp',
      headerName: formatMessage(Time),
      width: spacing(20)
    }
  ];

  features.forEach(({ title, recommendedFeatureName, historicalFeatureName }) => {
    columns.push({
      field: `${historicalFeatureName}-historical`,
      headerName: `${formatMessage(Historical)} ${formatLabel(title)}`,
      width: spacing(30)
    });
    columns.push({
      field: `${recommendedFeatureName}-recommended`,
      headerName: `${formatMessage(Recommended)} ${formatLabel(title)}`,
      width: spacing(30)
    });
  });

  columns.push({ field: 'feedback', headerName: formatMessage(Feedback), width: spacing(60) });
  return columns;
};

export default useColumns;
