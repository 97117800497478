import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SimplePage } from './lib';

const NotFoundPage = () => (
  <SimplePage
    title={<FormattedMessage defaultMessage="Page not found" id="page.NotFound.header" />}
    description={
      <FormattedMessage defaultMessage="Please navigate away using the top menu." id="page.NotFound.advice" />
    }
  />
);

export default NotFoundPage;
