{
  "config": {
    "app": {
      "languages": [
        "en"
      ],
      "plantRef": "adr",
      "timezone": "America/Sao_Paulo",
      "recommendationDataFeatureConfigs": [
        {
          "defaultColour": "leaf",
          "to": "",
          "feature": {
            "name": "g_ph_gol_o2_rec",
            "label": {
              "default": "Preheater O2 Target",
              "id": "page.today.options.recommendationDataFeatureConfigs.0.feature.label"
            }
          },
          "target": {
            "name": "g_ph_gol_o2_es_target",
            "label": {
              "default": "Target Preheater O2",
              "id": "page.today.options.recommendationDataFeatureConfigs.0.target.label"
            }
          }
        }
      ],
      "shiftLength": 24,
      "kpiFeatureConfigs": [
        {
          "name": "p_shc",
          "baseline": 800,
          "yMax": 1000
        },
        {
          "name": "s_c_sol_fcao",
          "upperBound": 2.5,
          "lowerBound": 1
        },
        {
          "name": "s_c_sol_fcao_soft",
          "defaultTargetValue": 1.5,
          "lowerBound": -0.5,
          "upperBound": 1,
          "target": "s_c_sol_fcao_target_rec"
        }
      ],
      "plantName": "Adrianópolis"
    },
    "pages": [
      {
        "options": {
          "alarmConfigs": [
            {
              "maxBound": 7,
              "lowBound": 2.5,
              "highBound": 5,
              "feature": {
                "name": "g_k_gol_o2",
                "label": {
                  "default": "Kiln Inlet O2",
                  "id": "page.today.options.alarmConfigs.0.feature.label"
                }
              },
              "minBound": 0,
              "inRangeColour": "#8AAD66",
              "aboveMidColour": "#F52600",
              "belowMidColour": "#8AAD66",
              "belowLowColour": "#F52600",
              "type": "MAX_HIGH_LOW_MIN",
              "aboveHighColour": "#F52600",
              "midBound": 0
            },
            {
              "maxBound": 1300,
              "lowBound": 800,
              "feature": {
                "name": "g_k_gol_nox",
                "label": {
                  "default": "Kiln Inlet NOx",
                  "id": "page.today.options.alarmConfigs.1.feature.label"
                }
              },
              "highBound": 1100,
              "inRangeColour": "#8AAD66",
              "minBound": 500,
              "aboveMidColour": "#F52600",
              "belowMidColour": "#8AAD66",
              "belowLowColour": "#F52600",
              "type": "MAX_HIGH_LOW_MIN",
              "aboveHighColour": "#F52600",
              "midBound": 0
            },
            {
              "maxBound": 1000,
              "lowBound": 850,
              "feature": {
                "name": "g_ph_cy5_gol_temp",
                "label": {
                  "default": "Cyclone 5 Top Temperature",
                  "id": "page.today.options.alarmConfigs.2.feature.label"
                }
              },
              "highBound": 910,
              "inRangeColour": "#8AAD66",
              "minBound": 800,
              "aboveMidColour": "#F52600",
              "belowMidColour": "#8AAD66",
              "belowLowColour": "#F52600",
              "type": "MAX_HIGH_LOW_MIN",
              "aboveHighColour": "#F52600",
              "midBound": 0
            },
            {
              "maxBound": 4,
              "lowBound": 1,
              "feature": {
                "name": "s_c_sol_fcao",
                "label": {
                  "default": "Free Lime Clinker",
                  "id": "page.today.options.alarmConfigs.3.feature.label"
                }
              },
              "highBound": 2.5,
              "inRangeColour": "#8AAD66",
              "minBound": 0,
              "aboveMidColour": "#F52600",
              "belowMidColour": "#8AAD66",
              "belowLowColour": "#F58302",
              "type": "MAX_HIGH_LOW_MIN",
              "aboveHighColour": "#F52600",
              "midBound": 0
            }
          ],
          "maxAlarmConfigsCount": 6,
          "newRecommendationFlagTime": 7,
          "kpiChartConfigs": [
            {
              "feature": {
                "name": "p_shc",
                "label": {
                  "default": "Specific Heat Consumption",
                  "id": "page.today.options.kpiChartConfigs.0.feature.label"
                }
              },
              "rangeEnd": 1200,
              "rangeStart": 0
            }
          ],
          "predictionDataFeatureConfigs": [],
          "qualityChartConfigs": [
            {
              "modelled": {
                "name": "s_c_sol_fcao_soft",
                "label": {
                  "default": "does it matter?",
                  "id": "page.today.options.qualityChartConfigs.0.modelled.label"
                }
              },
              "measured": {
                "name": "s_c_sol_fcao",
                "label": {
                  "default": "does it matter?",
                  "id": "page.today.options.qualityChartConfigs.0.measured.label"
                }
              },
              "label": {
                "default": "Clinker Free Lime",
                "id": "page.today.options.qualityChartConfigs.0.label"
              },
              "rangeStart": 0,
              "to": "/historical-free-lime",
              "rangeEnd": 4
            }
          ],
          "gasAnalyserChartConfigs": [
            {
              "leftFeatureConfig": {
                "feature": {
                  "name": "g_k_gol_o2",
                  "label": {
                    "default": "Calibrated O2",
                    "id": "page.today.options.gasAnalyserChartConfigs.0.leftFeatureConfig.feature.label"
                  }
                },
                "rangeEnd": 12,
                "rangeStart": 0
              },
              "rightFeatureConfig": {
                "feature": {
                  "name": "g_k_gol_co",
                  "label": {
                    "default": "Calibrated CO",
                    "id": "page.today.options.gasAnalyserChartConfigs.0.rightFeatureConfig.feature.label"
                  }
                },
                "rangeEnd": 6000,
                "rangeStart": 0
              },
              "to": "/kiln-inlet",
              "performanceFeatureConfig": {
                "highLabel": {
                  "default": "High O2",
                  "id": "page.today.options.gasAnalyserChartConfigs.0.performanceFeatureConfig.highLabel"
                },
                "name": "g_k_gol_combustion_state_soft",
                "lowLabel": {
                  "default": "Low O2",
                  "id": "page.today.options.gasAnalyserChartConfigs.0.performanceFeatureConfig.lowLabel"
                }
              },
              "title": {
                "default": "Kiln Inlet",
                "id": "page.today.options.gasAnalyserChartConfigs.0.title"
              }
            }
          ],
          "recommendationLifespan": 18
        },
        "template": "TodayPage",
        "path": "/",
        "featureFlag": "",
        "label": {
          "default": "Today",
          "id": "page.today.label"
        },
        "type": "page"
      },
      {
        "template": "category",
        "label": {
          "default": "KPIs",
          "id": "category.kpis"
        },
        "pages": [
          {
            "options": {
              "chartConfigs": [
                {
                  "yAxisLabel": {
                    "default": "SHC",
                    "id": "page.SHC-KPI.options.chartConfigs.p_shc.yAxisLabel"
                  },
                  "rangeStart": 600,
                  "featureName": "p_shc",
                  "xAxisLabel": {
                    "default": "Year - Week",
                    "id": "page.SHC-KPI.options.chartConfigs.p_shc.xAxisLabel"
                  },
                  "numberOfTicks": 5,
                  "title": {
                    "default": "Weekly SHC KPI",
                    "id": "page.SHC-KPI.options.chartConfigs.p_shc.title"
                  },
                  "rangeEnd": 900
                }
              ]
            },
            "template": "DailyAverageKpiUnrankedPage",
            "path": "/SHC-KPI",
            "featureFlag": "",
            "label": {
              "default": "SHC KPI",
              "id": "page.SHC-KPI.label"
            },
            "type": "page"
          },
          {
            "options": {
              "chartConfig": {
                "xAxisLabel": {
                  "default": "Year - Week",
                  "id": "page.free-lime-kpi.options.chartConfig.xAxisLabel"
                },
                "yAxisLabel": {
                  "default": "Free Lime",
                  "id": "page.free-lime-kpi.options.chartConfig.yAxisLabel"
                },
                "rangeStart": 0,
                "featureName": "s_c_sol_fcao",
                "rangeEnd": 4
              }
            },
            "template": "FreeLimeTemplatePage",
            "path": "/free-lime-kpi",
            "featureFlag": "",
            "label": {
              "default": "Weekly Free Lime KPI",
              "id": "page.free-lime-kpi.label"
            },
            "type": "page"
          },
          {
            "options": {
              "toggleOptions": [
                {
                  "buttonText": {
                    "default": "Incident Count",
                    "id": "page.weekly-ph-O2-performance.options.toggleOptions.0.buttonText"
                  },
                  "rightAxisTitle": {
                    "default": "Incident Count",
                    "id": "page.weekly-ph-O2-performance.options.toggleOptions.0.rightAxisTitle"
                  }
                },
                {
                  "buttonText": {
                    "default": "Incident Average Length",
                    "id": "page.weekly-ph-O2-performance.options.toggleOptions.1.buttonText"
                  },
                  "rightAxisTitle": {
                    "default": "Incident Average Length (min)",
                    "id": "page.weekly-ph-O2-performance.options.toggleOptions.1.rightAxisTitle"
                  }
                }
              ],
              "featureTitle": "O2",
              "chartTitle": {
                "default": "Weekly Preheater O2 Performance",
                "id": "page.weekly-ph-O2-performance.options.chartTitle"
              },
              "featureName": "g_ph_gol_combustion_state_soft",
              "leftAxisTitle": {
                "default": "Time (%)",
                "id": "page.weekly-ph-O2-performance.options.leftAxisTitle"
              }
            },
            "template": "HistoricalWeeklyPerformancePage",
            "path": "/weekly-ph-O2-performance",
            "label": {
              "default": "Weekly Preheater O2 Performance KPI",
              "id": "page.weekly-ph-O2-performance.label"
            },
            "type": "page"
          }
        ],
        "type": "category"
      },
      {
        "template": "category",
        "label": {
          "default": "Combustion Analyser",
          "id": "category.combustion-analyser"
        },
        "pages": [
          {
            "options": {
              "performanceFeature": {
                "title": {
                  "default": "O2"
                },
                "featureName": "g_k_gol_combustion_state_soft"
              },
              "rightRangeStart": 0,
              "leftFeature": {
                "title": {
                  "default": "Calibrated O2",
                  "id": "page.kiln-inlet.options.leftFeature.title"
                },
                "featureName": "g_k_gol_o2"
              },
              "rightRangeEnd": 6000,
              "chartTitle": {
                "default": "Kiln Inlet",
                "id": "page.kiln-inlet.options.chartTitle"
              },
              "rightFeature": {
                "title": {
                  "default": "Calibrated CO",
                  "id": "page.kiln-inlet.options.rightFeature.title"
                },
                "featureName": "g_k_gol_co"
              }
            },
            "template": "HistoricalGasAnalyserPage",
            "path": "/kiln-inlet",
            "label": {
              "default": "Kiln inlet",
              "id": "page.kiln-inlet.label"
            },
            "type": "page"
          }
        ],
        "type": "category"
      },
      {
        "template": "category",
        "label": {
          "default": "Soft Sensors",
          "id": "category.soft-sensors"
        },
        "pages": [
          {
            "options": {
              "chartConfigs": [
                {
                  "xAxisLabel": {
                    "default": "Date",
                    "id": "page.historical-free-lime.options.chartConfigs.s_c_sol_fcao_soft.xAxisLabel"
                  },
                  "yAxisLabel": {
                    "default": "Free Lime",
                    "id": "page.historical-free-lime.options.chartConfigs.s_c_sol_fcao_soft.yAxisLabel"
                  },
                  "rangeStart": 0,
                  "title": {
                    "default": "Modelled",
                    "id": "page.historical-free-lime.options.chartConfigs.s_c_sol_fcao_soft.title"
                  },
                  "featureName": "s_c_sol_fcao_soft",
                  "rangeEnd": 4
                },
                {
                  "xAxisLabel": {
                    "default": "Date",
                    "id": "page.historical-free-lime.options.chartConfigs.s_c_sol_fcao.xAxisLabel"
                  },
                  "yAxisLabel": {
                    "default": "Free Lime",
                    "id": "page.historical-free-lime.options.chartConfigs.s_c_sol_fcao.yAxisLabel"
                  },
                  "rangeStart": 0,
                  "title": {
                    "default": "Measured",
                    "id": "page.historical-free-lime.options.chartConfigs.s_c_sol_fcao.title"
                  },
                  "featureName": "s_c_sol_fcao",
                  "rangeEnd": 4
                }
              ]
            },
            "template": "HistoricalQualityGatewayPage",
            "path": "/historical-free-lime",
            "featureFlag": "",
            "label": {
              "default": "Free Lime",
              "id": "page.historical-free-lime.label"
            },
            "type": "page"
          }
        ],
        "type": "category"
      }
    ]
  },
  "version": {
    "number": 29,
    "alias": "__main",
    "user": "rory@carbonre.com",
    "timestamp": "2024-09-25T10:28:14.343869+00:00"
  }
}
