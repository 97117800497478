import { TooltipProps } from 'recharts';
import { ChartTooltip } from '../../../lib';
import { getDateAsWeek, round } from '../../../utils';
import LegendKey from './ToggleLegend/LegendKey';
import FeatureDescription from '../FeatureDescription';
import { Week } from '../../../messages';

type Props = TooltipProps<string, string> & { title: string };

type WeekProps = {
  children: string;
};
const WeekMessage = ({ children }: WeekProps) => (
  <>
    <Week /> {children}
  </>
);

const getFeatureValue = (dataKey: LegendKey, value: number): string => {
  if ([LegendKey.AverageHighPeriodDuration, LegendKey.AverageLowPeriodDuration].includes(dataKey)) {
    return `${round(value / 60, 0)} min`;
  }
  if (
    [
      LegendKey.OkPercentageTime,
      LegendKey.HighPercentageTime,
      LegendKey.LowPercentageTime,
      LegendKey.NoDataTime
    ].includes(dataKey)
  ) {
    return `${round(value, 2)} %`;
  }
  return `${value}`;
};

const TooltipContent = ({ payload = [], title }: Props) => {
  const data = payload?.[0]?.payload;
  const date = data?.date ? getDateAsWeek(new Date(data.date)) : '';

  const itemListData = payload.map((item) => {
    const featureValue = getFeatureValue(item.dataKey as LegendKey, Number(item.value));
    return {
      label: (
        <>
          <FeatureDescription legendKey={item.dataKey as LegendKey} featureTitle={title} />: {featureValue}
        </>
      ),
      // @ts-ignore
      color: item.stroke ?? item.fill
    };
  });
  return <ChartTooltip label={<WeekMessage>{date}</WeekMessage>} itemListData={itemListData} />;
};
export default TooltipContent;
