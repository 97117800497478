import classNames from 'classnames';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { useSearchParams } from 'react-router-dom';
import { Language } from '../../Config/sharedSchemas';
import { EzerTheme } from '../../EzerThemeProvider';
import { useLanguages } from '../../utils';
import MenuButton from '../Menu/MenuButton';
import ToggleButton from '../Menu/ToggleButton';

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  toggleButton: {
    backgroundColor: palette.white,
    color: palette.carbon,
    minWidth: spacing(20)
  },
  menu: {
    maxHeight: 0,
    overflow: 'hidden',
    transition: 'max-height 0.2s linear'
  },
  menuOpen: {
    maxHeight: 200
  },
  subMenuButton: {
    paddingLeft: spacing(3)
  }
}));

const getFlag = (locale: Language) => {
  switch (locale) {
    case 'en':
      return '🇬🇧🇺🇸';
    case 'es':
      return '🇪🇸';
    case 'pt-br':
      return '🇧🇷';
    case 'pt-pt':
      return '🇵🇹';
    default:
      return '';
  }
};

const getLanguageName = (locale: Language) => {
  // TODO: Add cs and tr once we support them.
  switch (locale) {
    case 'en':
      return 'EN';
    case 'es':
      return 'ES';
    case 'pt-br':
      return 'PT BR';
    case 'pt-pt':
      return 'PT PT';
    default:
      return '';
  }
};

const LanguageMessage = () => <FormattedMessage defaultMessage="Language" id="labels.language" />;

const LanguageMenu = () => {
  const styles = useStyles();
  const languages = useLanguages();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showMenu, setShowMenu] = useState(false);
  const setLanguage = (language: string) => {
    setShowMenu(false);
    searchParams.set('locale', language);
    setSearchParams(searchParams);
  };
  const { locale } = useIntl();
  return (
    <>
      <ToggleButton isOpen={showMenu} onClick={() => setShowMenu(!showMenu)} className={styles.toggleButton}>
        <LanguageMessage /> {getFlag(locale as Language)}
      </ToggleButton>
      <ul className={classNames(styles.menu, { [styles.menuOpen]: showMenu })}>
        {languages.map((language) => (
          <li key={language}>
            <MenuButton onClick={() => setLanguage(language)} className={styles.subMenuButton}>
              {getLanguageName(language)} {getFlag(language)}
            </MenuButton>
          </li>
        ))}
      </ul>
    </>
  );
};

export default LanguageMenu;
