import { FormattedMessage } from 'react-intl';
import React from 'react';

type Props = {
  featureTitle: string;
};

export const DESCRIPTOR = {
  defaultMessage: 'Time in Low {featureTitle} state',
  id: 'page.HistoricalPerformance.timeInLow'
};

const TimeInLow = ({ featureTitle }: Props) => (
  <FormattedMessage defaultMessage={DESCRIPTOR.defaultMessage} id={DESCRIPTOR.id} values={{ featureTitle }} />
);

export default TimeInLow;
