import { useIntl } from 'react-intl';

type Props = {
  label: {
    id?: string;
    default: string;
  };
};

const ConfigLabel = ({ label }: Props) => {
  const { formatMessage } = useIntl();
  if (label.id) {
    return <>{formatMessage({ id: label.id, defaultMessage: label.default })}</>;
  }
  return <>{label.default}</>;
};

export default ConfigLabel;
