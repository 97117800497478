import { createUseStyles } from 'react-jss';
import { Legend, Line, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import type { Payload as LegendPayload } from 'recharts/types/component/DefaultLegendContent';
import { EzerTheme, useEzerTheme } from '../../EzerThemeProvider';
import { Label } from '../../types';
import QualityChartData, { Payload } from '../../types/QualityChartData';
import { getLocalDate, useQualityChartYTicks, useReferenceAreaData, useTicksByDate, useTimezone } from '../../utils';
import CartesianGrid from '../CartesianGrid';
import LabelledContainer from '../LabelledContainer';
import MeasuredValueDot from '../MeasuredValueDot';
import QualityChartLabel from './QualityChartLabel';
import QualityTooltipContent from '../QualityTooltipContent/QualityTooltipContent';

type ChartValues = keyof Payload;

type Props = {
  data: QualityChartData;
  startDate: Date;
  endDate: Date;
  xAxisLabel: Label;
  yAxisLabel: Label;
  unit: string;
  rangeStart?: number;
  rangeEnd?: number;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    marginTop: spacing(2)
  },
  referenceArea: {
    opacity: 0.5
  },
  container: {
    position: 'relative'
  }
}));
const HistoricalQualityChart = ({
  data,
  startDate,
  endDate,
  xAxisLabel,
  yAxisLabel,
  unit,
  rangeStart,
  rangeEnd
}: Props) => {
  const styles = useStyles();
  const { palette, spacing } = useEzerTheme();
  const xTicks = useTicksByDate(startDate, endDate);
  const yTicks = useQualityChartYTicks(
    data,
    rangeStart === null ? undefined : rangeStart,
    rangeEnd === null ? undefined : rangeEnd
  );
  const showDecimalPoints = rangeEnd ? rangeEnd <= 1000 : true;
  const timezone = useTimezone();
  const referenceAreaData = useReferenceAreaData(data);
  const legendFormatter = (value: ChartValues) => (
    <>
      <QualityChartLabel>{value}</QualityChartLabel> ({unit})
    </>
  );
  const legendPayload: LegendPayload[] = [
    { value: 'measured', type: 'circle', color: palette.white },
    { value: 'predicted', type: 'line', color: palette.leaf }
  ];
  return (
    <LabelledContainer yAxisLabel={yAxisLabel} xAxisLabel={xAxisLabel} unit={unit}>
      <ResponsiveContainer width="100%" height={spacing(58)} className={styles.root}>
        <LineChart margin={{ top: spacing(1), right: 0, bottom: 0, left: 0 }} data={data}>
          <Legend verticalAlign="top" height={36} formatter={legendFormatter} payload={legendPayload} />
          <CartesianGrid />
          <Line
            type="basis"
            dataKey="upperBound"
            stroke={palette.white}
            strokeWidth={1}
            isAnimationActive={false}
            dot={false}
            activeDot={false}
            strokeDasharray="4"
          />
          <Line
            type="basis"
            dataKey="lowerBound"
            stroke={palette.white}
            strokeWidth={1}
            isAnimationActive={false}
            dot={false}
            activeDot={false}
            strokeDasharray="4"
          />
          <Line
            type="monotone"
            dataKey="predicted"
            stroke={palette.leaf}
            strokeWidth={2}
            isAnimationActive={false}
            dot={false}
          />
          {referenceAreaData.map(({ start, end }) => (
            <ReferenceArea key={start} x1={start} x2={end} fill={palette.flame} className={styles.referenceArea} />
          ))}
          <Line
            dot={MeasuredValueDot}
            activeDot={false}
            dataKey="measured"
            stroke={palette.white}
            style={{ stroke: 'transparent' }}
            isAnimationActive={false}
          />
          <XAxis
            dataKey="date"
            type="number"
            domain={[startDate.getTime(), endDate.getTime()]}
            ticks={xTicks}
            tickFormatter={(tick: string) => getLocalDate(new Date(tick), timezone)}
            allowDataOverflow
            height={spacing(4)}
          />
          <YAxis
            width={spacing(6)}
            dataKey="measured"
            ticks={yTicks}
            domain={[yTicks[0], yTicks[yTicks.length - 1]]}
            allowDataOverflow
            tickFormatter={(tick: number) => tick.toFixed(showDecimalPoints ? 1 : 0)}
          />
          {data.length > 0 && <Tooltip content={<QualityTooltipContent unit={unit} />} />}
        </LineChart>
      </ResponsiveContainer>
    </LabelledContainer>
  );
};

export default HistoricalQualityChart;
