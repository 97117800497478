import { WeeklyPerformanceData } from '../utils/useWeeklyPerformanceData';
import { getDateAsWeek, round } from '../../../utils';

type RowKeys =
  | 'id'
  | 'week'
  | 'percentage_time_good'
  | 'percentage_time_high'
  | 'percentage_time_low'
  | 'high_period_count'
  | 'low_period_count'
  | 'average_duration_high'
  | 'average_duration_low';
type RowValues = string | number;
type Row = Record<RowKeys, RowValues>;

const useRows = ({ periods = [] }: WeeklyPerformanceData): Row[] =>
  periods
    .map((period) => {
      const {
        date,
        highPercentageTime,
        lowPercentageTime,
        okPercentageTime,
        highPeriodCount,
        lowPeriodCount,
        averageHighPeriodDuration,
        averageLowPeriodDuration
      } = period;
      return {
        id: date,
        week: getDateAsWeek(new Date(date)),
        percentage_time_good: `${round(okPercentageTime)}`,
        percentage_time_high: `${round(highPercentageTime)}`,
        percentage_time_low: `${round(lowPercentageTime)}`,
        high_period_count: `${highPeriodCount}`,
        low_period_count: `${lowPeriodCount}`,
        average_duration_high: `${round(averageHighPeriodDuration / 60, 0)}`,
        average_duration_low: `${round(averageLowPeriodDuration / 60, 0)}`
      };
    })
    .reverse();

export default useRows;
