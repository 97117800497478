/* eslint-disable camelcase */
import { rest } from 'msw';
import { API_SERVE_BASE_URL, API_INFERENCE_BASE_URL } from '../settings';
import {
  config,
  user,
  plantStatus,
  dataStatus,
  recommendation,
  plantData_s_c_sol_fcao,
  plantData_g_k_gol_o2_Cg_k_gol_co,
  plantDataLatest_g_k_gol_nox,
  plantDataLatest_g_k_gol_o2,
  plantDataLatest_g_ph_cy5_gol_temp,
  plantDataLatest_s_c_sol_fcao,
  softSensor_s_c_sol_fcao_soft,
  softSensor_g_k_gol_combustion_state_soft,
  metadata_s_c_sol_fcao,
  metadata_p_shc,
  metadata_g_k_gol_o2,
  metadata_g_k_gol_nox,
  metadata_g_ph_cy5_gol_temp,
  metadata_g_k_gol_co,
  kpi_s_c_sol_fcao,
  kpi_p_shc,
  weeklyPerformance_g_ph_gol_combustion_state_soft
} from './mockApi';

const handlers = [
  rest.get(`${API_SERVE_BASE_URL}/config/`, (req, res, ctx) => res(ctx.json(config))),
  rest.get(`${API_SERVE_BASE_URL}/user/`, (req, res, ctx) => res(ctx.json(user))),
  rest.get(`${API_SERVE_BASE_URL}/plant-status/`, (req, res, ctx) => res(ctx.json(plantStatus))),
  rest.get(`${API_SERVE_BASE_URL}/data-status/`, (req, res, ctx) => res(ctx.json(dataStatus))),
  rest.get(`${API_INFERENCE_BASE_URL}/recommendation/`, (req, res, ctx) => res(ctx.json(recommendation))),
  rest.get(`${API_SERVE_BASE_URL}/plant-data/`, (req, res, ctx) => {
    const url = new URL(req.url);
    const feature = url.searchParams.get('features');
    if (feature === 's_c_sol_fcao') {
      return res(ctx.json(plantData_s_c_sol_fcao));
    }
    if (feature === 'g_k_gol_o2,g_k_gol_co') {
      return res(ctx.json(plantData_g_k_gol_o2_Cg_k_gol_co));
    }
    // any features not defined will return a 500 error response
    return res(
      ctx.status(500),
      ctx.json({
        errorMessage: 'Internal service error'
      })
    );
  }),
  rest.get(`${API_SERVE_BASE_URL}/plant-data/latest`, (req, res, ctx) => {
    const url = new URL(req.url);
    const feature = url.searchParams.get('features');
    if (feature === 'g_k_gol_o2') {
      return res(ctx.json(plantDataLatest_g_k_gol_o2));
    }
    if (feature === 'g_k_gol_nox') {
      return res(ctx.json(plantDataLatest_g_k_gol_nox));
    }
    if (feature === 'g_ph_cy5_gol_temp') {
      return res(ctx.json(plantDataLatest_g_ph_cy5_gol_temp));
    }
    if (feature === 's_c_sol_fcao') {
      return res(ctx.json(plantDataLatest_s_c_sol_fcao));
    }
    // any features not defined will return a 500 error response
    return res(
      ctx.status(500),
      ctx.json({
        errorMessage: 'Internal service error'
      })
    );
  }),
  rest.get(`${API_INFERENCE_BASE_URL}/soft-sensor/`, (req, res, ctx) => {
    const url = new URL(req.url);
    const feature = url.searchParams.get('features');
    if (feature === 's_c_sol_fcao_soft') {
      return res(ctx.json(softSensor_s_c_sol_fcao_soft));
    }
    if (feature === 'g_k_gol_combustion_state_soft') {
      return res(ctx.json(softSensor_g_k_gol_combustion_state_soft));
    }
    // any features not defined will return a 500 error response
    return res(
      ctx.status(500),
      ctx.json({
        errorMessage: 'Internal service error'
      })
    );
  }),
  rest.get(`${API_SERVE_BASE_URL}/metadata/`, (req, res, ctx) => {
    const url = new URL(req.url);
    const feature = url.searchParams.get('features');

    if (feature === 's_c_sol_fcao') {
      return res(ctx.json(metadata_s_c_sol_fcao));
    }
    if (feature === 'p_shc') {
      return res(ctx.json(metadata_p_shc));
    }
    if (feature === 'g_k_gol_o2') {
      return res(ctx.json(metadata_g_k_gol_o2));
    }
    if (feature === 'g_k_gol_nox') {
      return res(ctx.json(metadata_g_k_gol_nox));
    }
    if (feature === 'g_ph_cy5_gol_temp') {
      return res(ctx.json(metadata_g_ph_cy5_gol_temp));
    }
    if (feature === 'g_k_gol_co') {
      return res(ctx.json(metadata_g_k_gol_co));
    }
    // any features not defined will return a 500 error response
    return res(
      ctx.status(500),
      ctx.json({
        errorMessage: 'Internal service error'
      })
    );
  }),
  rest.get(`${API_SERVE_BASE_URL}/kpi/`, (req, res, ctx) => {
    const url = new URL(req.url);
    const feature = url.searchParams.get('feature');
    if (feature === 'p_shc') {
      return res(ctx.json(kpi_p_shc));
    }
    if (feature === 's_c_sol_fcao') {
      return res(ctx.json(kpi_s_c_sol_fcao));
    }
    // any features not defined will return a 500 error response
    return res(
      ctx.status(500),
      ctx.json({
        errorMessage: 'Internal service error'
      })
    );
  }),
  rest.get(`${API_SERVE_BASE_URL}/weekly-performance/`, (req, res, ctx) => {
    const url = new URL(req.url);
    const feature = url.searchParams.get('feature');
    if (feature === 'g_ph_gol_combustion_state_soft') {
      return res(ctx.json(weeklyPerformance_g_ph_gol_combustion_state_soft));
    }

    // any features not defined will return a 500 error response
    return res(
      ctx.status(500),
      ctx.json({
        errorMessage: 'Internal service error'
      })
    );
  })
];
export default handlers;
