import useRecommendationsQuery from '../serviceQueries/useRecommendationsQuery';
import { FeatureName, Recommendation } from '../types';

type Response = {
  data: Recommendation[];
  isLoading: boolean;
  isError: boolean;
};

// eslint-disable-next-line
const buildRecommendation = (datum: any): Recommendation => ({
  timestamp: new Date(datum.timestamp),
  feature: datum.feature as FeatureName,
  value: datum.value === null ? 0 : datum.value,
  error: datum.error ?? null
});

const useHistoricalRecommendationsData = (
  featureNames: FeatureName[],
  startDate: Date,
  endDate: Date,
  refetch = false
): Response => {
  const {
    data = [],
    isLoading,
    isError
  } = useRecommendationsQuery(featureNames, startDate, endDate, {
    useInferenceApi: true,
    refetch
  });

  const recommendations = data
    // eslint-disable-next-line
    .map((datum: any) => buildRecommendation(datum))
    .filter(
      (recommendation: Recommendation) => recommendation.error === null && featureNames.includes(recommendation.feature)
    );

  return {
    data: recommendations,
    isLoading,
    isError
  };
};

export default useHistoricalRecommendationsData;
