import { Api } from '../settings';
import { FeatureName } from '../types';
import getCacheKeyDate from '../utils/getCacheKeyDate';
import getUtcDate from '../utils/getUtcDate';
import useAuthorisedQuery from './useAuthorisedQuery';

type Options = {
  refetch?: boolean;
  useInferenceApi?: boolean;
};

const ONE_MINUTE = 60000;
export const buildQueryKey = (startDate: Date, endDate: Date, refetchMode = false) => {
  const result = ['recommendations'];
  if (!refetchMode) {
    result.push(getCacheKeyDate(startDate));
    result.push(getCacheKeyDate(endDate));
  }
  return result;
};

const useRecommendationsQuery = (features: FeatureName[], startDate: Date, endDate: Date, options?: Options) => {
  const refetchMode = !!options?.refetch;
  const refetchInterval = refetchMode ? ONE_MINUTE : false;
  const startDateValue = getUtcDate(startDate);
  const endDateValue = getUtcDate(endDate);
  const useInferenceApi = !!options?.useInferenceApi;
  const api = useInferenceApi ? Api.inference : Api.serve;
  return useAuthorisedQuery(
    buildQueryKey(startDate, endDate, refetchMode),
    `recommendation/?features=${features.join()}&start_datetime=${startDateValue}&end_datetime=${endDateValue}`,
    {
      refetchInterval
    },
    api
  );
};

export default useRecommendationsQuery;
