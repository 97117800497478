[
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:28:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:29:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:30:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:31:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:32:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:33:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:34:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:35:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:36:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:37:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:38:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:39:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:40:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:41:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:42:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:50:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:51:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:52:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:53:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:54:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:55:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:56:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:57:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:58:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T17:59:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:00:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:01:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:10:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:11:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:12:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:13:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:14:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:15:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:28:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:29:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:30:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:31:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:32:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:33:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:34:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:35:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:36:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:37:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:38:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:39:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:40:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:41:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:42:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:43:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:44:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:45:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:46:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:47:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:48:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:49:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:50:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:51:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:52:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:53:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:54:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:55:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:56:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:57:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:58:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T18:59:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:00:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:01:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:02:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:03:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:04:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:05:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:06:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:07:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:08:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:09:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:10:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:11:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:12:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:13:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:14:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:15:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:16:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:17:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:18:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:19:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:20:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:21:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:22:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:23:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:24:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:25:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:26:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:27:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:28:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:29:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:30:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:31:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:32:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:33:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:34:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:35:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:36:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:37:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:38:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:39:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:40:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:41:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:42:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:43:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:44:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:45:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:46:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:47:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:48:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:49:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:50:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:51:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:52:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:53:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:54:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:55:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:56:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:57:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:58:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T19:59:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:00:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:01:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:02:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:03:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:04:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:05:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:06:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:07:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:08:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:09:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:10:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:11:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:12:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:13:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:14:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:15:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:16:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:17:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:18:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:19:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:20:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:21:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:22:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:23:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:24:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:25:00+00:00",
    "value": -1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:26:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:27:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:28:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:29:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:30:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:31:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:32:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:33:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:34:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:35:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:36:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:37:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:38:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:39:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:40:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:41:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:42:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:43:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:44:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:45:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:46:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:47:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:48:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:49:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:50:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:51:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:52:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:53:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:54:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:55:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:56:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:57:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:58:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T20:59:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:00:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:01:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:02:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:03:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:04:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:05:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:06:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:07:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:08:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:09:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:10:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:11:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:12:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:13:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:14:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:15:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:16:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:17:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:18:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:19:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:20:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:21:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:22:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:23:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:24:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:25:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:26:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:27:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:28:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:29:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:30:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:31:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:32:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:33:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:34:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:35:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:36:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:37:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:38:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:39:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:40:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:41:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:42:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:43:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:44:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:45:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:46:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:47:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:48:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:49:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:50:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:51:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:52:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:53:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:54:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:55:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:56:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:57:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:58:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T21:59:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:00:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:01:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:02:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:03:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:04:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:05:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:06:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:07:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:08:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:09:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:10:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:11:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:12:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:13:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:14:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:15:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:16:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:17:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:18:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:19:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:20:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:21:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:22:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:23:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:24:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:25:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:26:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:27:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:28:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:29:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:30:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:31:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:32:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:33:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:34:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:35:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:36:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:37:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:38:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:39:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:40:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:41:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:42:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:43:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:44:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:45:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:46:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:47:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:48:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:49:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:50:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:51:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:52:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:53:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:54:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:55:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:56:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:57:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:58:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T22:59:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:00:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:01:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:02:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:03:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:04:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:05:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:06:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:07:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:08:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:09:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:10:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:11:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:12:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:13:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:14:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:15:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:16:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:17:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:18:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:19:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:20:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:21:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:22:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:23:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:24:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:25:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:26:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:27:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:28:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:29:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:30:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:31:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:32:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:33:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:34:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:35:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:36:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:37:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:38:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:39:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:40:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:41:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:42:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:50:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:51:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:52:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:53:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:54:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:55:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:56:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:57:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:58:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-25T23:59:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:00:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:01:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:10:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:11:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:12:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:13:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:14:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:15:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:28:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:29:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:30:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:31:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:32:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:33:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:34:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:35:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:36:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:37:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:38:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:39:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:40:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:41:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:42:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:50:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:51:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:52:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:53:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:54:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:55:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:56:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:57:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:58:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T00:59:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:00:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:01:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:10:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:11:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:12:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:13:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:14:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:15:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:28:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:29:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:30:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:31:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:32:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:33:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:34:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:35:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:36:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:37:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:38:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:39:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:40:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:41:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:42:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:50:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:51:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:52:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:53:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:54:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:55:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:56:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:57:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:58:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T01:59:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:00:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:01:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:10:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:11:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:12:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:13:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:14:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:15:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:28:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:29:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:30:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:31:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:32:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:33:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:34:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:35:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:36:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:37:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:38:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:39:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:40:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:41:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:42:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:50:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:51:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:52:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:53:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:54:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:55:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:56:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:57:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:58:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T02:59:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:00:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:01:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:10:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:11:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:12:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:13:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:14:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:15:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:28:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:29:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:30:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:31:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:32:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:33:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:34:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:35:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:36:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:37:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:38:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:39:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:40:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:41:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:42:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:50:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:51:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:52:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:53:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:54:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:55:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:56:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:57:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:58:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T03:59:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:00:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:01:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:10:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:11:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:12:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:13:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:14:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:15:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:28:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:29:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:30:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:31:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:32:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:33:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:34:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:35:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:36:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:37:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:38:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:39:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:40:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:41:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:42:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:50:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:51:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:52:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:53:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:54:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:55:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:56:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:57:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:58:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T04:59:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:00:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:01:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:10:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:11:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:12:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:13:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:14:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:15:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:28:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:29:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:30:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:31:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:32:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:33:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:34:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:35:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:36:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:37:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:38:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:39:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:40:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:41:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:42:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:50:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:51:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:52:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:53:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:54:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:55:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:56:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:57:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:58:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T05:59:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:00:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:01:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:10:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:11:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:12:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:13:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:14:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:15:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:28:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:29:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:30:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:31:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:32:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:33:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:34:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:35:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:36:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:37:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:38:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:39:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:40:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:41:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:42:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:50:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:51:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:52:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:53:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:54:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:55:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:56:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:57:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:58:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T06:59:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:00:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:01:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:10:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:11:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:12:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:13:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:14:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:15:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:28:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:29:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:30:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:31:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:32:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:33:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:34:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:35:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:36:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:37:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:38:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:39:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:40:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:41:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:42:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:50:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:51:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:52:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:53:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:54:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:55:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:56:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:57:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:58:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T07:59:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:00:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:01:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:10:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:11:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:12:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:13:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:14:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:15:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:28:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:29:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:30:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:31:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:32:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:33:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:34:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:35:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:36:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:37:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:38:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:39:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:40:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:41:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:42:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:50:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:51:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:52:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:53:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:54:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:55:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:56:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:57:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:58:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T08:59:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:00:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:01:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:10:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:11:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:12:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:13:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:14:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:15:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:28:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:29:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:30:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:31:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:32:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:33:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:34:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:35:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:36:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:37:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:38:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:39:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:40:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:41:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:42:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:50:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:51:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:52:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:53:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:54:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:55:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:56:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:57:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:58:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T09:59:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:00:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:01:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:10:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:11:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:12:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:13:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:14:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:15:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:28:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:29:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:30:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:31:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:32:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:33:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:34:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:35:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:36:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:37:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:38:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:39:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:40:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:41:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:42:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:50:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:51:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:52:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:53:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:54:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:55:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:56:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:57:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:58:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T10:59:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:00:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:01:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:10:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:11:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:12:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:13:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:14:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:15:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:28:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:29:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:30:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:31:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:32:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:33:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:34:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:35:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:36:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:37:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:38:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:39:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:40:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:41:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:42:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:50:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:51:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:52:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:53:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:54:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:55:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:56:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:57:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:58:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T11:59:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:00:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:01:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:10:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:11:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:12:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:13:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:14:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:15:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:16:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:17:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:18:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:19:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:20:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:21:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:22:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:23:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:24:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:25:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:26:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:27:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:28:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:29:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:30:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:31:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:32:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:33:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:34:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:35:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:36:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:37:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:38:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:39:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:40:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:41:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:42:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:43:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:44:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:45:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:46:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:47:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:48:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:49:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:50:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:51:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:52:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:53:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:54:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:55:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:56:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:57:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:58:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T12:59:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:00:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:01:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:02:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:03:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:04:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:05:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:06:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:07:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:08:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:09:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:10:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:11:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:12:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:13:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:14:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:15:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:16:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:17:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:18:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:19:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:20:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:21:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:22:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:23:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:24:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:25:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:26:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:27:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:28:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:29:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:30:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:31:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:32:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:33:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:34:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:35:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:36:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:37:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:38:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:39:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:40:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:41:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:42:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:43:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:44:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:45:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:46:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:47:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:48:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:49:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:50:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:51:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:52:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:53:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:54:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:55:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:56:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:57:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:58:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T13:59:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:00:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:01:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:02:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:03:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:04:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:05:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:06:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:07:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:08:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:09:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:10:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:11:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:12:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:13:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:14:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:15:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:16:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:17:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:18:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:19:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:20:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:21:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:22:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:23:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:24:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:25:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:26:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:27:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:28:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:29:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:30:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:31:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:32:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:33:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:34:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:35:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:36:00+00:00",
    "value": 1.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:37:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:38:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:39:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:40:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:41:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:42:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:50:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:51:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:52:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:53:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:54:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:55:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:56:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:57:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:58:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T14:59:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:00:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:01:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:10:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:11:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:12:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:13:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:14:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:15:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:28:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:29:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:30:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:31:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:32:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:33:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:34:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:35:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:36:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:37:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:38:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:39:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:40:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:41:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:42:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:43:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:44:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:45:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:46:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:47:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:48:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:49:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:50:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:51:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:52:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:53:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:54:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:55:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:56:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:57:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:58:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T15:59:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:00:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:01:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:02:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:03:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:04:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:05:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:06:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:07:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:08:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:09:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:10:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:11:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:12:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:13:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:14:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:15:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:16:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:17:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:18:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:19:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:20:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:21:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:22:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:23:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:24:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:25:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:26:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:27:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:28:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:29:00+00:00",
    "value": 0.0,
    "error": null
  },
  {
    "feature": "g_k_gol_combustion_state_soft",
    "timestamp": "2024-09-26T16:30:00+00:00",
    "value": 0.0,
    "error": null
  }
]
