import { Static, Type } from '@sinclair/typebox';
import { LabelSchema } from '../../Config/sharedSchemas';
import { selectableEnum } from '../../Config/utils';
import { FeatureName } from '../../types';
import getTemplateSchema from '../getTemplateSchema';

const axisOptionSchema = Type.Object({
  axisLabel: LabelSchema({ title: 'Axis Label', description: 'Label for the axis' }),
  numberOfTicks: Type.Optional(
    Type.Number({ title: 'Number of Ticks', description: 'Number of ticks to display on the Y axis' })
  ),
  rangeStart: Type.Optional(Type.Number({ title: 'Range Start', description: 'The start of the range on the Y axis' })),
  rangeEnd: Type.Optional(Type.Number({ title: 'Range End', description: 'The end of the range on the Y axis' }))
});

const options = Type.Object(
  {
    chartTitle: LabelSchema({ title: 'Chart Title', description: 'The title of the chart' }),
    featureName: selectableEnum(FeatureName, {
      title: 'Feature Name',
      description: 'The name of the kpi feature'
    }),
    leftAxisTitle: LabelSchema({ title: 'Left Axis Title', description: 'The title of the feature' }),
    bottomAxisTitle: LabelSchema({ title: 'Bottom Axis Title', description: 'Label for the x axis' }),
    rightAxisOptions: Type.Array(axisOptionSchema, {
      title: 'Right Axis Options',
      description: 'Configure the toggleable chart options for the right axis'
    })
  },
  { title: 'Options', description: 'Options for the Free Lime template' }
);

export type Options = Static<typeof options>;

export const templateSlug = 'FreeLimeTemplatePage';
export const templateSchema = Type.Object(getTemplateSchema({ templateSlug, options }), {
  title: 'Free Lime',
  description: 'Free Lime page for new config'
});
export type TemplateSchema = Static<typeof templateSchema>;
export const templateUiSchema = {
  featureFlag: {
    'ui:options': {
      // placeholder to show how to set a field option
      disabled: false
    }
  }
};
