[
  {
    "timestamp": "2024-10-16T02:15:00+00:00",
    "feature": "s_c_sol_fcao_target_rec",
    "value": 2.6002,
    "error": null
  },
  {
    "timestamp": "2024-10-16T03:00:00+00:00",
    "feature": "s_c_sol_fcao_target_rec",
    "value": 1.6002,
    "error": null
  },
  {
    "timestamp": "2024-10-16T07:00  :00+00:00",
    "feature": "s_c_sol_fcao_target_rec",
    "value": 1.7002,
    "error": null
  },
  {
    "timestamp": "2024-10-16T07:45:00+00:00",
    "feature": "s_c_sol_fcao_target_rec",
    "value": 1.6002,
    "error": null
  },
  {
    "timestamp": "2024-10-16T08:00:00+00:00",
    "feature": "s_c_sol_fcao_target_rec",
    "value": 1.6002,
    "error": null
  },
  {
    "timestamp": "2024-10-16T08:30:00+00:00",
    "feature": "s_c_sol_fcao_target_rec",
    "value": 1.4707,
    "error": null
  },
  {
    "timestamp": "2024-10-16T09:00:00+00:00",
    "feature": "s_c_sol_fcao_target_rec",
    "value": 1.3707,
    "error": null
  },
  {
    "timestamp": "2024-10-16T09:30:00+00:00",
    "feature": "s_c_sol_fcao_target_rec",
    "value": 1.6707,
    "error": null
  },
  {
    "timestamp": "2024-10-16T09:45:00+00:00",
    "feature": "s_c_sol_fcao_target_rec",
    "value": 1.6707,
    "error": null
  }
]
