/* eslint-disable @typescript-eslint/no-explicit-any */
import { subHours } from 'date-fns';
import useDataStatusQuery from '../../../../serviceQueries/useDataStatusQuery';
import useNow from '../../../../useNow';

export type DataStatus = {
  timestamp: Date;
  status: 'OK' | 'DEGRADED' | 'ERROR';
};

type Response = {
  data: DataStatus[];
  isLoading: boolean;
  isError: boolean;
};

const useDataStatus = (): Response => {
  const now = useNow();
  const startDate = subHours(now, 24);
  const { data: ingestJobsData = [], isLoading, isError } = useDataStatusQuery(startDate, now);
  const data = ingestJobsData.map((datum: any) => ({
    timestamp: new Date(datum.timestamp),
    status: datum.status as DataStatus['status']
  }));
  return { data, isLoading, isError };
};

export default useDataStatus;
