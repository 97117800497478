import { useMessages } from '../../../LocalisationProvider';
import { getLocalDateAndTime, getLocalisedMessage, useTimezone } from '../../../utils';
import { SoftSensor } from '../../../utils/useHistoricalSoftSensors';
import { FeatureName } from '../../../types';

type TableData = Record<'id', number> & Record<'date', string> & Partial<Record<FeatureName, number | string>>;

const useTableData = (data: SoftSensor[]) => {
  const messages = useMessages();
  const naLabel = getLocalisedMessage(messages, 'N/A', 'labels.na');
  const timezone = useTimezone();

  return data
    .reduce((acc: TableData[], datum: SoftSensor) => {
      const datumId = new Date(datum.timestamp).getTime();
      const existingItem = acc.find((item) => item.id === datumId);

      if (existingItem) {
        existingItem[datum.feature] = datum.value ?? naLabel;
      } else {
        const newDatum: TableData = {
          id: datumId,
          date: getLocalDateAndTime(new Date(datumId), timezone),
          [datum.feature]: datum.value ?? naLabel
        };
        acc.push(newDatum);
      }
      return acc;
    }, [])
    .sort((a, b) => b.id - a.id);
};

export default useTableData;
