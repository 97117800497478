import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { EzerTheme, useEzerTheme } from '../../../../../EzerThemeProvider';
import Chart from './Chart/Chart';
import Signpost from './Signpost';
import AlarmValue from './AlarmValue';
import Bar from './Bar';
import useAlarmData from './utils/useAlarmData';
import { useUnit } from '../../../../../utils';
import { AlarmConfig } from '../../../schema';
import { LocalisedLabel, ProgressIndicator } from '../../../../../lib';

type Props = {
  config: AlarmConfig;
  className: string;
};

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    backgroundColor: palette.rich,
    '&:first-child': {
      borderTopLeftRadius: spacing(0.5)
    },
    '&:last-child': {
      borderTopRightRadius: spacing(0.5),
      borderBottomRightRadius: spacing(0.5)
    },
    paddingBottom: spacing(2),
    position: 'relative'
  },
  title: {
    marginTop: spacing(0.5),
    marginLeft: spacing(0.5),
    marginRight: spacing(0.5),
    fontSize: spacing(1.75),
    lineHeight: `${spacing(2.4)}px`,
    height: spacing(5),
    textAlign: 'center'
  },
  errorMessage: {
    fontSize: spacing(1.75)
  }
}));

const Alarm = ({ config, className }: Props) => {
  const styles = useStyles();
  const { spacing } = useEzerTheme();
  const {
    data: alarmData,
    isLoading: isAlarmDataLoading,
    isError: isAlarmDataError
  } = useAlarmData(config.feature.name);
  const { data: unit, isLoading: isUnitLoading, isError: isUnitError } = useUnit(config.feature.name);
  const isLoading = isAlarmDataLoading || isUnitLoading;
  const isError = isAlarmDataError || isUnitError;

  return (
    <li className={classNames(styles.root, className)}>
      <h3 className={styles.title}>
        <LocalisedLabel>{config.feature.label}</LocalisedLabel>
      </h3>
      <Chart config={config}>{!isLoading && <Bar config={config} data={alarmData} />}</Chart>
      <Signpost config={config} data={alarmData}>
        <ProgressIndicator isLoading={isLoading} size={spacing(1.75)} />
        {isError && (
          <p className={styles.errorMessage}>
            <FormattedMessage id="labels.error" defaultMessage="Error" />
          </p>
        )}
        {!isLoading && !isError && <AlarmValue data={alarmData} unit={unit} />}
      </Signpost>
    </li>
  );
};

export default Alarm;
