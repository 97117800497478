import { createUseStyles } from 'react-jss';
import { LoadingMessage } from '../../../lib';
import { EzerTheme } from '../../../EzerThemeProvider';

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    paddingTop: spacing(3),
    marginLeft: spacing(2),
    marginRight: spacing(2),
    marginBottom: -1
  }
}));

const LoadingContent = () => {
  const styles = useStyles();
  return <LoadingMessage className={styles.root} size="small" />;
};

export default LoadingContent;
