import classNames from 'classnames';
import { isBefore } from 'date-fns';
import { createUseStyles } from 'react-jss';
import { createSearchParams, generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { Line, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useEzerTheme } from '../../../EzerThemeProvider';
import { CartesianGrid, MeasuredValueDot, QualityTooltipContent } from '../../../lib';
import QualityChartData from '../../../types/QualityChartData';
import { getLocalTime, useQualityChartYTicks, useReferenceAreaData, useTimezone } from '../../../utils';
import { QualityChartConfig } from '../schema';
import { useSevenTicks } from '../utils';

type Props = {
  data: QualityChartData;
  startDate: Date;
  endDate: Date;
  config: QualityChartConfig;
  unit: string;
};

const useStyles = createUseStyles({
  referenceArea: {
    opacity: 0.5
  },
  isSelectable: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7
    }
  }
});

const QualityChart = ({ data, startDate, endDate, config, unit }: Props) => {
  const { palette, spacing } = useEzerTheme();
  const referenceAreaData = useReferenceAreaData(data);
  const styles = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const onClick = (areaStartDate: Date, areaEndDate: Date) => {
    if (config.to) {
      if (isBefore(areaStartDate, areaEndDate)) {
        searchParams.set('startDate', areaStartDate.toISOString());
        searchParams.set('endDate', areaEndDate.toISOString());
      } else {
        searchParams.set('startDate', areaEndDate.toISOString());
        searchParams.set('endDate', areaStartDate.toISOString());
      }
      const path = generatePath(':url?:queryString', {
        url: config.to,
        queryString: createSearchParams(searchParams).toString()
      });
      navigate(path);
    }
  };
  const xTicks = useSevenTicks(startDate, endDate);
  const yTicks = useQualityChartYTicks(data, config.rangeStart, config.rangeEnd);
  const timezone = useTimezone();
  const showDecimalPoints = config?.rangeEnd ? config.rangeEnd < 1000 : true;

  return (
    <div data-testid={`${config.modelled}-quality-chart`}>
      <ResponsiveContainer width="100%" height={spacing(38)}>
        <LineChart margin={{ top: spacing(1), right: 0, bottom: 0, left: 0 }} data={data}>
          <CartesianGrid />
          <Line
            type="basis"
            dataKey="upperBound"
            stroke={palette.white}
            strokeWidth={1}
            isAnimationActive={false}
            dot={false}
            activeDot={false}
            strokeDasharray="4"
          />
          <Line
            type="basis"
            dataKey="lowerBound"
            stroke={palette.white}
            strokeWidth={1}
            isAnimationActive={false}
            dot={false}
            activeDot={false}
            strokeDasharray="4"
          />
          <Line
            type="monotone"
            dataKey="predicted"
            stroke={palette.leaf}
            strokeWidth={2}
            isAnimationActive={false}
            dot={false}
          />

          {referenceAreaData.map(({ start, end }) => (
            <ReferenceArea
              key={start}
              x1={start}
              x2={end}
              fill={palette.flame}
              onClick={() => onClick(new Date(end), new Date(start))}
              className={classNames(styles.referenceArea, { [styles.isSelectable]: !!config.to })}
            />
          ))}
          <Line
            dot={MeasuredValueDot}
            activeDot={false}
            dataKey="measured"
            stroke={palette.white}
            style={{ stroke: 'transparent' }}
            isAnimationActive={false}
          />
          <XAxis
            dataKey="date"
            type="number"
            domain={[startDate.getTime(), endDate.getTime()]}
            ticks={xTicks}
            tickFormatter={(tick: string) => getLocalTime(new Date(tick), timezone)}
            allowDataOverflow
            height={spacing(4)}
          />
          <YAxis
            width={spacing(6)}
            dataKey="measured"
            ticks={yTicks}
            domain={[yTicks[0], yTicks[yTicks.length - 1]]}
            allowDataOverflow
            tickFormatter={(tick: number) => tick.toFixed(showDecimalPoints ? 1 : 0)}
          />
          {data.length > 0 && <Tooltip content={<QualityTooltipContent unit={unit} />} />}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default QualityChart;
