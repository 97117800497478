import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import SummaryItem from './SummaryItem';
import { WeeklyPerformanceData } from '../utils/useWeeklyPerformanceData';
import { round } from '../../../utils';

type Props = {
  featureTitle: string;
  data: WeeklyPerformanceData;
};

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    listStyle: 'none',
    padding: 0,
    gap: 16
  }
});

type MessageProps = {
  featureTitle: string;
};

const TotalTimeGood = ({ featureTitle }: MessageProps) => (
  <FormattedMessage
    defaultMessage="Total time good {featureTitle}"
    id="page.HistoricalPerformance.summary.good"
    values={{ featureTitle }}
  />
);
const TotalTimeBad = ({ featureTitle }: MessageProps) => (
  <FormattedMessage
    defaultMessage="Total time bad {featureTitle}"
    id="page.HistoricalPerformance.summary.bad"
    values={{ featureTitle }}
  />
);

const AverageIncidentDuration = ({ featureTitle }: MessageProps) => (
  <FormattedMessage
    defaultMessage="Average incident duration (high {featureTitle} and low {featureTitle} combined)"
    id="page.HistoricalPerformance.summary.incidentDuration"
    values={{ featureTitle }}
  />
);

const SummaryList = ({ featureTitle, data }: Props) => {
  const styles = useStyles();
  const titles = [
    <TotalTimeGood featureTitle={featureTitle} />,
    <TotalTimeBad featureTitle={featureTitle} />,
    <AverageIncidentDuration featureTitle={featureTitle} />
  ];
  return (
    <ul className={styles.root} data-testid="performance-summary-table">
      <SummaryItem title={titles[0]} unit="%">
        {round(data.totalOkPercentageTime)}
      </SummaryItem>
      <SummaryItem title={titles[1]} unit="%">
        {round(data.totalHighPercentageTime + data.totalLowPercentageTime)}
      </SummaryItem>
      <SummaryItem title={titles[2]} unit="min">
        {round(data.averageBadDuration / 60, 0)}
      </SummaryItem>
    </ul>
  );
};

export default SummaryList;
