import React from 'react';
import { createUseStyles } from 'react-jss';
import Chart from './Chart/Chart';
import useGasAnalyserData from './useGasAnalyserData';
import { PlantData } from '../../../utils/useHistoricalPlantData';
import { SoftSensor } from '../../../utils/useHistoricalSoftSensors';
import { useUnit } from '../../../utils';
import { Options } from '../schema';
import { EzerTheme } from '../../../EzerThemeProvider';
import { LocalisedLabel } from '../../../lib';

type Props = {
  startDate: Date;
  endDate: Date;
  options: Options;
  plantData: PlantData;
  performanceData: SoftSensor[];
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  title: {
    textAlign: 'center',
    fontSize: spacing(4),
    marginTop: spacing(2)
  }
}));

const ChartContainer = ({ startDate, endDate, options, plantData, performanceData }: Props) => {
  const styles = useStyles();
  const { chartTitle } = options;
  const { leftFeature, rightFeature } = options;
  const data = useGasAnalyserData(startDate, endDate, plantData, performanceData, leftFeature, rightFeature);
  const { data: leftUnit } = useUnit(leftFeature.featureName);
  const { data: rightUnit } = useUnit(rightFeature.featureName);

  return (
    <>
      <h2 className={styles.title}>
        <LocalisedLabel>{chartTitle}</LocalisedLabel>
      </h2>
      <Chart
        data={data}
        startDate={startDate}
        endDate={endDate}
        options={options}
        leftUnit={leftUnit}
        rightUnit={rightUnit}
      />
    </>
  );
};

export default ChartContainer;
