import React from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../EzerThemeProvider';

type Props = {
  title: React.ReactNode;
  description: React.ReactNode;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  title: {
    marginTop: spacing(2),
    fontSize: spacing(10)
  },
  description: {
    marginTop: spacing(2),
    fontSize: spacing(5)
  }
}));

const SimplePage = ({ title, description }: Props) => {
  const styles = useStyles();
  return (
    <>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.description}>{description}</p>
    </>
  );
};

export default SimplePage;
