import React from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../../EzerThemeProvider';
import { Section } from '../../lib';

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    backgroundColor: palette.lightCarbon,
    height: spacing(42),
    overflow: 'hidden'
  }
}));

type Props = {
  children: React.ReactNode;
  dataTestId?: string;
};
const MessageContainer = ({ children, dataTestId }: Props) => {
  const styles = useStyles();
  return (
    <Section className={styles.root} dataTestId={dataTestId}>
      {children}
    </Section>
  );
};

export default MessageContainer;
