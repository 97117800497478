import { useMemo } from 'react';
import { PlantStatus } from '../../PlantSection/PlantStatusTimeline/usePlantStatus';
import { useCurrentDataStatus } from '../../../../utils';
import { useCurrentPlantStatus } from '../../utils';

export enum ClientStatus {
  stable = 'stable',
  unstable = 'unstable',
  offline = 'offline',
  apiDown = 'apiDown',
  shutdown = 'shutdown',
  lightUp = 'lightUp',
  unknown = 'unknown',
  warmingUp = 'warmingUp',
  coolingDown = 'coolingDown',
  gapsInFeed = 'gapsInFeed'
}

type Response = {
  data: ClientStatus;
  isLoading: boolean;
};

const plantStatusDataToClientStatus: Partial<Record<PlantStatus['status'], ClientStatus>> = {
  SHUTDOWN: ClientStatus.shutdown,
  LIGHT_UP: ClientStatus.lightUp,
  UNKNOWN: ClientStatus.unknown,
  WARMING_UP: ClientStatus.warmingUp,
  COOLING_DOWN: ClientStatus.coolingDown,
  GAPS_IN_FEED: ClientStatus.gapsInFeed
};

const useClientStatus = (): Response => {
  const { data: currentDataStatus, isLoading: isLoadingCurrentDataStatus } = useCurrentDataStatus();
  const { data: currentPlantStatusData, isLoading: isLoadingCurrentPlantStatus } = useCurrentPlantStatus();

  const data = useMemo(() => {
    // if we are still loading data, return status unknown
    if (isLoadingCurrentDataStatus) {
      return ClientStatus.unknown;
    }
    // if there is no response from the ingest job data, return api down
    if (!currentDataStatus) {
      return ClientStatus.apiDown;
    }
    // For a negative ingest job status, return api down
    if (currentDataStatus.status !== 'OK') {
      return ClientStatus.apiDown;
    }
    // return unknown if we are still loading that response
    if (isLoadingCurrentPlantStatus) {
      return ClientStatus.unknown;
    }
    // return api down if the there is no response
    if (!currentPlantStatusData) {
      // PlantStatus is not written when API_DOWN
      // Though, code could reach here if IngestJob call resolves before the PlantStatus one
      return ClientStatus.apiDown;
    }

    // if we get to here, return the appropriate response
    const plantStatus = plantStatusDataToClientStatus[currentPlantStatusData.status];
    if (plantStatus) {
      return plantStatus;
    }

    return ClientStatus.stable;
  }, [currentDataStatus, isLoadingCurrentDataStatus, currentPlantStatusData, isLoadingCurrentPlantStatus]);

  return {
    data,
    isLoading: isLoadingCurrentDataStatus || isLoadingCurrentPlantStatus
  };
};

export default useClientStatus;
