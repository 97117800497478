import React from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../EzerThemeProvider';

type Props = {
  children: React.ReactNode;
};

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    backgroundColor: palette.lightCarbon,
    height: spacing(38),
    paddingTop: spacing(17),
    borderRadius: spacing(0.5)
  }
}));

const ChartMessageContainer = ({ children }: Props) => {
  const styles = useStyles();
  return <div className={styles.root}>{children}</div>;
};

export default ChartMessageContainer;
