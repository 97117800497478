import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import RecommendationFor from './RecommendationFor';
import isRecommendationNew from './isRecommendationNew';
import RecommendationAge from './RecommendationAge';
import ParameterTable from './ParameterTable/ParameterTable';
import useNow from '../../../../useNow';
import { Section, SectionTitle, SectionContent } from '../../lib';
import NewNotification from './NewNotification';
import { EzerTheme } from '../../../../EzerThemeProvider';
import ReviewPanel from './ReviewPanel';
import ErrorMessage from './ErrorMessage';
import { Recommendation } from '../../../../types';
import { useTemplateOptions } from '../../TemplateOptionsContext';

type Props = {
  recommendation: Recommendation;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    minHeight: spacing(42)
  },
  flagged: {
    animation: 'glowAnimation 2s 0s infinite'
  },
  sectionContent: {
    overflow: 'hidden'
  }
}));

const CurrentRecommendationSection = ({ recommendation }: Props) => {
  const styles = useStyles();
  const now = useNow();
  const { newRecommendationFlagTime } = useTemplateOptions();
  const isError = recommendation?.error ?? false;
  const isNew =
    recommendation && !isError ? isRecommendationNew(recommendation, now, newRecommendationFlagTime) : false;

  return (
    <Section
      dataTestId="current-recommendation-section"
      className={classNames(styles.root, { [styles.flagged]: isNew })}>
      <SectionTitle>
        <FormattedMessage id="page.today.currentRecommendation" defaultMessage="Current Recommendation" />
      </SectionTitle>
      {!isError && (
        <>
          <RecommendationFor />
          {isNew ? <NewNotification /> : <RecommendationAge />}
        </>
      )}
      <SectionContent className={styles.sectionContent}>
        {isError && <ErrorMessage />}
        {!isError && (
          <>
            <ParameterTable />
            <ReviewPanel />
          </>
        )}
      </SectionContent>
    </Section>
  );
};

export default CurrentRecommendationSection;
