import useNow from '../../../../useNow';
import useLatestRecommendations from './useLatestRecommendations';
import { Recommendation } from '../../../../types';

type Response = {
  data: Recommendation | null;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
};

const findActiveRecommendation = (now: Date, recommendations: Recommendation[]): Recommendation | null => {
  // no recommendations in response
  if (recommendations.length === 0) {
    return null;
  }
  return recommendations[0];
};
const useActiveRecommendation = (): Response => {
  const { data, isLoading, isFetching, isError } = useLatestRecommendations();
  const now = useNow();

  return {
    data: findActiveRecommendation(now, data),
    isLoading,
    isFetching,
    isError
  };
};

export default useActiveRecommendation;
