import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TooltipProps } from 'recharts';
import { ChartTooltip, LocalisedLabel } from '../../lib';
import { getLocalDate } from '../../utils';
import { Label } from '../../types';

interface Props extends TooltipProps<string, string> {
  leftAxisLabel: Label;
  deviationLabel: Label;
  sensorDataLabel: Label;
}

type ItemLabelProps = {
  leftAxisLabel: Label;
  deviationLabel: Label;
  sensorDataLabel: Label;
  name?: string;
};
const ItemLabel = ({ leftAxisLabel, deviationLabel, sensorDataLabel, name }: ItemLabelProps) => {
  if (name === 'percentWithinBounds') {
    return <LocalisedLabel>{leftAxisLabel}</LocalisedLabel>;
  }
  if (name === 'standardDeviation') {
    return <LocalisedLabel>{deviationLabel}</LocalisedLabel>;
  }
  return <LocalisedLabel>{sensorDataLabel}</LocalisedLabel>;
};

const WeekStart = () => <FormattedMessage defaultMessage="Week Start" id="labels.weekStart" />;
const TooltipContent = ({ label, payload = [], leftAxisLabel, deviationLabel, sensorDataLabel }: Props) => {
  const itemListData = payload.map(({ value, color, dataKey }) => ({
    label: (
      <>
        <ItemLabel
          leftAxisLabel={leftAxisLabel}
          deviationLabel={deviationLabel}
          sensorDataLabel={sensorDataLabel}
          name={String(dataKey)}
        />{' '}
        {value} %
      </>
    ),
    color
  }));
  const tooltipLabel = label ? (
    <>
      <WeekStart />: {getLocalDate(new Date(label), 'UTC')}
    </>
  ) : (
    <></>
  );
  return <ChartTooltip label={tooltipLabel} itemListData={itemListData} />;
};

export default TooltipContent;
