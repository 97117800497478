import React from 'react';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import { LocalisedLabel, LoadingMessage, DataLoadErrorMessage } from '../../lib';
import { KpiData } from './useKpiData';
import Chart from './Chart';
import { EzerTheme } from '../../EzerThemeProvider';
import { TemplateSchema } from './schema';

type Props = {
  title: TemplateSchema['label'];
  options: TemplateSchema['options'];
  data: KpiData;
  isLoading: boolean;
  isError: boolean;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  title: {
    textAlign: 'center',
    fontSize: spacing(4),
    marginTop: spacing(2)
  }
}));

const ChartContainer = ({ title, options, data, isLoading, isError }: Props) => {
  const styles = useStyles();
  return (
    <>
      <h2 className={styles.title}>
        <LocalisedLabel>{title}</LocalisedLabel>
      </h2>
      {isLoading && <LoadingMessage />}
      {isError && <DataLoadErrorMessage />}
      {data.periods.length === 0 && !isLoading && !isError && (
        <p>
          <FormattedMessage defaultMessage="No data available" id="labels.noData" />
        </p>
      )}
      {data.periods.length > 0 && !isLoading && !isError && <Chart data={data} options={options} />}
    </>
  );
};

export default ChartContainer;
