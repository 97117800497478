import React from 'react';
import { Recommendation } from '../../../types';
import { PlantData } from '../../../utils/useHistoricalPlantData';
import { Feedback } from '../../../utils/useHistoricalFeedbacks';
import useColumns from './useColumns';
import useRows from './useRows';
import { DataTable } from '../../../lib';
import { Feature } from '../schema';

type Props = {
  recommendationData: Recommendation[];
  plantData: PlantData;
  feedbackData: Feedback[];
  features: Feature[];
  fileName: string;
};

const Table = ({ recommendationData, plantData, feedbackData, features, fileName }: Props) => {
  const columns = useColumns(features);
  const rows = useRows(features, recommendationData, plantData, feedbackData);
  return (
    <div data-testid="historical-recommendations-gateway-table">
      <DataTable columns={columns} data={rows} fileName={fileName} />
    </div>
  );
};

export default Table;
