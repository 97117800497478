import { FeatureName } from '../types';
import useMetadata from './useMetadata';

type Response = {
  data: string;
  isLoading: boolean;
  isError: boolean;
};
const useItem = (featureName: FeatureName): Response => {
  const { data, isLoading, isError } = useMetadata([featureName]);
  return {
    data: data?.[featureName]?.unit ?? '',
    isLoading,
    isError
  };
};
export default useItem;
