import { addHours, getMinutes, subHours } from 'date-fns';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useNow from '../../../../useNow';
import { useMinuteInterval, useUnit } from '../../../../utils';
import { KpiChartConfig } from '../../schema';
import Chart from './Chart';
import useKpiByHourData from './useKpiByHourData';
import ChartErrorMessage from '../ChartErrorMessage';
import ChartLoadingMessage from '../ChartLoadingMessage';
import { ChartTitle, LocalisedLabel } from '../../../../lib';

type Props = {
  config: KpiChartConfig;
};

const getCurrentHourDate = (now: Date) => {
  const result = new Date(now.getTime());
  result.setMinutes(0, 0, 0);
  return result;
};

const KpiByHourSection = ({ config }: Props) => {
  const now = useNow();
  const [currentHour, setCurrentHour] = useState(getCurrentHourDate(now));
  const [searchParams] = useSearchParams();
  const isTimeTravelActive = searchParams.has('now');

  useMinuteInterval(() => {
    const time = new Date();
    const minutes = getMinutes(time);
    if (!isTimeTravelActive && minutes === 3) {
      setCurrentHour(getCurrentHourDate(time));
    }
  });

  const endDate = addHours(currentHour, 6);
  const startDate = subHours(currentHour, 24);

  const {
    data,
    isLoading: isKpiDataLoading,
    isError: isKpiDataError
  } = useKpiByHourData(config.feature.name, startDate, endDate);
  const { data: unit, isLoading: isUnitLoading, isError: isUnitError } = useUnit(config.feature.name);
  const isLoading = isKpiDataLoading || isUnitLoading;
  const isError = isKpiDataError || isUnitError;
  const { feature } = config;

  return (
    <section>
      <ChartTitle>
        <>
          <LocalisedLabel>{feature.label}</LocalisedLabel> ({unit})
        </>
      </ChartTitle>
      {isLoading && <ChartLoadingMessage />}
      {isError && <ChartErrorMessage />}
      {!isLoading && !isError && data && (
        <Chart data={data} unit={unit} config={config} startDate={startDate} endDate={endDate} addBufferSpace={false} />
      )}
    </section>
  );
};

export default KpiByHourSection;
