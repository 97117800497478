import { SoftSensor } from '../../../utils/useHistoricalSoftSensors';
import useColumnData from './useColumnData';
import useTableData from './useTableData';
import { DataTable } from '../../../lib';
import { ChartConfig } from '../schema';

type Props = {
  chartConfigs: ChartConfig[];
  data: SoftSensor[];
  fileName: string;
};

const Table = ({ chartConfigs, data, fileName }: Props) => {
  const columnData = useColumnData(chartConfigs);
  const tableData = useTableData(data);

  return <DataTable data={tableData} columns={columnData} fileName={fileName} />;
};

export default Table;
