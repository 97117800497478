import { FormattedMessage } from 'react-intl';
import React from 'react';

type Props = {
  featureTitle: string;
};

export const DESCRIPTOR = {
  defaultMessage: 'Av. Duration Low {featureTitle}',
  id: 'page.HistoricalPerformance.lowAverageDuration'
};

const AverageDurationLow = ({ featureTitle }: Props) => (
  <FormattedMessage defaultMessage={DESCRIPTOR.defaultMessage} id={DESCRIPTOR.id} values={{ featureTitle }} />
);

export default AverageDurationLow;
