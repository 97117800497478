{
  "periods": [
    {
      "period": "2024-W13",
      "average_value": 1.37,
      "st_dev": 0.49,
      "percent_within_bounds": 74
    },
    {
      "period": "2024-W14",
      "average_value": 1.1,
      "st_dev": 0.38,
      "percent_within_bounds": 54
    },
    {
      "period": "2024-W15",
      "average_value": 1.27,
      "st_dev": 0.35,
      "percent_within_bounds": 85
    },
    {
      "period": "2024-W16",
      "average_value": 1.75,
      "st_dev": 0.5,
      "percent_within_bounds": 88
    },
    {
      "period": "2024-W17",
      "average_value": 1.42,
      "st_dev": 0.7,
      "percent_within_bounds": 64
    },
    {
      "period": "2024-W18",
      "average_value": 1.75,
      "st_dev": 0.62,
      "percent_within_bounds": 78
    },
    {
      "period": "2024-W19",
      "average_value": 1.62,
      "st_dev": 0.68,
      "percent_within_bounds": 70
    },
    {
      "period": "2024-W20",
      "average_value": 1.57,
      "st_dev": 0.75,
      "percent_within_bounds": 53
    },
    {
      "period": "2024-W21",
      "average_value": 1.19,
      "st_dev": 0.52,
      "percent_within_bounds": 66
    },
    {
      "period": "2024-W22",
      "average_value": 0.85,
      "st_dev": 0.37,
      "percent_within_bounds": 25
    },
    {
      "period": "2024-W23",
      "average_value": 1.41,
      "st_dev": 0.71,
      "percent_within_bounds": 52
    },
    {
      "period": "2024-W24",
      "average_value": 1.09,
      "st_dev": 0.44,
      "percent_within_bounds": 56
    },
    {
      "period": "2024-W25",
      "average_value": 1.22,
      "st_dev": 0.41,
      "percent_within_bounds": 65
    },
    {
      "period": "2024-W26",
      "average_value": 1.47,
      "st_dev": 0.54,
      "percent_within_bounds": 78
    },
    {
      "period": "2024-W27",
      "average_value": null,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-W28",
      "average_value": null,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-W29",
      "average_value": null,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-W30",
      "average_value": null,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-W31",
      "average_value": 1.28,
      "st_dev": 0.83,
      "percent_within_bounds": 41
    },
    {
      "period": "2024-W32",
      "average_value": 2.12,
      "st_dev": 0.97,
      "percent_within_bounds": 57
    },
    {
      "period": "2024-W33",
      "average_value": 1.3,
      "st_dev": 0.5,
      "percent_within_bounds": 74
    },
    {
      "period": "2024-W34",
      "average_value": 1.43,
      "st_dev": 0.56,
      "percent_within_bounds": 66
    },
    {
      "period": "2024-W35",
      "average_value": null,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-W36",
      "average_value": null,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-W37",
      "average_value": null,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-W38",
      "average_value": null,
      "st_dev": null,
      "percent_within_bounds": null
    },
    {
      "period": "2024-W39",
      "average_value": 1.33,
      "st_dev": 0.42,
      "percent_within_bounds": 82
    },
    {
      "period": "2024-W40",
      "average_value": null,
      "st_dev": null,
      "percent_within_bounds": null
    }
  ],
  "baselines": null,
  "targets": [
    {
      "start_period": "2024-W13",
      "end_period": "2024-W40",
      "lower_bound": 1.0,
      "upper_bound": 2.5
    }
  ]
}
