import { Static, Type } from '@sinclair/typebox';
import { LabelSchema } from '../../Config/sharedSchemas';
import { selectableEnum } from '../../Config/utils';
import { FeatureName } from '../../types';
import getTemplateSchema from '../getTemplateSchema';

const chartConfigSchema = Type.Object({
  title: LabelSchema({ title: 'Title', description: 'The title of the chart' }),
  featureName: selectableEnum(FeatureName, {
    title: 'Feature Name',
    description: 'The name of the recommended feature'
  }),
  xAxisLabel: LabelSchema({ title: 'X Axis Label', description: 'Label for the X axis' }),
  yAxisLabel: LabelSchema({ title: 'Y Axis Label', description: 'Label for the Y axis' }),
  numberOfTicks: Type.Optional(
    Type.Number({ title: 'Number of Ticks', description: 'Number of ticks to display on the Y axis' })
  ),
  rangeStart: Type.Optional(Type.Number({ title: 'Range Start', description: 'The start of the range on the Y axis' })),
  rangeEnd: Type.Optional(Type.Number({ title: 'Range End', description: 'The end of the range on the Y axis' }))
});
export type ChartConfig = Static<typeof chartConfigSchema>;

const options = Type.Object(
  {
    chartConfigs: Type.Array(chartConfigSchema, {
      title: 'Chart Configs',
      description: 'The configuration for the charts to display'
    })
  },
  { title: 'Options', description: 'Options for the Historical Quality template' }
);

export const templateSlug = 'HistoricalQualityMokPage';
export const templateSchema = Type.Object(getTemplateSchema({ templateSlug, options }), {
  title: 'Historical Mok Quality',
  description: 'Historical Quality page for Mok. Gets target data from plant data'
});
export type TemplateSchema = Static<typeof templateSchema>;
export const templateUiSchema = {
  featureFlag: {
    'ui:options': {
      // placeholder to show how to set a field option
      disabled: false
    }
  }
};
