{
  "item": {
    "s_c_sol_fcao": {
      "value": 2.124,
      "type": "sensor",
      "status": "ok",
      "unit": "%",
      "timestamp": "2024-09-26T13:30:00+00:00"
    }
  }
}
