import { createUseStyles } from 'react-jss';
import AnalyticsPageTracker from './AnalyticsPageTracker';
import AppRoutes from './AppRoutes';
import ErrorHandler from './ErrorHandler/ErrorHandler';
import EzerThemeProvider from './EzerThemeProvider';
import FixedNowProvider from './FixedNowProvider';
import GetBearerTokenProvider from './GetBearerTokenProvider/GetBearerTokenProvider';
import Header from './Header/Header';
import HoneyCombTracker from './HoneyCombPageTracker';
import LocalisationProvider from './LocalisationProvider';
import MuiThemeProvider from './MuiThemeProvider';
import PageContainer from './PageContainer';
import ReactQueryProvider from './ReactQueryProvider/ReactQueryProvider';
import fontStyles from './styles/fonts.styles';
import globalStyles from './styles/global.styles';
import rechartsStyles from './styles/recharts.styles';
import resetStyles from './styles/reset.styles';
import VersionTracker from './VersionTracker';
import ConfigUserErrorHandler from './ConfigUserErrorHandler';

const useStyles = createUseStyles({
  '@global': {
    ...fontStyles,
    ...resetStyles,
    ...globalStyles,
    ...rechartsStyles
  }
});

function App() {
  useStyles();
  return (
    <AnalyticsPageTracker>
      <EzerThemeProvider>
        <MuiThemeProvider>
          <GetBearerTokenProvider>
            <ReactQueryProvider>
              <VersionTracker>
                <ConfigUserErrorHandler>
                  <LocalisationProvider>
                    <HoneyCombTracker>
                      <ErrorHandler>
                        <FixedNowProvider>
                          <Header />
                          <PageContainer>
                            <AppRoutes />
                          </PageContainer>
                        </FixedNowProvider>
                      </ErrorHandler>
                    </HoneyCombTracker>
                  </LocalisationProvider>
                </ConfigUserErrorHandler>
              </VersionTracker>
            </ReactQueryProvider>
          </GetBearerTokenProvider>
        </MuiThemeProvider>
      </EzerThemeProvider>
    </AnalyticsPageTracker>
  );
}

export default App;
