import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import { EzerTheme } from '../../../../EzerThemeProvider';
import { HiddenText } from '../../../../lib';
import { LoadingContent } from '../../lib';
import { useCurrentDataStatus } from '../../../../utils';
import Alarm from './Alarm/Alarm';
import BoundLabels from './BoundLabels';
import useAlarmGroups from './useAlarmGroups';

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    marginTop: spacing(1),
    minHeight: spacing(21.5)
  },
  title: {
    height: 0
  },
  disabled: {
    opacity: 0.5
  },
  alarmGroup: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    marginTop: spacing(1),
    paddingBottom: spacing(4)
  },
  boundLabels: {
    width: spacing(6),
    marginRight: spacing(1),
    marginTop: spacing(4),
    paddingTop: spacing(0.5),
    flexShrink: 0,
    flexGrow: 0
  },
  sensorList: {
    display: 'flex',
    gap: spacing(1),
    flexGrow: 1
  },
  alarm: {
    maxWidth: spacing(18),
    flexGrow: 1,
    flexBasis: 'min-content'
  }
}));

const AlarmPanel = () => {
  const styles = useStyles();
  const { data: alarmGroups = [] } = useAlarmGroups();
  const { data: dataStatusData, isLoading } = useCurrentDataStatus();
  const isDisabled = dataStatusData?.status === 'ERROR';

  return (
    <div className={classNames(styles.root, { [styles.disabled]: isDisabled })} data-testid="alarm-panel">
      {isLoading && <LoadingContent />}
      {!isLoading && (
        <>
          <h2 className={styles.title}>
            <HiddenText>
              <FormattedMessage defaultMessage="Plant Sensors" id="page.today.plantSensors.header" />
            </HiddenText>
          </h2>
          {alarmGroups.map((alarmGroup) => (
            <div key={alarmGroup[0].feature.name} className={styles.alarmGroup}>
              <BoundLabels className={styles.boundLabels} />
              <ul className={styles.sensorList}>
                {alarmGroup.map((alarmConfig) => (
                  <Alarm key={alarmConfig.feature.name} config={alarmConfig} className={styles.alarm} />
                ))}
              </ul>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default AlarmPanel;
