import React from 'react';
import LoadingPage from '../LoadingPage';
import useBrowserLocale from './useBrowserLocale';

const AuthenticatingPage = () => {
  const browserLocale = useBrowserLocale();
  return (
    <LoadingPage>
      {browserLocale === 'en' && <span>Authenticating</span>}
      {browserLocale === 'es' && <span>Autenticador</span>}
      {/* Not yet localised */}
      {browserLocale === 'pt-br' && <span>Autenticando</span>}
      {browserLocale === 'pt-pt' && <span>Autenticando</span>}
    </LoadingPage>
  );
};

export default AuthenticatingPage;
