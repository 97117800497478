import React from 'react';
import { createUseStyles } from 'react-jss';
import ChartMessageContainer from './ChartMessageContainer';
import { EzerTheme } from '../../../EzerThemeProvider';
import { DataCouldNotBeLoaded } from '../../../messages';

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  message: {
    paddingTop: spacing(2),
    display: 'flex',
    justifyContent: 'center'
  }
}));

const ChartErrorMessage = () => {
  const styles = useStyles();
  return (
    <ChartMessageContainer>
      <p className={styles.message}>
        <DataCouldNotBeLoaded />
      </p>
    </ChartMessageContainer>
  );
};

export default ChartErrorMessage;
