import React from 'react';
import useConfig from './Config/useConfig';
import { SimplePage } from './lib';
import PageContainer from './PageContainer';
import useUserQuery from './serviceQueries/useUserQuery';

type Props = {
  children: React.ReactNode;
};

const Title = () => <>Something went wrong.</>;
const ConfigErrorDescription = () => <>Config could not be loaded</>;
const UserErrorDescription = () => <>User could not be loaded</>;
const ConfigUserErrorHandler = ({ children }: Props) => {
  const { isError: isConfigError } = useConfig();
  const { isError: isUserError } = useUserQuery();
  if (isConfigError) {
    return (
      <PageContainer>
        <SimplePage title={<Title />} description={<ConfigErrorDescription />} />
      </PageContainer>
    );
  }
  if (isUserError) {
    return (
      <PageContainer>
        <SimplePage title={<Title />} description={<UserErrorDescription />} />
      </PageContainer>
    );
  }

  return <>{children}</>;
};
export default ConfigUserErrorHandler;
