import { addDays, subDays } from 'date-fns';
import { PageTitle, LoadingMessage, LocalisedLabel } from '../../lib';
import { FilterControls, useEndDate, useStartDate } from '../../FilterControls';
import useToday from '../../useToday';
import { useSelectedParam } from '../../lib/Select';
import Table from './Table/Table';
import { useHistoricalPlantData, useHistoricalRecommendations, useHistoricalFeedbacks, useFileName } from '../../utils';
import Chart from './Chart/Chart';
import { TemplateSchema } from './schema';

const HistoricalRecommendationsNewConfigPage = ({ label, options }: TemplateSchema) => {
  const today = useToday();
  const startDate = useStartDate(subDays(today, 30));
  const endDate = useEndDate(addDays(today, 1));
  const { features } = options;
  const recommendedFeatureNames = features.map(({ recommendedFeatureName }) => recommendedFeatureName);

  const { isLoading: isLoadingRecommendationData, data: recommendationData } = useHistoricalRecommendations(
    recommendedFeatureNames,
    startDate,
    endDate
  );
  const selectData = features.map(({ recommendedFeatureName, title }) => ({
    value: recommendedFeatureName as string,
    label: title.default
  }));
  const selectedParam = useSelectedParam();
  const chartParam = selectedParam ?? features[0].recommendedFeatureName;
  const activeFeature = features.find(({ recommendedFeatureName }) => recommendedFeatureName === chartParam);
  const plantDataFeatureNames = features.map(({ historicalFeatureName }) => historicalFeatureName);
  const { data: plantData, isLoading: isLoadingPlantData } = useHistoricalPlantData(
    plantDataFeatureNames,
    startDate,
    endDate
  );
  const { data: feedbackData, isLoading: isLoadingFeedbackData } = useHistoricalFeedbacks(startDate, endDate);

  const isLoading = isLoadingRecommendationData || isLoadingPlantData;
  const fileName = useFileName(label, startDate, endDate);

  return (
    <>
      <PageTitle>
        <LocalisedLabel>{label}</LocalisedLabel>
      </PageTitle>
      <FilterControls isLoading={isLoading} selectData={selectData} />
      {isLoading && <LoadingMessage />}
      {!!activeFeature && !isLoading && (
        <Chart
          feature={activeFeature}
          recommendationData={recommendationData}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {!isLoading && !isLoadingFeedbackData && (
        <Table
          plantData={plantData}
          recommendationData={recommendationData}
          feedbackData={feedbackData}
          features={features}
          fileName={fileName}
        />
      )}
    </>
  );
};

export default HistoricalRecommendationsNewConfigPage;
