import { ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Mode, Unit } from './constants';

export type ButtonSetting = {
  label: string | ReactNode;
  value: number;
  unit: Unit;
};

const OneDay = () => <FormattedMessage id="buttons.filter.date.1day" defaultMessage="1d" />;
const TwoDays = () => <FormattedMessage id="buttons.filter.date.2days" defaultMessage="2d" />;
const SevenDays = () => <FormattedMessage id="buttons.filter.date.30days" defaultMessage="7d" />;
const ThirtyDays = () => <FormattedMessage id="buttons.filter.date.7days" defaultMessage="30d" />;
const OneWeek = () => <FormattedMessage id="buttons.filter.date.1week" defaultMessage="1w" />;
const OneMonth = () => <FormattedMessage id="buttons.filter.date.1month" defaultMessage="1m" />;
const ThreeMonths = () => <FormattedMessage id="buttons.filter.date.3months" defaultMessage="3m" />;
const SixMonths = () => <FormattedMessage id="buttons.filter.date.6months" defaultMessage="6m" />;
const NineMonths = () => <FormattedMessage id="buttons.filter.date.9months" defaultMessage="9m" />;
const OneYear = () => <FormattedMessage id="buttons.filter.date.12months" defaultMessage="1y" />;
const TwoYears = () => <FormattedMessage id="buttons.filter.date.24months" defaultMessage="2y" />;

const BY_MONTH_BUTTON_SETTINGS: ButtonSetting[] = [
  {
    label: <ThreeMonths />,
    value: 3,
    unit: Unit.month
  },
  {
    label: <SixMonths />,
    value: 6,
    unit: Unit.month
  },
  {
    label: <NineMonths />,
    value: 9,
    unit: Unit.month
  },
  {
    label: <OneYear />,
    value: 1,
    unit: Unit.year
  }
];

const BY_DAY_BUTTON_SETTINGS: ButtonSetting[] = [
  {
    label: <OneDay />,
    value: 1,
    unit: Unit.day
  },
  {
    label: <TwoDays />,
    value: 2,
    unit: Unit.day
  },
  {
    label: <SevenDays />,
    value: 7,
    unit: Unit.day
  },
  {
    label: <ThirtyDays />,
    value: 30,
    unit: Unit.day
  }
];

const BY_WEEK_AND_MONTH_BUTTON_SETTINGS: ButtonSetting[] = [
  {
    label: <OneWeek />,
    value: 1,
    unit: Unit.week
  },
  {
    label: <OneMonth />,
    value: 1,
    unit: Unit.month
  },
  {
    label: <ThreeMonths />,
    value: 3,
    unit: Unit.month
  },
  {
    label: <SixMonths />,
    value: 6,
    unit: Unit.month
  }
];

const BY_MONTH_AND_YEAR_BUTTON_SETTINGS: ButtonSetting[] = [
  {
    label: <ThreeMonths />,
    value: 3,
    unit: Unit.month
  },
  {
    label: <SixMonths />,
    value: 6,
    unit: Unit.month
  },
  {
    label: <OneYear />,
    value: 1,
    unit: Unit.year
  },
  {
    label: <TwoYears />,
    value: 2,
    unit: Unit.year
  }
];

const useButtonSettings = (mode: Mode): ButtonSetting[] =>
  useMemo(() => {
    if (mode === Mode.day) {
      return BY_DAY_BUTTON_SETTINGS;
    }
    if (mode === Mode.weekAndMonth) {
      return BY_WEEK_AND_MONTH_BUTTON_SETTINGS;
    }
    if (mode === Mode.monthAndYear) {
      return BY_MONTH_AND_YEAR_BUTTON_SETTINGS;
    }
    return BY_MONTH_BUTTON_SETTINGS;
  }, [mode]);

export default useButtonSettings;
