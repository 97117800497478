import { FormattedNumber } from 'react-intl';
import { round } from '../utils';
import { NotApplicable } from '../messages';

type Props = {
  children?: number;
  className?: string;
};

const Number = ({ children, className }: Props) => (
  <span className={className}>{children ? <FormattedNumber value={round(children)} /> : <NotApplicable />}</span>
);

export default Number;
