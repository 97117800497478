import { FeatureName } from '../../../../types';
import useKpiByHourQuery from '../../../../serviceQueries/useKpiByHourQuery';

type Period = {
  hour: number;
  averageValue: number;
};

export type ChartData = {
  periods: Period[];
  baseline?: number;
};

export type KpiByHourData = {
  data?: ChartData;
  isLoading: boolean;
  isError: boolean;
};

// eslint-disable-next-line
const generateChartData = (data: any): ChartData => ({
  // eslint-disable-next-line
  periods: data.periods.map((periodData: any) => ({
    hour: new Date(periodData.period).getTime(),
    averageValue: periodData.average_value
  })),
  baseline: data.baselines?.[0].value
});

const useKpiByHourData = (featureName: FeatureName, startDate: Date, endDate: Date): KpiByHourData => {
  const { data, isLoading, isError } = useKpiByHourQuery(featureName, startDate, endDate);
  return {
    data: isLoading || isError ? undefined : generateChartData(data),
    isLoading,
    isError
  };
};

export default useKpiByHourData;
