import { createUseStyles } from 'react-jss';
import QualityChartData from '../../../../../types/QualityChartData';
import Row from './Row';
import { EzerTheme } from '../../../../../EzerThemeProvider';
import { LocalisedLabel } from '../../../../../lib';
import { Label } from '../../../../../types';

type Props = {
  data: QualityChartData;
  modelledLabel: Label;
  measuredLabel: Label;
  unit: string;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    marginBottom: spacing(1)
  }
}));

const HighlightTable = ({ data, modelledLabel, measuredLabel, unit }: Props) => {
  const styles = useStyles();
  const lastModelled = [...data].reverse().find((datum) => datum.predicted !== undefined);
  const lastMeasured = [...data].reverse().find((datum) => datum.measured !== undefined);

  return (
    <ul className={styles.root}>
      {lastModelled && (
        <Row
          title={<LocalisedLabel>{modelledLabel}</LocalisedLabel>}
          date={new Date(lastModelled.date)}
          value={lastModelled.predicted}
          unit={unit}
        />
      )}
      {lastMeasured && (
        <Row
          title={<LocalisedLabel>{measuredLabel}</LocalisedLabel>}
          date={new Date(lastMeasured.date)}
          value={lastMeasured.measured}
          unit={unit}
        />
      )}
    </ul>
  );
};

export default HighlightTable;
