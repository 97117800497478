{
  "items": [
    {
      "timestamp": "2024-09-26T14:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.23,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.46,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T14:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.13,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.57,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T14:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.69,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.61,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T14:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.62,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.7,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T14:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.37,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.22,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T14:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.11,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.66,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T14:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.92,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.16,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T14:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.05,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T14:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.15,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.59,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T14:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.45,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.3,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T14:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.37,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.13,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T14:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.09,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.91,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T14:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.85,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.66,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T14:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.62,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.86,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T14:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.04,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.47,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T14:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.33,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.34,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.89,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.99,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.6,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.52,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.83,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 6.69,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.57,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.26,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.41,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.53,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.16,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.67,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.52,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.97,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.56,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 9.5,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.23,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.0,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.56,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 8.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.43,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.04,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.84,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.06,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.63,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.61,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.57,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.17,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.6,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.21,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.69,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.79,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.82,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.56,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.67,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.27,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.13,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.84,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.97,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 9.08,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.44,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.06,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.86,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.5,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 9.38,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.19,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 9.5,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.23,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 28.38,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.5,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.97,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.56,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 8.26,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.31,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.62,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.7,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T13:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.48,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.89,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.23,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.88,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.64,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:54:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 17.48,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.79,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.72,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:50:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 18.57,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:48:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 17.1,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.76,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.15,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:44:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 17.04,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.73,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.79,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:40:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 15.85,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:38:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 14.11,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.71,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.84,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:34:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 18.06,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.68,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.05,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:30:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 16.15,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:28:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 16.66,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:26:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 16.85,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.65,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.69,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:22:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 14.69,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.63,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.83,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:18:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 13.42,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.6,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.92,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:14:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 16.21,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:12:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 14.62,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.57,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.51,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:08:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 9.98,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.55,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.31,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:04:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 13.39,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.52,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T12:00:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 13.54,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:58:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 13.3,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.49,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.52,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:54:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 10.99,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.47,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 9.54,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:50:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 15.07,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:48:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 13.51,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.44,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.58,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:44:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 11.89,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.41,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.13,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:40:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 15.44,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.3,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.44,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.3,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.13,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.92,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.31,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.13,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.01,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.65,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 28.93,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.42,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 36.94,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.73,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.99,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.89,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 23.27,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.26,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 27.59,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.13,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 9.78,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.01,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 9.92,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.31,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.68,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.56,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 56.14,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.05,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 21.05,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.2,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.42,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.29,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.41,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.8,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.47,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.2,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.98,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.65,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 7.72,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T11:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.57,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 8.58,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.01,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.84,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.09,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.89,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.4,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 9.86,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.03,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.11,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.39,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.98,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.47,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.06,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.73,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 89.97,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.0,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.88,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.23,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 28.36,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.88,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 372.96,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.03,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.0,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.12,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.42,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.45,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.63,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.71,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 73.37,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.44,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.37,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.21,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.13,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.53,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.96,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.05,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.7,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.38,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.27,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.33,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.91,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.37,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.18,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.29,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.53,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.67,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.48,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.71,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.74,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.22,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.78,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.41,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.01,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.54,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.21,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.39,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 28.36,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.29,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 32.81,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T10:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.33,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 43.23,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.19,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 49.47,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.09,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 21.3,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.5,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 25.94,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.62,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 67.23,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.95,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 48.64,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.88,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 192.2,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.09,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 25.94,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.72,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 58.11,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.59,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.34,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.33,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 42.85,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.26,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.77,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.99,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.66,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.05,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.15,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.51,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 28.29,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.36,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.96,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.29,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.87,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.24,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 72.74,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.37,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 69.05,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.61,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.38,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.38,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 81.26,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.54,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 26.51,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.09,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 43.87,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.56,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 62.41,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.64,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 62.25,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.71,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 24.92,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.68,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 103.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.74,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 49.08,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.83,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 23.37,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T09:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.69,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.36,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.54,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 23.91,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.64,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.16,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.81,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 32.69,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.83,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.46,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.89,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 35.53,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.54,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 309.89,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.26,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 45.01,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.38,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 60.53,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.66,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 165.15,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.39,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 128.24,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.84,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 62.25,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.43,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 60.34,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.18,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 30.07,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.47,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.52,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.84,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.35,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.69,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.29,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.62,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.17,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.61,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 24.35,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.87,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.34,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.25,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.05,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.19,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.89,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.97,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 26.13,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.94,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.98,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.54,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 9.66,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.57,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.28,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.76,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.28,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.34,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.58,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.77,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.92,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.5,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.71,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T08:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.62,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.35,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.74,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.16,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.68,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.16,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.72,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.84,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.9,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.62,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.82,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.38,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.73,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.47,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.57,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.02,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 24.43,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.52,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 23.27,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.38,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.78,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.1,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.26,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.86,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.73,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.42,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 26.13,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.4,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.79,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.87,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.04,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.35,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.46,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.03,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 21.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.69,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.41,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:22:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 11.44,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.47,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 21.79,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:18:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 20.03,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.45,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.48,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:14:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 18.69,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.42,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.21,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.4,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.14,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.37,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.1,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.36,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.57,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.34,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.01,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:02:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 20.6,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T07:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.32,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.94,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:58:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 16.72,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.29,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.31,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:54:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 18.06,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.26,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.33,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:50:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 17.42,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.24,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:46:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 16.09,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.21,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.92,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.18,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.26,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.14,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.85,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.41,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 55.31,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.39,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 41.65,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.49,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 40.63,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.22,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.77,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.57,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.39,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.4,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 23.33,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.13,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.63,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.33,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.32,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.92,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.59,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.89,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 26.85,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.79,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 51.88,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.68,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 43.03,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.66,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.22,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.39,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 31.17,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:10:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-26T06:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.26,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.87,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.04,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.39,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.51,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.19,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.84,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 50.1,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T06:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.14,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.5,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.28,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 76.23,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.11,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 44.51,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.55,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 47.49,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 1.87,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 86.6,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.78,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 751.62,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.18,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 262.97,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.75,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 23.46,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.97,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 32.23,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.69,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.97,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.22,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.56,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.58,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 23.44,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.41,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.65,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.4,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.77,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.38,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.8,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.3,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 58.43,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.25,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 130.66,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.21,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 83.66,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.19,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 99.88,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 1.7,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 636.57,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.17,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 197.54,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.89,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 96.32,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.67,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 26.5,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.07,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 48.0,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.76,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 28.74,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.09,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 81.03,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 1.66,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 149.67,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 1.83,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 237.03,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.2,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 243.32,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.27,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 201.04,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T05:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 1.94,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 131.59,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.51,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 93.15,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.08,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.21,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.63,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 72.35,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.66,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.95,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.02,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 42.26,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.9,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 26.0,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.13,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 30.71,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.34,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 23.02,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.43,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 44.25,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.13,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 37.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.84,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 150.56,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.04,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 53.41,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.37,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 35.8,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.86,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.66,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.7,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.34,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 74.64,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.48,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 55.25,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.4,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.35,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.28,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.17,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.74,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 47.71,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.89,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 35.22,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.14,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.21,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.62,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 55.48,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.06,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 58.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.0,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.14,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.41,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 79.48,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.05,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 80.97,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.55,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 51.56,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.39,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 111.39,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T04:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.71,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 43.58,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.45,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 25.69,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.25,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.63,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.14,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 84.37,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.76,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 72.29,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.96,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.36,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.65,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 30.52,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.71,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 46.6,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.18,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 8.21,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.77,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.73,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.81,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.36,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 29.18,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.33,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.36,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.41,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.74,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.86,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.72,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.9,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 109.83,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.39,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 26.7,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.02,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 37.64,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.43,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 80.05,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.4,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 21.87,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.4,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 34.71,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.18,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 39.8,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.05,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 38.29,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.24,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 32.17,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.91,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 100.97,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.76,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 37.89,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.78,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 53.98,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.11,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 45.84,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.47,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 85.82,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.57,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 57.48,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T03:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.82,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 55.38,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.74,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 38.15,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.36,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 34.08,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.35,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.48,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.91,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 38.43,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.31,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 51.5,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.98,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 34.59,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.87,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 120.61,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.79,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 100.2,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.14,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 117.62,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.63,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 58.12,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.64,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 81.89,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.42,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 73.82,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.88,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 42.41,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.69,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.93,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.91,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 35.18,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.35,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 159.14,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.28,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 112.41,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.77,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 157.87,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.92,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 81.88,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.53,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 30.77,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.39,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 73.63,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.31,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 26.58,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.55,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 26.58,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.83,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 63.14,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.79,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 49.21,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.76,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 52.89,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.88,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.65,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.29,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 59.89,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.17,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.2,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T02:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.28,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 31.15,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.73,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 31.54,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.43,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 52.39,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.04,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 30.9,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.22,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.9,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.2,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.38,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.12,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.89,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.97,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.43,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.34,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.69,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.16,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.83,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.78,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 23.0,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.54,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.15,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.67,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.03,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.37,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.89,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.45,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.26,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.49,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.94,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.46,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 9.86,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.77,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.44,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.39,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.67,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 27.81,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.77,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 25.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.39,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 29.63,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.43,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.94,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.01,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.07,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.9,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.64,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.19,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.74,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 9.31,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.02,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.65,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.68,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.48,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.99,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.28,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T01:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.08,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.28,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.75,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.64,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.67,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.52,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.19,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.19,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.13,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.81,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.59,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.28,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.19,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.82,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.89,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.25,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.37,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.98,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.14,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.48,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.49,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.0,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.48,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.43,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.2,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.34,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.16,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.17,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.05,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 26.67,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.01,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.74,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.03,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 47.64,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 2.92,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 302.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.8,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 38.29,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.59,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.51,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.5,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 25.84,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.5,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 25.37,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.35,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 29.44,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.88,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 23.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.88,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 4.54,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.17,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.54,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.84,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 9.01,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.51,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.73,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.91,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 21.24,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.03,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 9.1,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-26T00:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.06,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 8.18,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.39,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.98,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.48,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.41,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.28,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.39,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.93,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.35,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 27.43,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.31,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 30.14,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.22,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.35,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.98,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.1,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.25,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.87,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.56,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 21.36,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:38:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 22.04,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.07,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 25.58,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:34:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 24.9,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:32:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 23.91,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:30:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 22.05,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.02,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 23.97,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:26:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 23.27,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:24:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 22.32,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.99,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.63,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:20:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 23.58,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.97,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.44,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:16:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 22.13,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.94,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 21.81,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:12:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 20.95,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:10:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 19.65,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.92,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 23.21,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:06:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 20.78,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.89,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.41,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:02:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 23.52,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T23:00:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 21.17,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.86,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.31,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:56:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 19.01,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.83,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.22,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:52:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 18.65,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.81,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.95,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:48:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 20.6,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:46:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 19.65,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:44:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 16.85,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.78,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.37,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:40:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 19.65,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.75,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.99,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:36:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 17.74,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.73,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.72,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.03,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:30:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 17.68,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.7,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.42,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:26:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 16.09,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.67,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.81,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:22:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 13.92,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.65,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.36,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:18:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 16.72,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.62,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.64,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:14:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 16.65,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:12:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 14.85,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.6,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.96,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:08:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 14.38,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.57,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.39,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:04:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 14.25,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:02:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 15.09,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T22:00:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 11.28,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:58:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 10.49,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.52,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.83,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:54:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 13.54,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.49,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 8.47,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:50:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 13.24,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.47,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.79,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:46:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 10.2,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:44:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 9.03,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.44,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.69,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:40:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": {
          "value": 11.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.41,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.63,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.31,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.15,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.15,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 28.37,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.44,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.14,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.66,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.94,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.93,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.66,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.29,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 24.73,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.4,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.36,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.89,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.37,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.24,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.6,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.12,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 26.64,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.63,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 23.21,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.33,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.56,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 7.26,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.5,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 7.27,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.99,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 9.03,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.44,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.71,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.04,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.68,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.33,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.3,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.77,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T21:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.36,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 26.32,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.26,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.22,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.43,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.48,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.74,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.86,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.37,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 23.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.0,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.2,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.01,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.23,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.14,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.09,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.23,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.53,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.02,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.43,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.34,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.15,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.31,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.98,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 7.11,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.54,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 7.18,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.04,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.49,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.31,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.19,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.58,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.64,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.36,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.33,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.68,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 7.3,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.69,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.06,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.05,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.53,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.29,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.19,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.06,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.23,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.25,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.64,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.65,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.46,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.65,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 21.5,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.2,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.9,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.74,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.22,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.89,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.08,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.01,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.3,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T20:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.96,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.52,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.4,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.03,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.15,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 21.05,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.2,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.44,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.38,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 867.36,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 0.71,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 3188.59,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 0.81,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 3412.1,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.99,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.29,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.94,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 47.11,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.62,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 43.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.18,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 21.54,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.44,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.54,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.15,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.7,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.96,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.11,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.46,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.68,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.65,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.62,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.34,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 31.35,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.07,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 21.17,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.4,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.35,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.5,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.72,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 39.42,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.15,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 57.22,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.66,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 75.66,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.85,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.51,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.77,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.78,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.05,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.24,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.93,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.58,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.3,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.72,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.32,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.39,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.17,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.68,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T19:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.62,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.69,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.76,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.23,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.47,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.59,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.34,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.37,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.08,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.7,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.19,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.44,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.03,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 7.64,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.55,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.31,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 7.19,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.48,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 7.15,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 7.09,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.67,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.21,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.2,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.02,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.28,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.07,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.49,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.04,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.7,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.88,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.94,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 10.8,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.98,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.98,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.44,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.73,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 6.11,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.66,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.97,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.31,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.94,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 1558.03,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.25,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 1399.94,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.85,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 56.78,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.3,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 25.51,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.29,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 30.03,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.9,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 18.5,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.44,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 55.06,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.24,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 70.64,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.76,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 45.08,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.41,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 64.79,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T18:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.9,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 92.15,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.28,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 20.66,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.75,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.15,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.84,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 36.05,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.44,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 45.87,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.97,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 36.37,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:48:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.84,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 26.64,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:46:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.65,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.9,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:44:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.22,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 32.49,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:42:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.18,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 25.1,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:40:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 3.95,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 44.25,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:38:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.08,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 41.52,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:36:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.01,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 19.65,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:34:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.82,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.31,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:32:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.71,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.62,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:30:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.68,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.2,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:28:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.9,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.15,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:26:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.82,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.7,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:24:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.71,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.29,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:22:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.58,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 22.96,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:20:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.87,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 24.1,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:18:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.6,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:16:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.02,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.12,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:14:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.47,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 11.38,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:12:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.58,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 16.17,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:10:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.11,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.88,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:08:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.82,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 13.14,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:06:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.57,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.33,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:04:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.61,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 29.12,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:02:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.93,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 23.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T17:00:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.36,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 17.36,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T16:58:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.69,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.48,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T16:56:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 5.02,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 15.13,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T16:54:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.8,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 27.78,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T16:52:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.5,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 12.74,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T16:50:00+00:00",
      "data": {
        "g_k_gol_o2": {
          "value": 4.64,
          "type": "sensor",
          "status": "ok"
        },
        "g_k_gol_co": {
          "value": 14.65,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2024-09-25T16:48:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:46:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:44:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:42:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:40:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:38:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:36:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:34:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:32:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:30:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:28:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:26:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:24:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:22:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:20:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:18:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:16:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:14:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:12:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:10:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:08:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:06:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:04:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:02:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T16:00:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:58:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:56:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:54:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:52:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:50:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:48:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:46:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:44:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:42:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:40:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:38:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:36:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:34:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:32:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:30:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:28:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:26:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:24:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:22:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:20:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:18:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:16:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:14:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:12:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:10:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:08:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:06:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:04:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:02:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T15:00:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T14:58:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T14:56:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T14:54:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T14:52:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T14:50:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T14:48:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    },
    {
      "timestamp": "2024-09-25T14:46:00+00:00",
      "data": {
        "g_k_gol_o2": null,
        "g_k_gol_co": null
      }
    }
  ]
}
