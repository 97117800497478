import { useAuth0 } from '@auth0/auth0-react';
import opentelemetry from '@opentelemetry/api';
import { flatten } from 'flat';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useConfig from './Config/useConfig';
import { AuthEventPayload, customerAuthorisationEvent } from './observability/authorisation';

type Props = {
  children: React.ReactNode;
};

const HoneyCombTracker = ({ children }: Props) => {
  const { user } = useAuth0();
  const { data: config } = useConfig();
  const { pathname, search } = useLocation();

  useEffect(() => {
    const toPath = pathname + search;
    if (!toPath) return;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const attributes = flatten({ navigate: { toPath, pathname, search } }) as any;
    opentelemetry.trace.getTracer('ezer').startSpan('pageChange', { attributes }).end();
  }, [pathname, search]);

  useEffect(() => {
    const email = user?.email;
    const plantRef = config?.app.plantRef ?? '';
    if (!email || !plantRef) return;
    window.dispatchEvent(
      new CustomEvent<AuthEventPayload>(customerAuthorisationEvent, {
        detail: {
          user: email,
          plant: plantRef
        }
      })
    );
  }, [user, config]);

  return <>{children}</>;
};

export default HoneyCombTracker;
