import { createUseStyles } from 'react-jss';
import { LoadingMessage } from '../../../lib';
import ChartMessageContainer from './ChartMessageContainer';

const useStyles = createUseStyles({
  message: {
    justifyContent: 'center'
  }
});

const ChartLoadingMessage = () => {
  const styles = useStyles();
  return (
    <ChartMessageContainer>
      <LoadingMessage size="small" className={styles.message} />
    </ChartMessageContainer>
  );
};

export default ChartLoadingMessage;
