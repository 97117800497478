import React from 'react';
import { createUseStyles } from 'react-jss';
import Chart from './Chart/Chart';
import { WeeklyPerformanceData } from './utils/useWeeklyPerformanceData';
import { EzerTheme } from '../../EzerThemeProvider';
import { Options } from './schema';
import { LocalisedLabel } from '../../lib';

type Props = {
  options: Options;
  data: WeeklyPerformanceData;
  startDate: Date;
  endDate: Date;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  title: {
    textAlign: 'center',
    fontSize: spacing(4),
    marginTop: spacing(2)
  }
}));

const ChartContainer = ({ options, data, startDate, endDate }: Props) => {
  const styles = useStyles();
  const { chartTitle } = options;
  return (
    <>
      <h2 className={styles.title}>
        <LocalisedLabel>{chartTitle}</LocalisedLabel>
      </h2>
      <Chart options={options} data={data} startDate={startDate} endDate={endDate} />
    </>
  );
};

export default ChartContainer;
