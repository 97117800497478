// see https://date-fns.org/v4.1.0/docs/formatDistance

import { FormattedMessage } from 'react-intl';
import useNow from '../useNow';

type Props = {
  date: Date;
};

const ONE_SECOND = 1000;
const THIRTY_SECONDS = ONE_SECOND * 30;
const ONE_MINUTE = ONE_SECOND * 60;
const FIFTY_NINE_MINUTES = ONE_MINUTE * 59;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

const Age = ({ date }: Props) => {
  const now = useNow();
  const ageInMs = now.getTime() - date.getTime();
  if (ageInMs < ONE_SECOND * 30) {
    return <FormattedMessage defaultMessage="less than a minute ago" id="labels.lessThanAMinute" />;
  }
  if (ageInMs < ONE_MINUTE + THIRTY_SECONDS) {
    return <FormattedMessage defaultMessage="1 minute ago" id="labels.oneMinute" />;
  }
  if (ageInMs < ONE_MINUTE * 44 + THIRTY_SECONDS) {
    const minutes = Math.floor(ageInMs / ONE_MINUTE) + 1;
    return <FormattedMessage defaultMessage="{minutes} minutes ago" values={{ minutes }} id="labels.severalMinutes" />;
  }
  if (ageInMs < ONE_MINUTE * 89 + THIRTY_SECONDS) {
    return <FormattedMessage defaultMessage="about 1 hour ago" id="labels.1Hour" />;
  }
  if (ageInMs < ONE_HOUR * 23 + FIFTY_NINE_MINUTES + THIRTY_SECONDS) {
    const hours = Math.floor(ageInMs / ONE_HOUR) + 1;
    return <FormattedMessage defaultMessage="about {hours} hours ago" values={{ hours }} id="labels.severalHours" />;
  }
  if (ageInMs < ONE_HOUR * 41 + FIFTY_NINE_MINUTES + THIRTY_SECONDS) {
    return <FormattedMessage defaultMessage="1 day ago" id="labels.oneDay" />;
  }
  const days = Math.floor(ageInMs / ONE_DAY) + 1;
  return <FormattedMessage defaultMessage="{days} days ago" values={{ days }} id="labels.severalDays" />;
};

export default Age;
