{
  "item": {
    "g_k_gol_nox": {
      "value": 633.2699787307746,
      "type": "sensor",
      "status": "ok",
      "unit": "ppm",
      "timestamp": "2024-09-26T14:30:00+00:00"
    }
  }
}
