import type { PlantData } from './useHistoricalPlantData';
import { FeatureName, Recommendation } from '../types';

const convertPlantDataToRecs = (plantData: PlantData, featureName: FeatureName): Recommendation[] => {
  const { items = [] } = plantData;
  return items.map((item) => ({
    timestamp: new Date(item.timestamp),
    feature: featureName,
    value: item.data[featureName]?.value ?? 0,
    error: null
  }));
};

export default convertPlantDataToRecs;
