import { FormattedMessage } from 'react-intl';
import React from 'react';

type Props = {
  featureTitle: string;
};

export const DESCRIPTOR = {
  defaultMessage: 'Time in Ok {featureTitle} state',
  id: 'page.HistoricalPerformance.timeInOk'
};

const TimeInOk = ({ featureTitle }: Props) => (
  <FormattedMessage defaultMessage={DESCRIPTOR.defaultMessage} id={DESCRIPTOR.id} values={{ featureTitle }} />
);

export default TimeInOk;
