import { KpiData } from './useKpiData';
import { round } from '../../utils';

const useFreeLimeTicks = (
  data: KpiData,
  showBoundTicks: boolean,
  tickCount: number,
  rangeStart: number,
  rangeEnd: number
): number[] => {
  const highestFreeLimeValue = data.periods.reduce((value, period) => {
    const { sensorData = 0 } = period;
    return sensorData !== null && sensorData > value ? sensorData : value;
  }, 0);
  const maxValue = Math.max(highestFreeLimeValue, rangeEnd);

  const freeLimeTicks: number[] = [];
  const stepSize = round(maxValue / (tickCount - 1), 2);

  for (let i = rangeStart; i < tickCount; i++) {
    const newTick = round(i * stepSize, 2);
    if (showBoundTicks && !freeLimeTicks.includes(data.lowerBound) && newTick > data.lowerBound) {
      freeLimeTicks.push(data.lowerBound);
    }
    if (showBoundTicks && !freeLimeTicks.includes(data.upperBound) && newTick > data.upperBound) {
      freeLimeTicks.push(data.upperBound);
    }
    freeLimeTicks.push(newTick);
  }
  return freeLimeTicks;
};

export default useFreeLimeTicks;
