import React from 'react';
import type { Payload as LegendPayload } from 'recharts/types/component/DefaultLegendContent';
import { useEzerTheme } from '../../../../EzerThemeProvider';
import { GasAnalyserChartConfig } from '../../schema';
import { LocalisedLabel } from '../../../../lib';

const useLegendPayload = (config: GasAnalyserChartConfig, leftUnit: string, rightUnit: string): LegendPayload[] => {
  const { palette } = useEzerTheme();
  return [
    {
      value: (
        <>
          <LocalisedLabel>{config.leftFeatureConfig.feature.label}</LocalisedLabel> ({leftUnit})
        </>
      ),
      type: 'line',
      color: palette.leaf
    },
    {
      value: (
        <>
          <LocalisedLabel>{config.rightFeatureConfig.feature.label}</LocalisedLabel> ({rightUnit})
        </>
      ),
      type: 'line',
      color: palette.white
    },
    {
      value: <LocalisedLabel>{config.performanceFeatureConfig.highLabel}</LocalisedLabel>,
      type: 'square',
      color: palette.glow
    },
    {
      value: <LocalisedLabel>{config.performanceFeatureConfig.lowLabel}</LocalisedLabel>,
      type: 'square',
      color: palette.lava
    }
  ];
};
export default useLegendPayload;
