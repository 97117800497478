import React from 'react';
import { WeeklyPerformanceData } from '../utils/useWeeklyPerformanceData';
import useColumns from './useColumns';
import useRows from './useRows';
import { DataTable } from '../../../lib';

type Props = {
  data: WeeklyPerformanceData;
  featureName: string;
  fileName: string;
};

const Table = ({ data, featureName, fileName }: Props) => {
  const columns = useColumns(featureName);
  const rows = useRows(data);

  return (
    <div data-testid="historical-weekly-performance-table">
      <DataTable columns={columns} data={rows} fileName={fileName} />
    </div>
  );
};

export default Table;
