import { Recommendation } from '../../../types';

type Payload = {
  value: number;
  date: number;
};

type ChartData = Payload[];
const useRecommendationsChartData = (recommendationData: Recommendation[]): ChartData =>
  recommendationData.map((recommendation) => ({
    value: recommendation.value,
    date: recommendation.timestamp.getTime()
  }));

export default useRecommendationsChartData;
