import { createSearchParams, generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { useUserPermissions } from '../utils';
import Menu from './Menu/Menu';
import MenuButton from './Menu/MenuButton';

const CustomerMenu = () => {
  const { data: permissions } = useUserPermissions();
  const plants = permissions?.plants || [];
  const [searchParams] = useSearchParams();
  if (searchParams.get('locale')) {
    searchParams.delete('locale');
  }
  const customerParam = searchParams.get('customer');
  const customerName = customerParam ? plants.find((p) => p.plantRef === customerParam)?.name : plants[0].name;
  const navigate = useNavigate();
  const onSelectCustomer = (id: string) => {
    searchParams.set('customer', id);
    const path = generatePath(':url?:queryString', {
      url: '/',
      queryString: createSearchParams(searchParams).toString()
    });
    navigate(path);
  };

  const items = plants.map((p) => <MenuButton onClick={() => onSelectCustomer(p.plantRef)}>{p.name}</MenuButton>);

  return <Menu items={items}>{customerName}</Menu>;
};

export default CustomerMenu;
