import { SoftSensor } from '../../../utils/useHistoricalSoftSensors';
import Chart from './Chart';
import { ChartConfig } from '../schema';
import useChartData from './useChartData';
import { FeatureName } from '../../../types';

type Props = {
  data: SoftSensor[];
  startDate: Date;
  endDate: Date;
  config: ChartConfig;
};
const ChartContainer = ({ data, startDate, endDate, config }: Props) => {
  const { yAxisLabel, xAxisLabel, rangeStart, rangeEnd, featureName } = config;

  const chartData = useChartData(data, featureName as FeatureName);

  return (
    <Chart
      data={chartData}
      startDate={startDate}
      endDate={endDate}
      yAxisLabel={yAxisLabel}
      xAxisLabel={xAxisLabel}
      rangeStart={rangeStart}
      rangeEnd={rangeEnd}
    />
  );
};

export default ChartContainer;
