import { subHours } from 'date-fns';
import useHistoricalRecommendations from './useHistoricalRecommendations';
import { FeatureName, Recommendation } from '../types';
import useConfig from '../Config/useConfig';

type Response = {
  data: Recommendation[];
  isLoading: boolean;
  isError: boolean;
};

const useHistoricalTargets = (
  featureNames: FeatureName[],
  startDate: Date,
  endDate: Date,
  refetch = false
): Response => {
  const { data: config, isLoading: isConfigLoading, isError: isConfigError } = useConfig();

  const featureConfigs = config?.app.kpiFeatureConfigs ?? [];
  const targetFeatureNames = featureConfigs.reduce((acc: FeatureName[], featureConfig) => {
    if (featureNames.includes(featureConfig.name) && featureConfig?.target) {
      acc.push(featureConfig.target);
    }
    return acc;
  }, []);

  const {
    data,
    isLoading: isRecommendationsLoading,
    isError: isRecommendationsError
  } = useHistoricalRecommendations(targetFeatureNames, subHours(startDate, 24), endDate, refetch);

  return {
    data,
    isLoading: isConfigLoading || isRecommendationsLoading,
    isError: isConfigError || isRecommendationsError
  };
};

export default useHistoricalTargets;
