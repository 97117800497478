import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../../EzerThemeProvider';
import { New } from '../../../../messages';

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    display: 'inline-block',
    textTransform: 'uppercase',
    backgroundColor: palette.flame,
    marginLeft: spacing(0.5),
    padding: [spacing(0.25), spacing(1)]
  }
}));
const NewNotification = () => {
  const styles = useStyles();
  return (
    <p className={styles.root}>
      <New />
    </p>
  );
};

export default NewNotification;
