import { createUseStyles } from 'react-jss';
import React, { ReactNode, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ToggleButton from './ToggleButton';
import { EXCLUSIVE_PARAM_KEY, INCLUSIVE_PARAM_KEY } from './contants';

export type TogglesData = { value: string; label: ReactNode; exclusive?: boolean }[];

type Props = {
  data: TogglesData;
  disabled?: boolean;
};

const useStyles = createUseStyles({
  root: {
    display: 'flex'
  }
});
const Toggles = ({ data, disabled = false }: Props) => {
  const styles = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  // set all inclusive toggles as selected

  const inclusiveData = data.filter((datum) => !datum.exclusive);
  const inclusiveSearchParams = searchParams.get(INCLUSIVE_PARAM_KEY);
  const isInclusiveParamSet = !!inclusiveSearchParams;
  const exclusiveData = data.filter((datum) => datum.exclusive);
  const exclusiveSearchParams = searchParams.get(EXCLUSIVE_PARAM_KEY);
  const isExclusiveParamSet = !!exclusiveSearchParams;
  const isAnyParamSet = isInclusiveParamSet || isExclusiveParamSet;

  useEffect(() => {
    if (!isAnyParamSet && inclusiveData.length) {
      const defaultValue = inclusiveData.map((d) => d.value).join(',');
      searchParams.set(INCLUSIVE_PARAM_KEY, defaultValue);

      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, isAnyParamSet, inclusiveData]);

  // set the first exclusive button as selected

  useEffect(() => {
    if (!isAnyParamSet && exclusiveData.length) {
      const defaultValue = exclusiveData[0].value;
      searchParams.set(EXCLUSIVE_PARAM_KEY, defaultValue);

      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, isAnyParamSet, exclusiveData]);

  return (
    <ul className={styles.root}>
      {inclusiveData.map((dataItem, index) => (
        <li key={dataItem.value}>
          <ToggleButton
            value={dataItem.value}
            isFirst={index === 0}
            isLast={index === exclusiveData.length - 1}
            disabled={disabled || !isAnyParamSet}>
            {dataItem.label}
          </ToggleButton>
        </li>
      ))}
      {exclusiveData.map((dataItem, index) => (
        <li key={dataItem.value}>
          <ToggleButton
            value={dataItem.value}
            disabled={disabled || !isAnyParamSet}
            exclusive
            isFirst={index === 0}
            isLast={index === exclusiveData.length - 1}>
            {dataItem.label}
          </ToggleButton>
        </li>
      ))}
    </ul>
  );
};
export default Toggles;
