import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { createUseStyles } from 'react-jss';
import { ChartData } from './useChartData';
import { Label } from '../../../types';
import { EzerTheme, useEzerTheme } from '../../../EzerThemeProvider';
import { useTicksByDate, getLocalDate, useTimezone } from '../../../utils';
import useYTicks from './useYTicks';
import { LabelledContainer } from '../../../lib';
import CartesianGrid from '../../../lib/CartesianGrid';
import TooltipContent from './TooltipContent';

type Props = {
  data: ChartData;
  startDate: Date;
  endDate: Date;
  yAxisLabel: Label;
  xAxisLabel: Label;
  rangeStart?: number;
  rangeEnd?: number;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    marginTop: spacing(2)
  },
  container: {
    position: 'relative'
  }
}));

const Chart = ({ data, startDate, endDate, yAxisLabel, xAxisLabel, rangeStart, rangeEnd }: Props) => {
  const styles = useStyles();
  const { palette, spacing } = useEzerTheme();
  const xTicks = useTicksByDate(startDate, endDate);
  const yTicks = useYTicks(rangeStart, rangeEnd);
  const showDecimalPoints = rangeEnd ? rangeEnd <= 1000 : true;
  const timezone = useTimezone();
  const domain = yTicks && yTicks.length > 0 ? [yTicks[0], yTicks[yTicks.length - 1]] : undefined;
  const unit = '%';
  return (
    <LabelledContainer yAxisLabel={yAxisLabel} xAxisLabel={xAxisLabel} unit={unit}>
      <ResponsiveContainer width="100%" height={spacing(58)} className={styles.root}>
        <LineChart margin={{ top: spacing(1), right: 0, bottom: 0, left: 0 }} data={data}>
          <CartesianGrid />
          <Line
            type="monotone"
            dataKey="value"
            stroke={palette.leaf}
            strokeWidth={2}
            isAnimationActive={false}
            dot={false}
          />
          <XAxis
            dataKey="date"
            type="number"
            domain={[startDate.getTime(), endDate.getTime()]}
            ticks={xTicks}
            tickFormatter={(tick: string) => getLocalDate(new Date(tick), timezone)}
            allowDataOverflow
            height={spacing(4)}
          />
          <YAxis
            width={spacing(6)}
            dataKey="measured"
            ticks={yTicks}
            domain={domain}
            allowDataOverflow
            tickFormatter={(tick: number) => tick.toFixed(showDecimalPoints ? 1 : 0)}
          />
          {data.length > 0 && <Tooltip content={<TooltipContent unit={unit} />} />}
        </LineChart>
      </ResponsiveContainer>
    </LabelledContainer>
  );
};

export default Chart;
