import React from 'react';
import { NavLink } from 'react-router-dom';
import { usePath } from '../utils';
import { useStyles } from './IconLink';

type Props = {
  path: string;
  children: React.ReactNode;
};

const ConfigNavLink = ({ path, children }: Props) => {
  const to = usePath(path);
  const styles = useStyles();
  return (
    <NavLink to={to} className={styles.root}>
      {children}
    </NavLink>
  );
};

export default ConfigNavLink;
