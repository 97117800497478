import { FormattedMessage } from 'react-intl';
import React from 'react';

export const DESCRIPTOR = {
  defaultMessage: 'Time with no data',
  id: 'page.HistoricalPerformance.timeWithNoData'
};

const TimeWithNoData = () => <FormattedMessage defaultMessage={DESCRIPTOR.defaultMessage} id={DESCRIPTOR.id} />;

export default TimeWithNoData;
