import { subDays } from 'date-fns';
import { TemplateSchema } from './schema';
import { LoadingMessage, LocalisedLabel, PageTitle } from '../../lib';
import FilterControls from '../../FilterControls/FilterControls';
import useToday from '../../useToday';
import useStartDate from '../../FilterControls/useStartDate';
import useEndDate from '../../FilterControls/useEndDate';
import { useFileName, useHistoricalPlantData } from '../../utils';
import Chart from './Chart/Chart';
import Table from './Table';

const TimelineTemplatePage = ({ options, label }: TemplateSchema) => {
  const today = useToday();
  const startDate = useStartDate(subDays(today, 30));
  const endDate = useEndDate(today);
  const { features } = options;
  const selectData = features.map(({ featureName, title: featureTitle }) => ({
    value: featureName as string,
    label: <>{featureTitle}</>
  }));
  const featureNames = features.map(({ featureName }) => featureName);
  const { data, isLoading } = useHistoricalPlantData(featureNames, startDate, endDate);
  const fileName = useFileName(label, startDate, endDate);
  return (
    <div data-testid="timeline-template-page">
      <PageTitle>
        <LocalisedLabel>{label}</LocalisedLabel>
      </PageTitle>
      <FilterControls selectData={selectData} isLoading={isLoading} />
      {isLoading && <LoadingMessage />}
      {!isLoading && startDate && endDate && (
        <>
          <Chart data={data} features={features} startDate={startDate} endDate={endDate} />
          <Table data={data} features={features} fileName={fileName} />
        </>
      )}
    </div>
  );
};
export default TimelineTemplatePage;
