import React from 'react';
import LoadingPage from '../LoadingPage';
import useBrowserLocale from './useBrowserLocale';

const UnauthorisedPage = () => {
  const browserLocale = useBrowserLocale();
  return (
    <LoadingPage>
      {browserLocale === 'en' && <span>Unauthorised</span>}
      {browserLocale === 'es' && <span>No Autorizado</span>}
      {browserLocale === 'pt-br' && <span>Não autorizado</span>}
      {browserLocale === 'pt-pt' && <span>Não autorizado</span>}
    </LoadingPage>
  );
};
export default UnauthorisedPage;
