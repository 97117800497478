import { DataGrid, GridColumns, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import React from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../EzerThemeProvider';
import Number from './Number';

type Props = {
  // need to specify any prop, as this is the one required by DataGrid
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  columns: GridColumns;
  fileName?: string;
  columnBuffer?: number | undefined;
  rowBuffer?: number | undefined;
};

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    marginTop: spacing(2)
  },
  dataGrid: {
    backgroundColor: palette.white
  }
}));

const COLUMNS_DESCRIPTOR = defineMessage({
  id: 'labels.columns',
  defaultMessage: 'Columns'
});
const FILTERS_DESCRIPTOR = defineMessage({
  id: 'labels.filters',
  defaultMessage: 'FILTERS'
});
const DENSITY_DESCRIPTOR = defineMessage({
  id: 'labels.density',
  defaultMessage: 'DENSITY'
});
const EXPORT_DESCRIPTOR = defineMessage({
  id: 'labels.export',
  defaultMessage: 'Export'
});

const FROM_TO_OF_COUNT_DESCRIPTOR = defineMessage({
  id: 'labels.fromToOfCount',
  defaultMessage: '{from} - {to} of {count}'
});

const DataTable = ({ data, columns, fileName = 'data', columnBuffer, rowBuffer }: Props) => {
  const styles = useStyles();
  const intl = useIntl();

  const formattedColumns = columns.map((column) => ({
    ...column,
    renderCell: (params: GridRenderCellParams) =>
      typeof params.value === 'number' ? <Number>{params.value}</Number> : params.value
  }));

  const csvOptions = {
    fileName
  };
  return (
    <Box sx={{ height: 400, width: '100%' }} className={styles.root}>
      <DataGrid
        className={styles.dataGrid}
        rows={data}
        getRowId={(row) => row.timestamp || row.id}
        columns={formattedColumns}
        columnBuffer={columnBuffer || 3}
        rowBuffer={rowBuffer || 3}
        density="compact"
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{ toolbar: { csvOptions } }}
        localeText={{
          toolbarColumns: intl.formatMessage(COLUMNS_DESCRIPTOR),
          toolbarFilters: intl.formatMessage(FILTERS_DESCRIPTOR),
          toolbarDensity: intl.formatMessage(DENSITY_DESCRIPTOR),
          toolbarExport: intl.formatMessage(EXPORT_DESCRIPTOR),
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              intl.formatMessage(FROM_TO_OF_COUNT_DESCRIPTOR, { from, to, count })
          }
        }}
      />
    </Box>
  );
};

export default DataTable;
