import { FormattedMessage } from 'react-intl';
import { TooltipProps } from 'recharts';
import { getLocalDateAndTime, useTimezone } from '../../utils';
import ChartTooltip from '../ChartTooltip';
import useItemsListData, { ItemsListDataPayload } from './useItemsListData';

interface Props extends TooltipProps<string, string> {
  unit: string;
}

type TooltipLabelProps = {
  label: number;
};

const TooltipLabel = ({ label }: TooltipLabelProps) => {
  const timezone = useTimezone();
  const convertToTime = (timestamp: number) => (timestamp ? getLocalDateAndTime(new Date(timestamp), timezone) : label);
  return (
    <FormattedMessage
      defaultMessage="Date: {label}"
      id="chart.quality.tooltip"
      values={{ label: convertToTime(label) }}
    />
  );
};

const QualityTooltipContent = ({ label, payload, unit }: Props) => {
  // check that payload is not null or undefined
  const itemsPayload = payload && payload?.length >= 0 ? payload : [];
  const itemListData = useItemsListData(itemsPayload as ItemsListDataPayload, unit);
  return <ChartTooltip label={<TooltipLabel label={label} />} itemListData={itemListData} />;
};

export default QualityTooltipContent;
