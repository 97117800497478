import { FeatureName } from '../types';
import convertPeriodToTime from './convertPeriodToTime';
import useKpiByWeekQuery from '../serviceQueries/useKpiByWeekQuery';

type Period = {
  date: number;
  averageValue: number;
  baseline?: number;
};

export type ChartData = {
  periods: Period[];
};

export type KpiByWeekData = {
  data?: ChartData;
  isLoading: boolean;
  isError: boolean;
};

// eslint-disable-next-line
const generateChartData = (data: any): ChartData => {
  return {
    // eslint-disable-next-line
    periods: data.periods.map((periodData: any) => ({
      date: convertPeriodToTime(periodData.period),
      averageValue: periodData.average_value,
      baseline: data.baselines?.[0].value
    }))
  };
};
const useKpiByWeekData = (featureName: FeatureName, startDate: Date, endDate: Date): KpiByWeekData => {
  const { data, isLoading, isError } = useKpiByWeekQuery(featureName, startDate, endDate);
  return {
    data: !isLoading && !isError ? generateChartData(data) : undefined,
    isLoading,
    isError
  };
};

export default useKpiByWeekData;
