import { GA4React } from 'ga-4-react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import AuthProvider from './AuthProvider';
import worker from './mocks/browser';
import './observability/instrumentation';
import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_ENV === 'mock') {
  worker.start();
}

const ga4react = new GA4React(process.env.REACT_APP_GA_CODE ?? '');

const root = createRoot(document.getElementById('root') as HTMLElement);

(async () => {
  await ga4react
    .initialize()
    // eslint-disable-next-line no-console
    .then(() => console.log('Google Analytics Initialised'))
    // eslint-disable-next-line no-console
    .catch(() => console.log('Google Analytics Initialisation Failed'))
    .finally(() => {
      root.render(
        <React.StrictMode>
          <AuthProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AuthProvider>
        </React.StrictMode>
      );
    });
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
