import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { EzerTheme } from '../EzerThemeProvider';
import { DataCouldNotBeLoaded } from '../messages';

type Props = {
  className?: string;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    paddingTop: spacing(2),
    display: 'flex',
    justifyContent: 'center',
    fontSize: spacing(3)
  }
}));

const DataLoadErrorMessage = ({ className }: Props) => {
  const styles = useStyles();
  return (
    <p className={classNames(styles.root, className)}>
      <DataCouldNotBeLoaded />
    </p>
  );
};
export default DataLoadErrorMessage;
