import { createUseStyles } from 'react-jss';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { EzerTheme, useEzerTheme } from '../../../EzerThemeProvider';
import { AxisFrame, CartesianGrid } from '../../../lib';
import { Recommendation } from '../../../types';
import { getLocalDate, useTicksByDate, useTimezone } from '../../../utils';
import type { Feature } from '../schema';
import TooltipContent from './TooltipContent';
import useHistoricalChartData from './useHistoricalChartData';
import useRecommendationsChartData from './useRecommendationsChartData';
import { Historical, Recommended } from '../../../messages';

type Props = {
  feature: Feature;
  recommendationData: Recommendation[];
  startDate: Date;
  endDate: Date;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    marginTop: spacing(2)
  }
}));

type MessageProps = {
  unit: string;
};
const HistoricalMessage = ({ unit }: MessageProps) => (
  <>
    <Historical /> ({unit})
  </>
);
const RecommendedMessage = ({ unit }: MessageProps) => (
  <>
    <Recommended /> ({unit})
  </>
);

const Chart = ({ feature, recommendationData, startDate, endDate }: Props) => {
  const { palette, spacing } = useEzerTheme();
  const styles = useStyles();
  const ticks = useTicksByDate(startDate, endDate);
  const { title, recommendedFeatureName, rangeStart = 'auto', rangeEnd = 'auto' } = feature;
  const recommendationsChartData = useRecommendationsChartData(recommendationData);
  const { items: historicalChartData, unit } = useHistoricalChartData(recommendedFeatureName, startDate, endDate);
  const renderTicksAtAngle = ticks.length > 15;
  const timezone = useTimezone();

  return (
    <>
      {recommendationsChartData.length > 0 && (
        <AxisFrame yAxisLabel={title.default} dataTestId="historical-recommendations-chart" unit={unit}>
          <ResponsiveContainer width="100%" height={spacing(58)} className={styles.root}>
            <LineChart>
              <Legend
                verticalAlign="top"
                height={spacing(5)}
                payload={[
                  {
                    value: <HistoricalMessage unit={unit} />,
                    type: 'line',
                    color: palette.white
                  },
                  {
                    value: <RecommendedMessage unit={unit} />,
                    type: 'line',
                    color: palette.leaf
                  }
                ]}
              />
              <CartesianGrid />
              <Line
                type="stepBefore"
                data={historicalChartData}
                dataKey="value"
                stroke={palette.white}
                strokeWidth={2}
                dot={false}
              />
              <Line
                type="stepBefore"
                data={recommendationsChartData}
                dataKey="value"
                stroke={palette.leaf}
                strokeWidth={2}
                dot={false}
              />
              <XAxis
                dataKey="date"
                ticks={ticks}
                type="number"
                angle={renderTicksAtAngle ? -45 : 0}
                height={renderTicksAtAngle ? spacing(8) : spacing(2)}
                textAnchor={renderTicksAtAngle ? 'end' : 'middle'}
                tickFormatter={(tick: string) => getLocalDate(new Date(tick), timezone)}
                domain={[startDate.getTime(), endDate.getTime()]}
                allowDataOverflow
                allowDuplicatedCategory={false}
                interval={0}
              />
              <YAxis domain={[rangeStart, rangeEnd]} allowDataOverflow />
              <Tooltip content={<TooltipContent unit={unit} />} />
            </LineChart>
          </ResponsiveContainer>
        </AxisFrame>
      )}
    </>
  );
};

export default Chart;
