import { Payload } from '../../types/QualityChartData';
import { round } from '../../utils';
import QualityChartLabel from '../HistoricalQualityChart/QualityChartLabel';

export type ItemsListDataPayload = {
  value: string;
  color: string;
  name: keyof Payload;
}[];

const useItemsListData = (payload: ItemsListDataPayload, unit: string) =>
  [...payload].reverse().map(({ value, color, name }) => ({
    label: (
      <>
        <QualityChartLabel>{name}</QualityChartLabel>: {round(Number(value))} {unit}
      </>
    ),
    color
  }));

export default useItemsListData;
