import { getUtcDate } from '../utils';
import useAuthorisedQuery from './useAuthorisedQuery';
import { Api } from '../settings';

const ONE_MINUTE = 60000;
const usePlantStatusQuery = (startDate: Date, endDate: Date) => {
  const startDateValue = getUtcDate(startDate);
  const endDateValue = getUtcDate(endDate);
  return useAuthorisedQuery(
    ['plant-status'],
    `plant-status/?start_datetime=${startDateValue}&end_datetime=${endDateValue}`,
    {
      refetchInterval: ONE_MINUTE
    },
    Api.serve
  );
};

export default usePlantStatusQuery;
