import React, { Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { SimplePage } from '../lib';
import PageContainer from '../PageContainer';

interface Props {
  children: ReactNode;
  onError: () => void;
}

interface State {
  hasError: boolean;
}

const SomethingWentWrong = () => (
  <FormattedMessage id="labels.somethingWentWrong" defaultMessage="Something went wrong." />
);

const ContentProblemDescription = () => (
  <FormattedMessage id="labels.contentProblem" defaultMessage="Sorry, there seems to be a problem with this content." />
);

class ErrorBoundary extends Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  public state: State = {
    hasError: false
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // This render() displays the desired error text to the user, but then
  // the entire website is broken afterwards. Merging anyway as still an
  // improvement.
  public render() {
    const { hasError } = this.state;
    const { children, onError } = this.props;
    if (hasError) {
      onError();
      return (
        <PageContainer>
          <SimplePage title={<SomethingWentWrong />} description={<ContentProblemDescription />} />
        </PageContainer>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
