{
  "permissions": {
    "can_edit_config": true,
    "plants": [
      {
        "plant_ref": "adr",
        "name": "Adrian\u00f3polis"
      }
    ]
  }
}
