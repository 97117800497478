import React, { useEffect, useMemo, useState } from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import useConfig from './Config/useConfig';
import { LOCALE_FILE_BASE_URL } from './settings';
import { type Locale } from './useCustomer';
import useFeatureFlags from './utils/useFeatureFlags';

type Props = {
  children: React.ReactNode;
};

export type Messages = Partial<Record<string, string>>;

const requestLocaleFile = async (url: string) => {
  let result = {};
  const response = await fetch(url, { cache: 'no-cache' });
  if (response.ok) {
    try {
      result = await response.json();
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.error(`Cannot parse json from ${url}`);
    }
  }
  return result;
};

const getMessages = async (locale: Locale, customerCode: string) => {
  const sharedMessages = await requestLocaleFile(`${LOCALE_FILE_BASE_URL}/shared/${locale}.json`);
  const customerDefaults = await requestLocaleFile(`${LOCALE_FILE_BASE_URL}/${customerCode}/en.json`);
  const customerMessages = await requestLocaleFile(`${LOCALE_FILE_BASE_URL}/${customerCode}/${locale}.json`);
  return {
    ...sharedMessages,
    ...customerDefaults,
    ...customerMessages
  };
};

export const useMessages = (): Messages => {
  const { messages } = useIntl();
  return messages as Messages;
};

const LocalisationProvider = ({ children }: Props) => {
  const [locale, setLocale] = useState('en');
  const [messages, setMessages] = useState({});
  const featureFlags = useFeatureFlags();
  const disableLocalisation = featureFlags.includes('disableLocalisation');

  const { data: config } = useConfig();
  const plantRef = useMemo(() => config?.app.plantRef || '', [config]);
  const languages = useMemo(() => config?.app.languages || [], [config]);

  // check if locale is defined in local storage, use it if it is

  useEffect(() => {
    const storedValue = localStorage.getItem(`locale-${plantRef}`);
    if (storedValue) {
      setLocale(storedValue);
    }
  }, [plantRef]);

  // use the first language value defined on the customer if the locale is not set in local storage

  useEffect(() => {
    if (languages.length > 0 && !localStorage.getItem(`locale-${plantRef}`)) {
      setLocale(languages[0]);
    }
  }, [languages, plantRef]);

  // if the value changes on the url, set that as the locale, and store it local storage

  const [searchParams] = useSearchParams();

  const searchParamValue = searchParams.get('locale');

  useEffect(() => {
    if (searchParamValue && searchParamValue !== locale && locale) {
      setLocale(searchParamValue as Locale);
      // we only store the value in local storage if explicitly on the url
      localStorage.setItem(`locale-${plantRef}`, searchParamValue);
    }
  }, [locale, plantRef, searchParamValue]);

  // check that the locale is valid

  useEffect(() => {
    if (languages.length > 1 && !languages.includes(locale as Locale)) {
      setLocale(languages[0]);
    }
  }, [locale, languages]);

  useEffect(() => {
    async function loadLanguageBundles() {
      if (disableLocalisation) {
        setMessages({});
      } else {
        const lang = await getMessages(locale as Locale, plantRef);
        setMessages(lang);
      }
    }

    if (plantRef) {
      loadLanguageBundles();
    }
  }, [locale, plantRef, disableLocalisation]);

  return (
    <IntlProvider messages={messages} locale={locale} defaultLocale={locale}>
      {children}
    </IntlProvider>
  );
};

export default LocalisationProvider;
