import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../../EzerThemeProvider';

type Props = {
  className: string;
};

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    backgroundColor: palette.rich,
    borderTopLeftRadius: spacing(0.5),
    borderBottomLeftRadius: spacing(0.5),
    paddingBottom: spacing(0.5),
    overflow: 'hidden'
  },
  label: {
    color: palette.white,
    textAlign: 'center',
    fontSize: spacing(1.75),
    marginTop: spacing(0.5)
  }
}));

const BoundLabels = ({ className }: Props) => {
  const styles = useStyles();
  return (
    <ul className={classNames(styles.root, className)}>
      <li className={styles.label}>
        <FormattedMessage defaultMessage="Max" id="page.today.alarm.max" />
      </li>
      <li className={styles.label}>
        <FormattedMessage defaultMessage="High" id="page.today.alarm.high" />
      </li>
      <li className={styles.label}>
        <FormattedMessage defaultMessage="Low" id="page.today.alarm.low" />
      </li>
      <li className={styles.label}>
        <FormattedMessage defaultMessage="Min" id="page.today.alarm.min" />
      </li>
    </ul>
  );
};

export default BoundLabels;
