import { FormattedMessage } from 'react-intl';
import React from 'react';

type Props = {
  featureTitle: string;
};

export const DESCRIPTOR = {
  defaultMessage: 'Av. Duration High {featureTitle}',
  id: 'page.HistoricalPerformance.highAverageDuration'
};

const AverageDurationHigh = ({ featureTitle }: Props) => (
  <FormattedMessage defaultMessage={DESCRIPTOR.defaultMessage} id={DESCRIPTOR.id} values={{ featureTitle }} />
);

export default AverageDurationHigh;
