const JUMBO_EMAILS = [
  'will+multi_jumbo@carbonre.com',
  'rory+mok_jumbo@carbonre.com',
  'rory+cui_jumbo@carbonre.com',
  'rory+nie_jumbo@carbonre.com',
  'rory+can_jumbo@carbonre.com'
];

const isRealUser = (email: string) => {
  if (JUMBO_EMAILS.includes(email)) {
    return true;
  }
  if (!email.includes('@carbonre.com') && email !== '') {
    return true;
  }
  return false;
};

export default isRealUser;
