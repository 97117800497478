import { Api } from '../settings';
import { getUtcDate } from '../utils';
import useAuthorisedQuery from './useAuthorisedQuery';

const useDataStatusQuery = (startDate: Date, endDate: Date) => {
  const startDateValue = getUtcDate(startDate);
  const endDateValue = getUtcDate(endDate);
  return useAuthorisedQuery(
    ['data-status'],
    `data-status/?start_datetime=${startDateValue}&end_datetime=${endDateValue}`,
    {},
    Api.serve
  );
};

export default useDataStatusQuery;
