/* eslint-disable @typescript-eslint/no-explicit-any */
import { FeatureName } from '../../../types';
import useWeeklyPerformanceQuery from '../../../serviceQueries/useWeeklyPerformanceQuery';
import { convertPeriodToTime } from '../../../utils';

type Period = {
  date: number;
  highPercentageTime: number;
  lowPercentageTime: number;
  okPercentageTime: number;
  noDataPercentageTime: number;
  highPeriodCount: number;
  lowPeriodCount: number;
  averageHighPeriodDuration: number;
  averageLowPeriodDuration: number;
};

export type WeeklyPerformanceData = {
  periods: Period[];
  totalLowPercentageTime: number;
  totalHighPercentageTime: number;
  totalOkPercentageTime: number;
  averageBadDuration: number;
};

type Response = {
  data: WeeklyPerformanceData;
  isLoading: boolean;
  isError: boolean;
};

const generatePeriods = (data: any[]): Period[] =>
  data.map((dataItem) => {
    const highPercentageTime = dataItem.high_percentage_time || 0;
    const lowPercentageTime = dataItem.low_percentage_time || 0;
    const okPercentageTime = dataItem.ok_percentage_time || 0;
    const totalTime = highPercentageTime + lowPercentageTime + okPercentageTime;
    return {
      date: convertPeriodToTime(dataItem.period),
      highPercentageTime,
      lowPercentageTime,
      okPercentageTime,
      noDataPercentageTime: totalTime < 100 ? 100 - totalTime : 0,
      highPeriodCount: dataItem.high_periods || 0,
      lowPeriodCount: dataItem.low_periods || 0,
      averageHighPeriodDuration: dataItem.average_high_duration || 0,
      averageLowPeriodDuration: dataItem.average_low_duration || 0
    };
  });

const useWeeklyPerformanceData = (featureName: FeatureName, startDate: Date, endDate: Date): Response => {
  const { data, isLoading, isError } = useWeeklyPerformanceQuery(featureName, startDate, endDate);
  return {
    data: {
      periods: isLoading ? [] : generatePeriods(data?.periods ?? []),
      totalHighPercentageTime: data?.total_high_percentage_time,
      totalLowPercentageTime: data?.total_low_percentage_time,
      totalOkPercentageTime: data?.total_ok_percentage_time,
      averageBadDuration: data?.average_bad_duration
    },
    isLoading,
    isError
  };
};

export default useWeeklyPerformanceData;
