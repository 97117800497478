import { DiffEditor, MonacoDiffEditor, useMonaco } from '@monaco-editor/react';
import { useQueryClient } from 'react-query';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Ajv from 'ajv';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../EzerThemeProvider';
import useConfigMutation from '../serviceQueries/useConfigMutation';
import useConfigQuery, { usePath } from '../serviceQueries/useConfigQuery';
import { configJsonSchema, ConfigType } from './ConfigSchema';
import { displayVersion } from './utils';
import { useCacheKey } from '../serviceQueries/useAuthorisedQuery';

const ajv = new Ajv();

function validateConfig(editor: MonacoDiffEditor): ConfigType {
  const blob = editor.getModifiedEditor().getValue();
  const config = JSON.parse(blob);

  const validate = ajv.compile(configJsonSchema);
  const valid = validate(config);

  if (!valid) {
    // eslint-disable-next-line no-console
    console.log(validate.errors);
    const errors = validate.errors
      ?.map((error) => `${error.instancePath}: ${error.message} - params: ${JSON.stringify(error.params)}`)
      .join('\n');
    throw new Error(`Invalid config\n: ${errors}`);
  }

  return config as ConfigType; // is ok because we validated the schema above
}

const useStyles = createUseStyles(({ palette }: EzerTheme) => ({
  root: {
    background: palette.white,
    color: palette.carbon
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'right',
    padding: '10px',
    '& button': {
      margin: '0 10px'
    }
  }
}));

const ConfigDiffPage = () => {
  const styles = useStyles();
  const monaco = useMonaco();
  const { data: configWrapper, isLoading } = useConfigQuery();
  const editorRef = useRef<MonacoDiffEditor | null>(null);
  const saveConfig = useConfigMutation();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleEditorDidMount = useCallback((editor: MonacoDiffEditor) => {
    editorRef.current = editor;
  }, []);

  const queryClient = useQueryClient();
  const path = usePath();
  const queryKey = useCacheKey(path);

  const loadFromBrowser = () => {
    setIsDisabled(true);
    if (!editorRef.current) {
      throw new Error('editorRef.current should never be null');
    }

    const blob = localStorage.getItem('config');
    if (!blob) {
      // eslint-disable-next-line no-alert
      window.alert('No config found in browser');
      return;
    }
    editorRef.current.getModifiedEditor().setValue(blob);
    setIsDisabled(false);
  };

  const saveToBrowser = () => {
    setIsDisabled(true);
    if (!editorRef.current) {
      throw new Error('editorRef.current should never be null');
    }
    const blob = editorRef.current.getModifiedEditor().getValue();

    localStorage.setItem('config', blob);
    // eslint-disable-next-line no-alert
    window.alert('Config saved in browser');
    setIsDisabled(false);
  };

  const saveToDB = () => {
    setIsDisabled(true);
    if (!editorRef.current) {
      throw new Error('editorRef.current should never be null');
    }

    if (!configWrapper) {
      // eslint-disable-next-line no-alert
      window.alert('No config available');
      setIsDisabled(false);
      return;
    }

    let config;
    try {
      config = validateConfig(editorRef.current);
    } catch (error) {
      // eslint-disable-next-line no-alert
      window.alert(error);
      setIsDisabled(false);
      return;
    }

    saveConfig.mutate(
      { config, version: configWrapper.version },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey).then(() => {
            setIsDisabled(false);
          });
        },
        onError: (error: unknown) => {
          // eslint-disable-next-line no-alert
          window.alert(error);
        }
      }
    );
  };

  useEffect(() => {
    if (!monaco) return;

    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          uri: 'http://this/does-not-matter.json',
          fileMatch: ['*'],
          schema: configJsonSchema
        }
      ]
    });
  }, [monaco]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const dataString = JSON.stringify(configWrapper?.config || {}, null, 4);
  return (
    <div className={styles.root}>
      <div className={styles.toolbar}>
        <Box display="flex" justifyContent="center" alignItems="center">
          {displayVersion(configWrapper?.version)}
        </Box>
        <Button size="small" variant="contained" onClick={loadFromBrowser} disabled={isDisabled}>
          Load from Browser
        </Button>
        <Button size="small" variant="contained" onClick={saveToBrowser} disabled={isDisabled}>
          Save in Browser
        </Button>
        <Button size="small" variant="contained" onClick={saveToDB} disabled={isDisabled}>
          Save in DB
        </Button>
      </div>
      <DiffEditor
        height="90vh"
        original={dataString}
        originalLanguage="json"
        modified={dataString}
        modifiedLanguage="json"
        theme="vs-dark"
        options={{ glyphMargin: true }}
        onMount={handleEditorDidMount}
      />
    </div>
  );
};

export default ConfigDiffPage;
