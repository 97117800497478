{
  "item": {
    "g_ph_cy5_gol_temp": {
      "value": 893.0926536437561,
      "type": "sensor",
      "status": "ok",
      "unit": "C",
      "timestamp": "2024-09-26T14:30:00+00:00"
    }
  }
}
