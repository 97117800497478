import { addHours, subHours } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import ChartTitle from '../ChartTitle';
import ChartContainer from './ChartContainer';
import useNow from '../../../../useNow';
import { useHistoricalPlantData, useHistoricalSoftSensors } from '../../../../utils';
import { GasAnalyserChartConfig } from '../../schema';
import { LocalisedLabel } from '../../../../lib';
import ChartLoadingMessage from '../ChartLoadingMessage';
import ChartErrorMessage from '../ChartErrorMessage';

type Props = {
  config: GasAnalyserChartConfig;
};

const DEFAULT_SAMPLE_RATE = 2;

const GasAnalyserSection = ({ config }: Props) => {
  const now = useNow();
  const startDate = subHours(now, 24);
  const endDate = addHours(now, 6);
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const sampleRate = Number(query?.get('sampleRate')) || DEFAULT_SAMPLE_RATE;

  const {
    data: plantData,
    isLoading: isPlantDataLoading,
    isError: isPlantDataError
  } = useHistoricalPlantData(
    [config.leftFeatureConfig.feature.name, config.rightFeatureConfig.feature.name],
    subHours(startDate, 1),
    endDate,
    sampleRate,
    true
  );

  const {
    data: performanceData,
    isLoading: isPerformanceDataLoading,
    isError: isPerformanceDataError
  } = useHistoricalSoftSensors([config.performanceFeatureConfig.name], subHours(startDate, 1), endDate, true);

  const isLoading = isPlantDataLoading || isPerformanceDataLoading;
  const isError = isPlantDataError || isPerformanceDataError;

  return (
    <section>
      <ChartTitle>
        <LocalisedLabel>{config.title}</LocalisedLabel>
      </ChartTitle>
      {isLoading && <ChartLoadingMessage />}
      {isError && <ChartErrorMessage />}
      {!isLoading && !isError && (
        <ChartContainer
          config={config}
          startDate={startDate}
          endDate={endDate}
          plantData={plantData}
          performanceData={performanceData}
        />
      )}
    </section>
  );
};

export default GasAnalyserSection;
