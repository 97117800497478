import { useIntl } from 'react-intl';
import { ChartConfig } from '../../../useCustomer';
import { DESCRIPTOR as DateTimeDescriptor } from '../../../messages/DateTime';
import { FeatureName } from '../../../types';
import { getLocalisedMessage } from '../../../utils';
import { useMessages } from '../../../LocalisationProvider';

interface ColumnData {
  field: string | FeatureName;
  headerName: string;
  width: number;
}

const useColumnData = (chartConfigs: ChartConfig[]): ColumnData[] => {
  const messages = useMessages();
  const { formatMessage } = useIntl();
  const result = [{ field: 'date', headerName: formatMessage(DateTimeDescriptor), width: 200 }];

  chartConfigs.forEach((chartConfig) => {
    result.push({
      field: chartConfig.featureName,
      headerName: getLocalisedMessage(messages, chartConfig.title.default, chartConfig.title.id ?? ''),
      width: 180
    });
  });

  return result;
};

export default useColumnData;
