import useHistoricalSoftSensorsQuery from '../serviceQueries/useHistoricalSoftSensorsQuery';
import { FeatureName } from '../types';

export type SoftSensor = {
  timestamp: Date;
  feature: FeatureName;
  value?: number;
};

type Response = {
  data: SoftSensor[];
  isLoading: boolean;
  isError: boolean;
};
const useHistoricalSoftSensors = (
  featureNames: FeatureName[],
  startDate: Date,
  endDate: Date,
  refetch = false
): Response => {
  const {
    data = [],
    isLoading,
    isError
  } = useHistoricalSoftSensorsQuery(featureNames, startDate, endDate, {
    refetch
  });
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: data.map((datum: any) => ({
      timestamp: new Date(datum.timestamp),
      feature: datum.feature as FeatureName,
      value: [null, undefined].includes(datum.value) ? undefined : datum.value
    })),
    isLoading,
    isError
  };
};

export default useHistoricalSoftSensors;
