import { useSearchParams } from 'react-router-dom';
import { EXCLUSIVE_PARAM_KEY, INCLUSIVE_PARAM_KEY } from '../lib/Toggles/contants';

const useToggles = (): string[] => {
  const [searchParams] = useSearchParams();
  const inclusiveSearchParam = searchParams.get(INCLUSIVE_PARAM_KEY);
  const inclusiveToggles = inclusiveSearchParam === null ? [] : inclusiveSearchParam.split(',');
  const exclusiveSearchParam = searchParams.get(EXCLUSIVE_PARAM_KEY);
  return exclusiveSearchParam === null ? inclusiveToggles : [...inclusiveToggles, exclusiveSearchParam];
};

export default useToggles;
