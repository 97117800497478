import { subWeeks } from 'date-fns';
import { TemplateSchema } from './schema';
import { DataLoadErrorMessage, LoadingMessage, LocalisedLabel, PageTitle } from '../../lib';
import { DatePickerMode, Mode } from '../../FilterControls/constants';
import { FilterControls, useEndDate, useStartDate } from '../../FilterControls';
import useToday from '../../useToday';
import { getStartOfWeek, useFileName } from '../../utils';
import { useTogglesData, useWeeklyPerformanceData } from './utils';
import ChartContainer from './ChartContainer';
import SummaryList from './SummaryList/SummaryList';
import Table from './Table/Table';

const HistoricalWeeklyPerformancePage = ({ options, label }: TemplateSchema) => {
  const today = useToday();
  const startDate = useStartDate(subWeeks(getStartOfWeek(today), 5));
  const endDate = useEndDate(getStartOfWeek(today));
  const { data, isLoading, isError } = useWeeklyPerformanceData(options.featureName, startDate, endDate);
  const { toggleOptions } = options;
  const fileName = useFileName(label, startDate, endDate);

  const togglesData = useTogglesData(toggleOptions.map((option) => option.buttonText));
  return (
    <>
      <PageTitle>
        <LocalisedLabel>{label}</LocalisedLabel>
      </PageTitle>
      <FilterControls
        isLoading={isLoading}
        mode={Mode.monthAndYear}
        datePickerMode={DatePickerMode.week}
        toggleData={togglesData}
      />
      {isLoading && <LoadingMessage />}
      {isError && <DataLoadErrorMessage />}
      {!isLoading && !isError && toggleOptions.length > 0 && (
        <>
          <ChartContainer options={options} data={data} startDate={startDate} endDate={endDate} />
          <SummaryList featureTitle={options.featureTitle} data={data} />
          <Table data={data} featureName={options.featureTitle} fileName={fileName} />
        </>
      )}
    </>
  );
};

export default HistoricalWeeklyPerformancePage;
