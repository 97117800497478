import { FormattedMessage } from 'react-intl';
import { TooltipProps } from 'recharts';
import { getLocalDateAndTime, round, useTimezone } from '../../../utils';
import { ChartTooltip } from '../../../lib';

interface Props extends TooltipProps<string, string> {
  unit: string;
}

type TooltipLabelProps = {
  label: number;
};

const TooltipLabel = ({ label }: TooltipLabelProps) => {
  const timezone = useTimezone();
  const convertToTime = (timestamp: number) => (timestamp ? getLocalDateAndTime(new Date(timestamp), timezone) : label);
  return (
    <FormattedMessage
      defaultMessage="Date: {label}"
      id="chart.quality.tooltip"
      values={{ label: convertToTime(label) }}
    />
  );
};

const TooltipContent = ({ label, payload, unit }: Props) => {
  // check that payload is not null or undefined
  const itemsPayload = payload && payload?.length >= 0 ? payload : [];
  const itemListData = itemsPayload.map((payloadItem) => ({
    label: (
      <>
        Score: {round(Number(payloadItem.value))} {unit}
      </>
    ),
    color: payloadItem.color
  }));
  return <ChartTooltip label={<TooltipLabel label={label} />} itemListData={itemListData} />;
};

export default TooltipContent;
