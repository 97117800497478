import { useSearchParams } from 'react-router-dom';
import { Legend } from 'recharts';
import React from 'react';
import { useEzerTheme } from '../../../../EzerThemeProvider';
import LegendItem from './LegendItem';
import useLegendToggledItems, { KEY } from './useLegendToggledItems';
import LegendKey from './LegendKey';
import { useToggles, convertStringToKey } from '../../../../utils';
import { Options } from '../../schema';
import FeatureDescription from '../../FeatureDescription';

type Props = {
  options: Options;
};

const ToggleLegend = ({ options }: Props) => {
  const { spacing, palette } = useEzerTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const legendToggledItems = useLegendToggledItems();

  const onClick = ({ value }: Record<'value', LegendKey>) => {
    if (legendToggledItems.includes(value)) {
      if (legendToggledItems.length > 1) {
        const index = legendToggledItems.indexOf(value);
        legendToggledItems.splice(index, 1);
      }
    } else {
      legendToggledItems.push(value);
    }

    searchParams.set(KEY, legendToggledItems.join(','));
    setSearchParams(searchParams);
  };
  const formatter = (value: LegendKey) => (
    <LegendItem value={value}>
      <FeatureDescription legendKey={value} featureTitle={options.featureTitle} showUnit />
    </LegendItem>
  );

  const payload = [
    {
      value: LegendKey.OkPercentageTime,
      type: 'square',
      color: palette.leaf
    },
    {
      value: LegendKey.HighPercentageTime,
      type: 'square',
      color: palette.glow
    },
    {
      value: LegendKey.LowPercentageTime,
      type: 'square',
      color: palette.lava
    },
    {
      value: LegendKey.NoDataTime,
      type: 'square',
      color: '#999'
    }
  ];
  const toggles = useToggles();
  const { toggleOptions } = options;
  if (toggleOptions.length > 0 && toggles.includes(convertStringToKey(toggleOptions[0].buttonText.default))) {
    payload.push({
      value: LegendKey.HighPeriodCount,
      type: 'line',
      color: palette.lightning
    });
    payload.push({
      value: LegendKey.LowPeriodCount,
      type: 'line',
      color: palette.electric
    });
  }
  if (toggleOptions.length > 1 && toggles.includes(convertStringToKey(toggleOptions[1].buttonText.default))) {
    payload.push({
      value: LegendKey.AverageHighPeriodDuration,
      type: 'line',
      color: palette.lightning
    });
    payload.push({
      value: LegendKey.AverageLowPeriodDuration,
      type: 'line',
      color: palette.electric
    });
  }
  // @ts-ignore
  return <Legend verticalAlign="top" onClick={onClick} height={spacing(4)} formatter={formatter} payload={payload} />;
};

export default ToggleLegend;
