import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { EzerTheme, useEzerTheme } from '../../../EzerThemeProvider';
import { AxisTitle, CartesianGrid, LegendToggles, VerticalTitle } from '../../../lib';
import { PayloadItem } from '../../../lib/LegendToggles/LegendToggles';
import { Recommendation } from '../../../types';
import { getLocalDate, useLegendToggles, useTicksByDate, useTimezone, useUnit } from '../../../utils';
import { PlantData } from '../../../utils/useHistoricalPlantData';
import { Options } from '../schema';
import TooltipContent from './TooltipContent';
import useHistoricalChartData from './useHistoricalChartData';
import useRecommendationsChartData from './useRecommendationsChartData';
import { Date as DateMessage } from '../../../messages';

type Props = {
  options: Options;
  recommendationData: Recommendation[];
  plantData: PlantData;
  startDate: Date;
  endDate: Date;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    marginTop: spacing(2),
    position: 'relative'
  },
  displayRightAxis: {
    paddingRight: spacing(2)
  },
  leftLabel: {
    left: 0
  },
  rightLabel: {
    right: 0
  }
}));

const Chart = ({ options, recommendationData, plantData, startDate, endDate }: Props) => {
  const { palette, spacing } = useEzerTheme();
  const FEATURE_COLOURS = [palette.leaf, palette.white, palette.electric, palette.lightning];
  const styles = useStyles();
  const ticks = useTicksByDate(startDate, endDate);
  const {
    recommendedFeatures,
    historicalFeatures,
    leftAxisTitle,
    rightAxisTitle,
    leftRangeStart = 'auto',
    leftRangeEnd = 'auto',
    rightRangeStart = 'auto',
    rightRangeEnd = 'auto'
  } = options;
  const allFeatures = [...recommendedFeatures, ...historicalFeatures];

  const recommendationsData = useRecommendationsChartData(recommendationData);
  const historicalDataOne = useHistoricalChartData(historicalFeatures[0].featureName, plantData);
  const historicalDataTwo = useHistoricalChartData(historicalFeatures[1].featureName, plantData);
  const historicalDataThree = useHistoricalChartData(historicalFeatures[2].featureName, plantData);
  const renderTicksAtAngle = ticks.length > 15;
  const timezone = useTimezone();
  const { data: leftUnit = '' } = useUnit(historicalFeatures[0].featureName);
  const { data: rightUnit = '' } = useUnit(historicalFeatures[1].featureName);
  const payload: PayloadItem[] = allFeatures.map((feature, index) => ({
    id: feature.title.default.toLowerCase().replaceAll(' ', '-'),
    value: (
      <>
        {feature.title.default} ({index <= 1 ? leftUnit : rightUnit})
      </>
    ),
    type: 'line',
    color: FEATURE_COLOURS[index],
    supportToggle: index >= 2
  }));
  const legendToggles = useLegendToggles();
  const displayRightAxis = legendToggles.includes(payload[2].id) || legendToggles.includes(payload[3].id);

  return (
    <div className={styles.root}>
      <ResponsiveContainer
        width="100%"
        height={spacing(58)}
        className={classNames(styles.root, { [styles.displayRightAxis]: displayRightAxis })}>
        <LineChart>
          {LegendToggles({ payload })}
          <CartesianGrid />
          <Line
            type="stepBefore"
            data={recommendationsData}
            dataKey="value"
            stroke={FEATURE_COLOURS[0]}
            strokeWidth={2}
            dot={false}
            yAxisId="left-axis"
          />
          <Line
            type="stepBefore"
            data={historicalDataOne}
            dataKey="value"
            stroke={FEATURE_COLOURS[1]}
            strokeWidth={2}
            dot={false}
            yAxisId="left-axis"
          />
          {legendToggles.includes(payload[2]?.id) && (
            <Line
              type="stepBefore"
              data={historicalDataTwo}
              dataKey="value"
              stroke={FEATURE_COLOURS[2]}
              strokeWidth={2}
              dot={false}
              yAxisId="right-axis"
            />
          )}
          {legendToggles.includes(payload[3]?.id) && (
            <Line
              type="stepBefore"
              data={historicalDataThree}
              dataKey="value"
              stroke={FEATURE_COLOURS[3]}
              strokeWidth={2}
              dot={false}
              yAxisId="right-axis"
            />
          )}
          <XAxis
            dataKey="date"
            ticks={ticks}
            type="number"
            angle={renderTicksAtAngle ? -45 : 0}
            height={renderTicksAtAngle ? spacing(8) : spacing(2)}
            textAnchor={renderTicksAtAngle ? 'end' : 'middle'}
            tickFormatter={(tick: string) => getLocalDate(new Date(tick), timezone)}
            domain={[startDate.getTime(), endDate.getTime()]}
            allowDataOverflow
            allowDuplicatedCategory={false}
            interval={0}
          />
          <YAxis domain={[leftRangeStart, leftRangeEnd]} allowDataOverflow yAxisId="left-axis" />
          {displayRightAxis && (
            <YAxis
              domain={[rightRangeStart, rightRangeEnd]}
              allowDataOverflow
              yAxisId="right-axis"
              orientation="right"
            />
          )}
          <Tooltip content={<TooltipContent features={allFeatures} leftUnit={leftUnit} rightUnit={rightUnit} />} />
        </LineChart>
      </ResponsiveContainer>
      <VerticalTitle title={leftAxisTitle} className={styles.leftLabel} unit={leftUnit} />
      {displayRightAxis && <VerticalTitle title={rightAxisTitle} className={styles.rightLabel} unit={rightUnit} />}
      <AxisTitle title={<DateMessage />} />
    </div>
  );
};

export default Chart;
