import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getLocalDateAndTime, useTimezone } from '../../../../../utils';

type Props = {
  date: Date;
};

const ForDate = ({ date }: Props) => {
  const timezone = useTimezone();
  return (
    <FormattedMessage
      defaultMessage="For {date}"
      id="labels.forDate"
      values={{
        date: getLocalDateAndTime(date, timezone)
      }}
    />
  );
};

export default ForDate;
