import React from 'react';
import { TooltipProps } from 'recharts';
import { FormattedMessage } from 'react-intl';
import { ChartTooltip } from '../../../../lib';
import { getLocalDateAndTime, useTimezone } from '../../../../utils';
import { useEzerTheme } from '../../../../EzerThemeProvider';
import { Date as DateMessage } from '../../../../messages';

type Props = TooltipProps<string, string> & { title: string; baseline?: number; unit?: string };
const TooltipContent = ({ title, label, payload = [], baseline, unit }: Props) => {
  const { palette } = useEzerTheme();
  const payloadItems = payload.map(({ value, color }) => ({
    label: (
      <>
        {title}: {value} {unit}
      </>
    ),
    color
  }));
  const baselineItem = {
    label: (
      <>
        <FormattedMessage defaultMessage="Baseline" id="page.today.kpi.baseline" />: {baseline}
      </>
    ),
    color: palette.white
  };
  const timezone = useTimezone();

  const itemListData = !baseline ? payloadItems : [...payloadItems, baselineItem];

  const tooltipLabel = label ? (
    <>
      <DateMessage />: {getLocalDateAndTime(new Date(label), timezone)}
    </>
  ) : (
    <></>
  );
  return <ChartTooltip label={tooltipLabel} itemListData={itemListData} />;
};

export default TooltipContent;
