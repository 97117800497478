import usePlantStatus, { PlantStatus } from '../PlantSection/PlantStatusTimeline/usePlantStatus';

export type Response = {
  data: PlantStatus | null;
  isLoading: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertDataItem = (historicalData: any): PlantStatus => ({
  status: historicalData.status,
  timestamp: historicalData.timestamp.toISOString()
});

const useCurrentPlantStatus = (): Response => {
  const { data: historicalData, isLoading } = usePlantStatus();
  const historicalItem = historicalData.length > 0 ? historicalData[0] : null;
  const data = historicalItem === null ? null : convertDataItem(historicalItem);
  return { data, isLoading };
};

export default useCurrentPlantStatus;
