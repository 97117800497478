import { subWeeks } from 'date-fns';
import { FilterControls, useEndDate, useStartDate } from '../../FilterControls';
import { DatePickerMode, Mode } from '../../FilterControls/constants';
import { LocalisedLabel, PageTitle } from '../../lib';
import useToday from '../../useToday';
import getStartOfWeek from '../../utils/getStartOfWeek';
import ChartContainer from './ChartContainer';
import useKpiData from './useKpiData';
import { TemplateSchema } from './schema';
import { convertStringToKey } from '../../utils';

const FreeLimeTemplatePage = ({ label, options }: TemplateSchema) => {
  const today = useToday();
  const startDate = useStartDate(subWeeks(getStartOfWeek(today), 5));
  const endDate = useEndDate(getStartOfWeek(today));
  const { data, isLoading, isError } = useKpiData(options.featureName, startDate, endDate);
  const toggleData = [
    {
      value: 'timeWithinBounds',
      label: <LocalisedLabel>{options.leftAxisTitle}</LocalisedLabel>,
      exclusive: false
    }
  ];
  options.rightAxisOptions.forEach((option) => {
    toggleData.push({
      value: convertStringToKey(option.axisLabel.default),
      label: <LocalisedLabel>{option.axisLabel}</LocalisedLabel>,
      exclusive: true
    });
  });
  return (
    <>
      <PageTitle>
        <LocalisedLabel>{options.chartTitle}</LocalisedLabel>
      </PageTitle>
      <FilterControls
        isLoading={isLoading}
        mode={Mode.monthAndYear}
        datePickerMode={DatePickerMode.week}
        toggleData={toggleData}
      />
      <ChartContainer title={label} data={data} options={options} isLoading={isLoading} isError={isError} />
    </>
  );
};

export default FreeLimeTemplatePage;
