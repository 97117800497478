import { createUseStyles } from 'react-jss';
import { useActiveRecommendation } from '../../utils';
import useNow from '../../../../useNow';
import { Age } from '../../../../lib';

const useStyles = createUseStyles({
  root: {
    display: 'inline-block'
  }
});

const RecommendationAge = () => {
  const styles = useStyles();
  const { data } = useActiveRecommendation();
  const now = useNow();
  const timestamp = data?.timestamp ? new Date(data.timestamp) : now;
  return (
    <p className={styles.root}>
      <Age date={timestamp} />
    </p>
  );
};
export default RecommendationAge;
