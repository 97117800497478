import { Label } from '../types';
import getLocalDate from './getLocalDate';
import useTimezone from './useTimezone';
import useLocalisedLabel from './useLocalisedLabel';

const useFileName = (label: Label, startDate: Date, endDate: Date) => {
  const timezone = useTimezone();
  const title = useLocalisedLabel(label);
  return `${title} ${getLocalDate(startDate, timezone)} ${getLocalDate(endDate, timezone)}`;
};

export default useFileName;
