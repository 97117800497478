{
  "item": {
    "g_k_gol_o2": {
      "value": 5.516001453180195,
      "type": "sensor",
      "status": "ok",
      "unit": "%",
      "timestamp": "2024-09-26T14:30:00+00:00"
    }
  }
}
